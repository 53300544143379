export const loader = {
  state: {
    display: false,
    isDismissable: true
  },
  displayLoader(status) {
    this.state.display = status
  }
}

export const modal = {
  state: {
    display: false,
    title: null,
    subtitle: null,
    lottie: null,
    buttonText: null,
    buttonLink: null
  },
  displayModal(status, isDissmisable, type, link, phone) {
    this.state.display = status
    this.state.phone = phone
    this.state.isDismissable = isDissmisable
    if (status === true) {
      document.body.classList.add('blurred-form')
    } else {
      document.body.classList.remove('blurred-form')
    }

    if (type === 'requestSuccess') {
      this.state.title = `Oferta a fost trimisă prin SMS cu succes către +4${this.state.phone}.`
      this.state.subtitle =
        'Nu uita că poți cere oferte pentru toți artiștii disponibili!'
      this.state.lottie = 'lottie/sentAnimation.json'
      this.state.buttonText = null
      this.state.buttonLink = null
    } else if (type === 'requestLoading') {
      this.state.title =
        'In curs de trimitere...'
      this.state.subtitle = null
      this.state.lottie = 'lottie/sentAnimation.json'
      this.state.buttonText = null
      this.state.buttonLink = null
    } else if (type === 'requestError') {
      this.state.title =
        'Ne pare rău, se pare că solicitarea ta nu a fost trimisă.'
      this.state.subtitle = null
      this.state.lottie = 'lottie/errorDark.json'
      this.state.buttonText = null
      this.state.buttonLink = null
    } else if (type === 'resultsError') {
      this.state.title =
        'Ne pare rău. Nu am găsit rezultate pentru evenimentul tău.'
      this.state.subtitle = null
      this.state.lottie = 'lottie/errorDark.json'
      this.state.buttonText = null
      this.state.buttonLink = null
    } else if (type === 'selectionSuccess') {
      this.state.title = 'Selecția a fost confirmata cu succes.'
      this.state.subtitle =
        'Vei fi un contactat de un Artist Manager in cel mai scurt timp.'
      this.state.lottie = 'lottie/sentAnimation.json'
      this.state.buttonText = null
      this.state.buttonLink = null
    } else if (type === 'selectionError') {
      this.state.title = 'Ne pare rău, se pare că a apărut o eroare.'
      this.state.subtitle =
        'Contactează-ne pentru confirmarea manuală a selectiei.'
      this.state.lottie = 'lottie/errorDark.json'
      this.state.buttonText = null
      this.state.buttonLink = null
    } else if (type === 'offerExpired') {
      // Oferta expirata
      this.state.title = 'Ne pare rău, oferta a expirat'
      this.state.subtitle =
        'Caută alți artiști disponibili pentru evenimentul tău'
      this.state.lottie = 'lottie/precompExpired.json'
      this.state.buttonText = 'Caută artiști disponibili'
      this.state.buttonLink = 'https://www.privent.ro'
    } else if (type === 'offerDeclined') {
      // Oferta a fost anulata de un administrator
      this.state.title = 'Oferta nu a fost confirmată'
      this.state.subtitle = 'Ia legatura cu un administrator '
      this.state.lottie = 'lottie/errorDark.json'
      this.state.buttonText = 'Conteacteaza-ne'
      this.state.buttonLink = 'tel:+40743291354'
    } else if (type === 'artistNotConfirmed') {
      // Aristul nu a confirmat
      this.state.title = 'Artistul nu a confirmat disponibilitatea'
      this.state.subtitle =
        'Caută alți artiști disponibili pentru evenimentul tău'
      this.state.lottie = 'lottie/errorDark.json'
      this.state.buttonText = 'Caută artiști disponibili'
      this.state.buttonLink = 'https://www.privent.ro'
    } else if (type === 'feedbackIsExpired') {
      this.state.title =
        'Ne pare rău, formularul de feedback a fost deja completat.'
      this.state.subtitle = null
      this.state.lottie = 'lottie/errorDark.json'
      this.state.buttonText = null
      this.state.buttonLink = null
    } else if (type === 'playlistConfirmed') {
      this.state.title = 'Playlistul a fost trimis către artist cu succes'
      this.state.subtitle = null
      this.state.lottie = 'lottie/sentAnimation.json'
      this.state.buttonText = null
      this.state.buttonLink = null
    } else if (type === 'playlistExpired') {
      this.state.title =
        'Ne pare rău, formularul de playlist a fost deja completat.'
      this.state.subtitle = null
      this.state.lottie = 'lottie/errorDark.json'
      this.state.buttonText = null
      this.state.buttonLink = null
    } else if (type === 'generateContractIsExpired') {
      this.state.title =
        'Ne pare rău, formularul de generare a contractului a fost deja completat sau oferta a expirat.'
      this.state.subtitle = null
      this.state.lottie = 'lottie/errorDark.json'
      this.state.buttonText = null
      this.state.buttonLink = null
    } else if (type === 'artistNotAvailable') {
      this.state.title =
        'Ne pare rău, artistul nu este disponibil in perioada selectata.'
      this.state.subtitle = null
      this.state.lottie = 'lottie/errorDark.json'
      this.state.buttonText = 'Reincercati cautarea'
      this.state.buttonLink = link
    }
    else if (type === 'uploadGallery') {
      this.state.title =
        'Incarcă rapid fotografiile tale și trimite-le catre sărbătoriți'
      this.state.subtitle = null
      this.state.lottie = 'lottie/uploadImages.json'
      this.state.buttonText = null
      this.state.buttonLink = link
    }
  }
}
