import Vue from 'vue'
import App from './App.vue'
import router from './router'
import UUID from 'vue-uuid'
import Hotjar from 'vue-hotjar'
import VueLazyload from 'vue-lazyload'
// import VueCompositionAPI from '@vue/composition-api';


import '@/assets/less/privent.less'
// import '@/assets/less/style.css'

Vue.config.productionTip = false
Vue.use(UUID)
Vue.use(VueLazyload)

Vue.use(Hotjar, {
  id: process.env.VUE_APP_hotjar_Id,
  isProduction: true
})

// Vue.use(VueCompositionAPI);

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')

