<template>
  <div
    :id="'form-step-' + stepId"
    class="single-step"
    :data-name="stepName"
    :data-step="stepId"
  >
    <h2>Data evenimentului</h2>
    <p class="subttl">
      Când va avea loc evenimentul?
    </p>
    <div class="divider" />
    <div class="field">
      <div class="control form-group calendarPristine">
        <datepicker
          v-model="rawDate"
          :inline="true"
          :language="ro"
          :monday-first="true"
          :full-month-name="true"
          :disabled-dates="{ to: new Date(Date.now() - 8640000) }"
        />
        <input
          v-model="eventStartDate"
          type="text"
          class="store-date creator"
          name="date"
          readonly
          required
          data-pristine-required-message="Te rugăm să selectezi data evenimentului."
        />
      </div>
    </div>
    <br />
    <h2>Ora evenimentului</h2>
    <p class="subttl">
      La ce oră va începe evenimentul?
    </p>
    <div class="divider" />
    <div class="field">
      <div class="control form-group ">
        <input
          id="hours"
          v-model="eventStartTime"
          type="time"
          name="hours"
          class="input creator"
          required
          data-pristine-required-message="Te rugăm să introduci ora evenimentului."
        />
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { en, ro } from 'vuejs-datepicker/dist/locale'
export default {
  name: 'FormStep2',
  components: {
    Datepicker
  },
  props: {
    stepId: String,
    stepName: String
  },
  data() {
    return {
      en: en,
      ro: ro,
      rawDate: null,
      eventStartDate: null,
      eventStartTime: '19:00',
      eventEndDate: null,
      eventEndTime: null
    }
  },
  watch: {
    rawDate: function() {
      this.emitInfos()
    },
    eventStartTime: function() {
      this.emitInfos()
    }
  },
  methods: {
    createDates: function(date, hours) {
      var startDateRaw = new Date(date)
      var startHours = hours

      var startDay = String(startDateRaw.getDate())
      var startMonth = String(startDateRaw.getMonth() + 1)
      var startYear = String(startDateRaw.getFullYear())

      if (startMonth.length < 2) {
        startMonth = '0' + startMonth
      }
      if (startDay.length < 2) {
        startDay = '0' + startDay
      }

      var startDateFormatted = `${startYear}-${startMonth}-${startDay}T${startHours}:00`

      var endDateRaw = new Date(startDateFormatted)
      endDateRaw.setHours(endDateRaw.getHours() + 8)

      var endDay = String(endDateRaw.getDate())
      var endMonth = String(endDateRaw.getMonth() + 1)
      var endYear = String(endDateRaw.getFullYear())
      var endHours = String(endDateRaw.getHours())
      var endMinutes = String(endDateRaw.getMinutes())

      if (endMonth.length < 2) {
        endMonth = '0' + endMonth
      }
      if (endDay.length < 2) {
        endDay = '0' + endDay
      }
      if (endHours.length < 2) {
        endHours = '0' + endHours
      }
      if (endMinutes.length < 2) {
        endMinutes = '0' + endMinutes
      }

      this.eventStartDate = `${startDay}/${startMonth}/${startYear}`
      this.eventEndDate = `${endDay}/${endMonth}/${endYear}`
      this.eventEndTime = `${endHours}:${endMinutes}`
    },
    emitInfos() {
      this.createDates(this.rawDate, this.eventStartTime)
      this.$emit('input', {
        eventStartDate: this.eventStartDate,
        eventStartTime: this.eventStartTime,
        eventEndDate: this.eventEndDate,
        eventEndTime: this.eventEndTime
      })
    }
  }
}
</script>
