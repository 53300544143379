<template>
  <div class="step">
    <div class="feedbackText center">
      Formularul a fost trimis cu succes! <br>Iti multumim pentru timpul acordat
    </div>
    <lottie-animation
      ref="lottieVue"
      class="lottieLoader"
      :autoplay="true"
      :click-play="false"
      :loop="true"
      path="lottie/sentAnimation.json"
    />
  </div>
</template>

<script>
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'

export default {
  name: 'Step7',
  components: { LottieAnimation },
  props: {

  },
  methods: {
  }
}
</script>
