<template>
  <footer class="area">
    <div class="social-icons section">
      <div class="col zrcol_2 t_zrcol_2 align-center">
        <a href="tel:+40751023338">
          <img src="@/assets/img/phone.svg" alt="" />
        </a>
      </div>
      <div class="col zrcol_2 t_zrcol_2 align-center">
        <a href="mailto:contact@privent.ro?subject=Privent%20Oferta%20-%20Emailing%20system">
          <img src="@/assets/img/mail.svg" alt="" />
        </a>
      </div>
      <div class="col zrcol_2 t_zrcol_2 align-center">
        <a href="https:www.facebook.com/privent/">
          <img src="@/assets/img/facebook.svg" alt="" />
        </a>
      </div>
      <div class="col zrcol_2 t_zrcol_2 align-center">
        <a href="https:www.instagram.com/priventsociety/">
          <img src="@/assets/img/instagram.svg" alt="" />
        </a>
      </div>
      <div class="col zrcol_2 t_zrcol_2 align-center">
        <a href="https:www.linkedin.com/company/privent">
          <img src="@/assets/img/linkedin.svg" alt="" />
        </a>
      </div>
    </div>
  </footer>
</template>
<script>
export default { name: 'Footer' }
</script>
