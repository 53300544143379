<template>
  <div class="page-wrapper factura-wrapper">

    <div class="invoice">
      <div class="header">
        <img src="@/assets/img/invoiceHeaderLogo.svg" style="position: absolute; width: 100%; height: 100%">
      </div>
      <div class="content">
        <div class="row">
          <div class="col-8">
            <h3>Prestator</h3>
            <h2>S.C. PRIVENT ENTERTAINMENT SRL</h2>
            <div class="row">
              <div class="col-3">
                <p class="bold">
                  Adresa<br>
                  CUI<br>
                  Reg. Com.<br>
                  Banca <br>
                  IBAN <br>
                  Banca<br>
                  IBAN <br>
                </p>
              </div>
              <div class="col-9">
                <p>
                  Str. Lânăriei Nr. 5B , Pitești, Argeș<br>
                  35928219<br>
                  J3/ 714 / 2016<br>
                  Raifaissen Bank<br>
                  RO14RZBR0000060021099581 <br>
                  Trezorerie <br>
                  RO26TREZ0465069XXX016868
                </p>
              </div>
            </div>
          </div>
          <div class="col-4">
            <h1>
              Chitanță
            </h1>
            <div class="invoice-details">
              <div class="row">
                <div class="col-7">
                  <p class="bold">
                    Seria {{ invoice.serial }} nr.<br>
                    Data emiterii:<br>
                  </p>
                </div>
                <div class="col-5 lp">
                  <p class="invoice-number">
                    {{ invoice.number }}
                  </p>
                  <p class="invoice-number">
                    {{ invoice.createdOn }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="third-content">
        <div class="row">
          <div class="col-6">
            <p>Am primit de la: <span class="bold-text">{{ invoice.clientName }}</span><br>CIF/CNP: <span
                class="bold-text">{{ invoice.clientCnp }}</span><br>C.I. Reg. com: <span class="bold-text">{{
    invoice.clientCi
}}</span><br>Adresa: <span class="bold-text">{{ invoice.clientAddress }}</span></p>
          </div>
          <div class="vertical-divider"></div>
          <div class="col-5">
            <p> Suma de <span class="bold-text">{{ invoice.price1 + invoice.price2 }} Lei</span> adică douamii Lei
              reprezentând contravaloarea facturii seria PRIV nr. 90 din data de 18.01.2022</p>
            <p>Casier:<br>Adelin Preda</p>
          </div>
        </div>
      </div>
      <div class="disclaimer">
        <p>Valabil fără semnatură și ștampilă cf. legii 227/2015</p>
        <div>
          <p style="
              text-align: right;
              display: block;
              margin: 0px;
              padding: 0px;
              margin-bottom: 3px;
            ">
            Generată cu
          </p>
          <img src="@/assets/img/privent-matching-logo.svg" style="width: 140px; display: block">
        </div>

      </div>
      <div class="footer">
        <img src="@/assets/img/invoiceLogoFooter.svg">
        <p>contact@privent.ro | 0751 023 338 | www.privent.ro</p>
      </div>
    </div>
  </div>
</template>

<script>
import PriventApi from '@/api/calls.js'

export default {
  name: 'Factura',
  components: {},
  props: {
    isCompany: Boolean,
    print: Boolean,
    offerLoaded: Boolean,
    contractLoaded: Boolean
  },
  data() {
    return {
      invoice: {
        id: 0,
        createdBy: '',
        createdOn: '',
        clientName: '',
        clientCnp: '',
        contractDate: '',
        clientAddress: '',
        contractNumber: '',
        contractSerial: '',
        isFiscal: false,
        isPrepayment: false,
        item1: '',
        item2: '',
        number: 0,
        prepaymentDate: '',
        prepaymentNumber: '',
        prepaymentSerial: '',
        price: 0,
        serial: 'PRIVC',
        clientSeries: '',
        clientCi: ''
      }
    }
  },
  mounted() {
    const id = this.$route.query.id
    PriventApi.getInvoiceInfo(id).then((response) => {
      const data = response.data
      this.invoice = data
    })
  },

  methods: {}
}
</script>