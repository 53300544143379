<template>
  <div>
    <!-- Loader -->
    <div class="loader show" id="loaderBackground">
      <div class="pulsar">
        <lottie-animation ref="lottieVue" class="lottie" :autoplay="true" :click-play="false" :loop="true"
          path="lottie/logo.json" />
      </div>
    </div>
    <!-- end Loader -->
    <div>
      <contract v-if="showContract" :company="company" :client="client" :offer="offer" :is-company="isCompany" :print="print"
        :contract-loaded="contractLoaded" :offer-loaded="offerLoaded" :goBack="this.goBack" />

      <div v-else class="generateContract-wrapper">
        <div class="wrapper">
          <div class="header">
            <div class="wrapperHeader">
              <img src="@/assets/img/logoHeadline.svg" />
              <div class="actions" />
            </div>
          </div>
          <div v-if="offerLoaded" class="wrapperCards">
            <Splide :options="splideOptions">
              <SplideSlide>
                <div class="card">
                  <p class="headline">Eveniment</p>
                  <p class="content">
                    {{ offer.eventType }}<br />{{ offer.nrParticipants }}
                  </p>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div class="card">
                  <p class="headline">Data si ora</p>
                  <p class="content">{{ offer.time }}<br />{{ offer.date }}</p>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div class="card">
                  <p class="headline">Locatie</p>
                  <p class="content">
                    {{ offer.location }}
                  </p>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div class="card">
                  <p class="headline">DJ</p>
                  <p class="content">
                    {{ offer.artistFirstName }} {{ offer.artistLastName }}
                  </p>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div class="card">
                  <p class="headline">Pret</p>
                  <p class="content">
                    {{ offer.price }}
                  </p>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div v-if="offer.soundPackage" class="card">
                  <p class="headline">Sound Experience</p>
                  <p class="content">
                    {{ offer.soundPackage.name }}
                  </p>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div v-if="offer.lightPackage" class="card">
                  <p class="headline">Showlight</p>
                  <p class="content">
                    {{ offer.lightPackage.name }}
                  </p>
                </div>
              </SplideSlide>
            </Splide>
            <vue-horizontal-list-autoscroll :items="items" :options="autoScrollOptions">
              <template v-slot:default="{ item }">
                <div class="card generateContract">
                  <div class="gradient" />
                  <p class="headline">
                    {{ item.headline }}
                  </p>
                  <p class="content" v-html="item.content">
                    {{ item.content }}
                  </p>
                </div>
              </template>
            </vue-horizontal-list-autoscroll>
          </div>
          <div class="wrapperTitle">
            <div class="title">
              <p>
                Completează formularul<br />
                pentru a genera contractul
              </p>
            </div>
          </div>
          <div class="wrapperForm">
            <div id="form" class="form">
              <div class="clientType">
                <button type="button" class="btn-select left" :class="{ selected: !isCompany }"
                  @click="isCompany = false">
                  Persoană fizică
                </button>
                <button type="button" class="btn-select right" :class="{ selected: isCompany }"
                  @click="isCompany = true">
                  Persoană juridică
                </button>
              </div>

              <personal-form v-if="!isCompany" @generateContract="setClientData" />
              <company-form v-else @generateContract="setClientData" />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
    <modal-message v-if="!showContract" />
  </div>
</template>

<script>
import ModalMessage from '/src/components/ModalMessage.vue'
import * as Pristine from 'pristinejs'
import PersonalForm from '@/components/generateContract/PersonalForm.vue'
import CompanyForm from '@/components/generateContract/CompanyForm.vue'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import PriventApi from '@/api/calls.js'
import Contract from './Contract.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import { modal } from '@/store/loaders.js'
import Footer from "@/components/Footer.vue";


export default {
  name: 'GenerateContract',
  components: {
    PersonalForm,
    CompanyForm,
    Splide,
    SplideSlide,
    Contract,
    LottieAnimation,
    ModalMessage,
    Footer
  },
  data() {
    return {
      loading: true,
      isCompany: false,
      offerLoaded: false,
      offer: {},
      company: {},
      showContract: false,
      validation: false,
      client: { contractSerial: 'PRVC', contractNumber: 0 },
      print: false,
      contractLoaded: false,
      splideOptions: {
        type: 'loop',
        perPage: 4,
        autoplay: true,
        interval: 2500,
        perMove: 1,
        arrows: false,
        pagination: false,
        breakpoints: {
          600: {
            perPage: 2.7,
            drag: true
          },
          1000: {
            perPage: 3,
            drag: true
          }
        }
      }
    }
  },
  mounted() {
    const offerId = this.$route.query.offerId
    const print = this.$route.query.print === 'true'
    PriventApi.getOfferForContract(offerId).then((response) => {
      const data = response.data
      this.offer.location = data.location
      this.offer.eventType = this.getEventType(data.eventType)
      this.offer.nrParticipants = data.nrAttendees
      this.offer.artistFirstName = data.firstName
      this.offer.artistLastName = data.lastName
      this.offer.soundPackage = data.selectedSoundPackage
      this.offer.lightPackage = data.selectedLightPackage
      this.offer.end = data.eventEndTime
      this.offer.datetime = this.getEventDate(data.eventDate)
      this.offer.expired = data.offerExpired
      this.offer.dateOriginalFormat = data.eventDate
      this.offer.price = data.price
      this.offer.phone = data.phone
      this.offer.id = data.offerId
      this.offer.hasPrepayment = data.hasPrepayment
      this.offer.signed = data.contractSigned
      this.offer.email = data.email
      this.company = data.company

      this.setEventEnd(this.offer.end)

      this.client.contractSerial = data.contractSerial
        ? data.contractSerial.value
        : this.client.contractSerial
      this.client.contractNumber = data.contractNumber
      this.offerLoaded = true
      setTimeout(() => {
        this.turnLoaderOff()
      }, 1000)
      if ((this.offer.signed || this.offer.expired) && !print) {
        modal.displayModal(true, false, 'generateContractIsExpired')
      } else {
        modal.displayModal(false, false, 'generateContractIsExpired')
      }
    })

    if (print) {
      PriventApi.getContractInfo(offerId).then((response) => {
        const data = response.data
        this.client.name = data.name
        this.client.address = data.address
        this.client.cnp = data.cnp
        this.client.series = data.series
        this.client.date = data.date
        this.client.signature = data.signatureUrl
        this.isCompany = data.isCompany
        this.client.contractSerial = data.serial.value
        this.client.contractNumber = data.number
        this.client.isContractVoid = data.isVoid

        this.contractLoaded = true
      })
    }

    this.showContract = print
    this.print = print
  },
  methods: {
    turnLoaderOff() {
      setTimeout(() => {
        // Close proloader
        document.querySelector('.loader').classList.remove('show')
      }, 250)
    },
    goBack() {
      this.showContract = false;
      document.body.classList.remove('helper-form')
      document.querySelector('body').classList.remove('printFriendly')

    },
    formifyClient() {
      var pristine = new Pristine(document.getElementById('form'))
      var valid = pristine.validate()
      if (valid) {
        pristine.reset()
        pristine.destroy()
        this.validation = true
      }
    },
    getEventDate(date) {
      var predate = new Date(date)
      var dd = predate.getDate()

      var mm = predate.getMonth() + 1
      if (dd < 10) {
        dd = '0' + dd
      }

      if (mm < 10) {
        mm = '0' + mm
      }
      var hh = predate.getHours()
      if (hh < 10) {
        hh = '0' + hh
      }
      var ss = predate.getMinutes()
      if (ss < 10) {
        ss = '0' + ss
      }
      const finalDate =
        dd + '.' + mm + '.' + predate.getFullYear() + ' ' + hh + ':' + ss
      this.offer.time = hh + ':' + ss
      this.offer.date = dd + '.' + mm + '.' + predate.getFullYear()
      return finalDate
    },
    setEventEnd(date) {
      var predate = new Date(date)
      var dd = predate.getDate()

      var mm = predate.getMonth() + 1
      if (dd < 10) {
        dd = '0' + dd
      }

      if (mm < 10) {
        mm = '0' + mm
      }
      var hh = predate.getHours()
      if (hh < 10) {
        hh = '0' + hh
      }
      var ss = predate.getMinutes()
      if (ss < 10) {
        ss = '0' + ss
      }
      this.offer.endTime = hh + ':' + ss
      this.offer.endDate = dd + '.' + mm + '.' + predate.getFullYear()
    },
    getEventType(type) {
      switch (type) {
        case 1:
          return 'Nuntă'
        case 2:
          return 'Cununie civilă'
        case 3:
          return 'Botez'
        case 4:
          return 'Majorat'
        case 5:
          return 'Petrecere aniversară'
        case 6:
          return 'Corporate'
      }
    },
    setClientData(client) {
      client.contractSerial = this.client.contractSerial
      client.contractNumber = this.client.contractNumber
      this.client = client
      this.showContract = true
      document.body.classList.add('helper-form')
    }
  }
}
</script>

<style lang="less" scoped>
body {
  overflow: auto;
  overflow-y: auto !important;
}
</style>
