<!-- eslint-disable vue/html-self-closing -->
<!-- eslint-disable vue/html-indent -->
<template>
    <div class="invitation-wrapper">
        <div class="header">
            <ul>
                <li><a href="#hero">Ce?</a></li>
                <li><a href="#spouses">Cine?</a></li>
                <li><a href="#venue-header">Unde?</a></li>
                <li><a href="#rsvp">Confirmare</a></li>
            </ul>
        </div>
        <div class="hero" id="hero">
            <p :contenteditable="isEditable" @blur="update"  v-text="modelValue">
            </p>
            <h1
            :contenteditable="isEditable"
                    @input="onHeroTitleInput"
                >
                <span v-html="this.heroTitle"></span>
            </h1>
            <date-pick v-model="eventDate" v-if="isEditable">
                <template v-slot:default="{toggle, inputValue}">
                    <p
                        class="date"
                        @click="toggle"
                        >
                        {{ inputValue || 'Selecteaza data' }}
                    </p>
                </template>
                <template v-slot:cellContent="{item}">
                    <div class="vdpCellContent">
                        <strong>{{ item.date.getDate() }}</strong>
                    </div>
                </template>
            </date-pick>
            <p v-else class="date">
                {{ eventDate }}
            </p>
</div>
        <div class="spouses" id="spouses">
            <div class="bride">
                <h2 :contenteditable="isEditable"
                    @input="onBrideSubtitle">{{ brideSubtitle }}</h2>
                <h3 :contenteditable="isEditable" @input="onBrideTitle">{{ brideTitle }}</h3>
                <p :contenteditable="isEditable" @input="onBrideDesc">
                  <span v-html="brideDesc">
                  </span>
                </p>
            </div>
            <div class="groom">
                <h2 :contenteditable="isEditable"
                    @input="onGroomSubtitle">{{ groomSubtitle }}</h2>
                <h3 :contenteditable="isEditable"
                    @input="onGroomTitle">{{ groomTitle }}</h3>

                <p :contenteditable="isEditable"
                    @input="onGroomDesc">
                    <span v-html="groomDesc">
                  </span>
                </p>
            </div>
            <img
:src="spouseImageURL"
style="width: 100%;"
>
        </div>
        <div class="godparents">
            <h2 :contenteditable="isEditable"
                    @input="onGodparentsSubtitle">{{ godparentsSubtitle }}</h2>
            <p :contenteditable="isEditable"
                    @input="onGodparentsDesc">{{ godparentsDesc }}</p>
            <h3 :contenteditable="isEditable"
                    @input="onGodparentsTitle">{{ godparentsTitle }}</h3>
        </div>
        <div class="venue-header" id="venue-header">
            <h2 :contenteditable="isEditable"
                    @input="onVenueHeader">{{ venueHeader }}</h2>
            <p :contenteditable="isEditable" @input="onVenueSubtitle" class="subtitle" >
                {{ venueSubtitle }}
            </p>
        </div>
        <div class="venue">
            <h3 :contenteditable="isEditable"
                    @input="onVenuesCivilWeddingTitle">Cununie civila</h3>
            <date-pick v-if="isEditable" v-model="venues.civilWedding.date" >
                <template v-slot:default="{toggle, inputValue}">
                    <p
                        class="date"
                        @click="toggle"
                        >
                        {{ inputValue || 'Vineri, 8 Septembrie 2023, 14:30' }}
                    </p>
                </template>
                <template v-slot:cellContent="{item}">
                    <div class="vdpCellContent">
                        <strong>{{ item.date.getDate() }}</strong>
                    </div>
                </template>
            </date-pick>

            <p v-else
                        class="date"
                        >
                        {{ venues.civilWedding.date || 'Vineri, 8 Septembrie 2023, 14:30' }}
                    </p>

            <p :contenteditable="isEditable"
                    @input="onVenuesCivilWeddingLocationName" v-html="venues.civilWedding.locationName">
                    
                   </p>
           
            <div class="map">
                <a
                    href=""
                    class="navigate-button"
                >Navigheaza</a>
                <div class="iframe">
                    <iframe
                    width="100%"
                   height="300px"
                    style="border:0"
                    loading="lazy"
                    scrolling="no"
                    allowfullscreen
                    referrerpolicy="no-referrer-when-downgrade"
                    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAjwYT2gisXQlZjroQX1XCWpzf92_fj1QE
                        &q=Space+Needle,Seattle+WA"
>
                    </iframe>
                </div>
</div>
        </div>
        
        <div class="rsvp" id="rsvp">
            <div class="wrapper">
                <h2 :contenteditable="isEditable"
                    @input="onRsvpTitle">{{ rsvpTitle }}</h2>
             
                <p :contenteditable="isEditable"
                    @input="onRsvpSubtitle" class="subtitle">
                    {{ rsvpSubtitle }}
                    </p>
                    <p :contenteditable="isEditable"
                    @input="onRsvpExplanation" class="subtitle last">
                   {{ rsvpExplanation }}
                </p>
                        <form>
                            <div
class="select-style"
style="width: 90%;"
>
                            <select
id="numberOfPersons"
name="numberOfPersons"
>
                                <option value="0">
                                    Alege număr persoane
                                </option>
                                <option value="1">
                                    O persoană
                                </option>
                                <option value="2">
                                    Două persoane
                                </option>
                            </select>
                            <div
class="alert alert-danger margin-top-10"
style="display: none"
>
                                <i class="icon-remove-sign"></i>Te rog să alegi numărul de persoane.
                            </div>
                        </div>
                        <div
class="select-style vreauCazare"
style="width: 90%;"
>
                            <select
id="confirmare-optiune-cazare"
name="vreauCazare"
>
                                <option value="0">
                                    Dorești cazare?
                                </option>
                                <option value="Da">
DA, doresc cazare
</option>
                                <option value="Nu">
NU, nu doresc cazare
</option>
                            </select>
                            <div
class="alert alert-danger margin-top-10"
style="display: none"
>
                                <i class="icon-remove-sign"></i>Te rog să ne spui dacă dorești cazare.
                            </div>
                        </div>
                        <div
class="select-style tipMeniu"
style="width: 90%;"
>
                            <select
id="confirmare-optiune-meniu-vegetarian"
name="tipMeniu"
>
                                <option value="0">
                                    Tip meniu
                                </option>
                                <option value="Meniu normal">
Meniu normal
</option>
                                <option value="Meniu vegetarian">
Meniu vegetarian
</option>
                            </select>
                            <div
class="alert alert-danger margin-top-10"
style="display: none"
>
                                <i class="icon-remove-sign"></i>Te rugăm să ne spui ce tip de meniu dorești.
                            </div>
                        </div>
                        <div class="buttons">
                            <button>Nu pot sa particip</button>
                            <button>Da, confirm prezenta</button>
                        </div>
</form>
</div>
            <div class="personal-note">
                <p :contenteditable="isEditable"
                    @input="onPersonalTitle"> {{ personalTitle }}</p>
            <p :contenteditable="isEditable"
                    @input="onPersonalNote">
                {{ personalNote }}
                        </p>
                       

            </div>
</div>
    </div>  
</template>
  
<script>

import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';


export default {
    name: "Wedding1",
    components: {
        DatePick,
        
    },
    data() {
        return {
            isEditable: true,
            heroSubtitle: 'Ne casatorim',
            heroTitle: 'Eva <span>&</span>Andrei',
            eventDate: '01.02.2024',
            brideSubtitle: 'EA',
            brideTitle: 'Eva Stanciu',
            brideDesc: 'Serioasă dar cu capul în nori, devreme acasă dar party animal.<br>Si ghici ce.. mă mărit!<br> Aș fi foarte bucuroasă daca vom putea marca acest eveniment impreună!',
            groomSubtitle: 'EL  ',
            groomTitle: 'Ionel Popescu',
            groomDesc: 'Ar fi multe de spus dar începând din această zi nu va mai fi despre mine, ci despre noi.<br>Așa că te invit cu mare drag să petrecem impreună si să avem ce povesti.',
            spouseImageURL: 'https://www.inviteme.ro/templates/wedding/classy/assets/images/image06.jpg',
            godparentsSubtitle: 'Nasii',
            godparentsDesc: 'Împreună cu oamenii de suflet',
            godparentsTitle: 'Andreea și Marian Anghel',
            venueHeader: 'Evenimentul',
            venueSubtitle: 'Cununia civila, religioasa si petrecerea',
            venues: {
                civilWedding: {
                  isShown: true,
                  title: '',
                  date: '',
                  locationName: 'Oficiul stării civile Sector 1<br>Maresal Alexandru Averescu 17 <br>București, România</p>',
                  locationLatLng: ''
                },
                religiousCeremony: {
                  isShown: true,
                  title: '',
                  date: '',
                  locationName: '',
                  locationLatLng: ''
                },
                weddingParty: {
                  isShown: true,
                  title: '',
                  date: '',
                  locationName: '',
                  locationLatLng: ''
                }
            },
            rsvpTitle: 'Confirmare',
            rsvpSubtitle: 'Te asteptam cu drag.',
            rsvpExplanation: 'Completează formularul de mai jos pentru a ne anunța decizia ta.',
            personalTitle: this.brideTitle + this.groomTitle,
            personalNote: 'Pentru noi doi acasă nu este un loc. Este o persoană. Și suntem în sfârșit acasă.'

        };
    },
    computed: {},
    mounted() {
        
     },
    emits: ['update:heroSubtitle'],
    props: {
        modelValue: {
        type: String,
        default: 'Ne casatorim',
        },
    },
    methods: {
        update(e) {
      if (e.target.textContent !== this.modelValue) {
        this.$emit('update:modelValue', e.target.textContent)
        console.log(this.modelValue)
      }
    },
        onHeroSubtitleInput(e) {
            this.$emit('update:heroSubtitle', e.target.textContent)
            console.log(this.heroSubtitle)
        },
        onHeroTitleInput(e) {
            this.heroTitle = e.target.innerText;
            console.log(this.heroTitle);
        },
        onBrideSubtitle(e) {
            this.brideSubtitle = e.target.innerText;
            console.log(this.brideSubtitle);
        },
        onBrideTitle(e) {
            this.brideTitle = e.target.innerText;
            console.log(this.brideTitle);
        },
        onBrideDesc(e) {
            this.brideDesc = e.target.innerText;
            console.log(this.brideDesc);
        },
        onGroomSubtitle(e) {
            this.groomSubtitle = e.target.innerText;
            console.log(this.groomSubtitle);
        },
        onGroomTitle(e) {
            this.groomTitle = e.target.innerText;
            console.log(this.groomTitle);
        },
        onGroomDesc(e) {
            this.groomDesc = e.target.innerText;
            console.log(this.groomDesc);
        },
        onGodparentsSubtitle(e) {
            this.godparentsSubtitle = e.target.innerText;
            console.log(this.godparentsSubtitle);
        },
        onGodparentsDesc(e) {
            this.godparentsDesc = e.target.innerText;
            console.log(this.godparentsDesc);
        },
        onGodparentsTitle(e) {
            this.godparentsTitle = e.target.innerText;
            console.log(this.godparentsTitle);
        },
        onVenueHeader(e) {
            this.venueHeader = e.target.innerText;
            console.log(this.venueHeader);
        },
        onVenueSubtitle(e) {
            this.venueSubtitle = e.target.innerText;
            console.log(this.venueSubtitle);
        },
        onVenuesCivilWeddingTitle(e) {
            this.venues.civilWedding.title = e.target.innerText;
            console.log(this.venues.civilWedding.title);
        },
        onVenuesCivilWeddingLocationName(e) {
            this.venues.civilWedding.locationName = e.target.innerText;
            console.log(this.venues.civilWedding.locationName);
        },
        onRsvpTitle(e) {
            this.rsvpTitle = e.target.innerText;
            console.log(this.rsvpTitle);
        },
        onRsvpSubtitle(e) {
            this.rsvpSubtitle = e.target.innerText;
            console.log(this.rsvpSubtitle);
        },
        onRsvpExplanation(e) {
            this.rsvpExplanation = e.target.innerText;
            console.log(this.rsvpExplanation);
        },
        onPersonalTitle(e) {
            this.personalNote = e.target.innerText;
            console.log(this.personalNote);
        },
        onPersonalNote(e) {
            this.personalNote = e.target.innerText;
            console.log(this.personalNote);
        },
    },
};
</script>

