<template>
  <div class="stars">
    <img
      v-for="(star, index) in realStars"
      :key="index"
      src="@/assets/img/star.svg"
    />
  </div>
</template>

<script>
export default {
  name: 'ProfileStars',
  props: {
    artistStars: Array
  },
  data() {
    return {
      realStars: 0
    }
  },
  watch: {
    // Used for profile
    artistStars: function() {
      this.generateStars()
    }
  },
  mounted() {
    // Used for cards
    if (this.artistStars.length > 0) {
      this.generateStars()
    }
  },
  methods: {
    generateStars() {
      var calculateRating = 0
      var numberOfRating = this.artistStars.length

      this.artistStars.forEach((rat) => {
        calculateRating += rat.ratingDj
      })

      var calculateStars = calculateRating / numberOfRating / 1
      this.realStars = Math.round(calculateStars)
    }
  }
}
</script>
