<template>
  <div class="step">
    <div class="feedbackText">
      2. Cum evaluezi prestatia profesionala a DJ-ului in cadrul evenimentului?
    </div>
    <vue-feedback-reaction
      v-model="feedback"
      :emoji-width="emojiWidth"
      is-disabled="false"
      :emoji-height="emojiHeight"
      :labels="labels"
      label-class="labelClass"
      class="feedback"
      @input="onInput"
    />
  </div>
</template>

<script>
import { VueFeedbackReaction } from 'vue-feedback-reaction'

export default {
  name: 'Step3',
  components: { VueFeedbackReaction },
  props: {
    emojiWidth: String,
    emojiHeight: String,
    value: String
  },
  data() {
    return {
      feedback: '',
      labels: ['1', '2', '3', '4', '5']
    }
  },
  methods: {
    onInput() {
      this.$emit('value', this.feedback)
    }
  }
}
</script>
