<template>
  <div
  id="form-step-info"
  class="tab-details"
  >
    <h2>Datele de contact</h2>
    <p class="subttl">
      Completează datele de contact și primește automat prin SMS oferta
      personalizată.
    </p>
    <div class="divider" />
    <div class="field">
      <div class="control form-group data">
        <input
          v-model.trim="lastName"
          name="lastname"
          type="text"
          placeholder=" "
          class="input creator"
          pattern="/^[a-zA-Zăîâșț-]*$/"
          maxlength="256"
          required
          data-pristine-required-message="Te rugăm să introduci numele."
          data-pristine-pattern-message="Numele trebuie să conțină doar litere."
          data-pristine-maxlength-message="Numele este prea lung."
        />
        <label class="label" for="adress">Nume</label>
      </div>
    </div>
    <div class="field">
      <div class="control form-group data">
        <input
          v-model.trim="firstName"
          name="firstname"
          type="text"
          placeholder=" "
          class="input creator"
          pattern="/^[a-zA-Zăîâșț -]*$/"
          maxlength="256"
          required
          data-pristine-required-message="Te rugăm să introduci prenumele."
          data-pristine-pattern-message="Prenumele trebuie să conțină doar litere."
          data-pristine-maxlength-message="Prenumele este prea lung."
        />
        <label class="label" for="adress">Prenume</label>
      </div>
    </div>
    <div class="field">
      <div class="control form-group data">
        <input
          v-model="phone"
          name="phone"
          type="tel"
          placeholder=" "
          class="input creator"
          required
          minlength="10"
          maxlength="10"
          pattern="/(07)[2-9]\d{7}/"
          data-pristine-minlength-message="Numărul de telefon este prea scurt."
          data-pristine-maxlength-message="Numărul de telefon este prea lung."
          data-pristine-pattern-message="Numărul de telefon nu este valid."
          data-pristine-required-message="Te rugăm să introduci numărul de telefon."
        />
        <label class="label" for="adress">Telefon</label>
      </div>
    </div>
    <div class="field">
      <div class="control form-group termsPristine">
        <div class="terms">
          <input
            id="checkbox"
            type="checkbox"
            name="terms"
            v-model="terms"
            required
            data-pristine-required-message="Trebuie să acceptați termenii și condițiile"
          />
          <label for="checkbox"
            ><p>
              Sunt de acord cu
              <a
                href="https://storagedjbooking.blob.core.windows.net/terms-and-conditions/GDPR%20DJ%20BOOKING.pdf"
                target="_blank"
                >termenii și condițiile</a
              >
            </p></label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoStep',
  props: {
    eventLastName: String,
    eventFirstName: String,
    eventPhone: String,
    termsAndConditions: Boolean
  },
  data() {
    return{
      lastName: this.eventLastName,
      firstName: this.eventFirstName,
      phone: this.eventPhone,
      terms: this.termsAndConditions
    }
  },
  watch: {
    phone: function() {
      this.emitInfos()
    },
    lastName: function() {
      this.emitInfos()
    },
    firstName: function() {
      this.emitInfos()
    },
    terms: function() {
      this.emitInfos()
    }
  },
  methods: {
    emitInfos() {
      this.$emit('input', {
        lastName: this.lastName,
        firstName: this.firstName,
        phone: this.phone,
        termsAndConditions: this.terms
      })
    }
  }
}
</script>
