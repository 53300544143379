<template>
  <div id="app-form" class="wrapper">
    <cookie-law buttonText="Am inteles" buttonClass="Cookie__button">
      <div slot="message">
        Acest website utilizează cookieuri pentru o a-ți asigura o experiență
        cât mai bună. Continuarea navigării reprezintă acceptul tău.
        <router-link to="terms">Despre politica cookie</router-link>
      </div>
      <div slot="buttonText">Am inteles</div>
    </cookie-law>
    <loader-pulsar />
    <div class="headerNav">
      <div class="wrapperHeader">
        <img src="@/assets/img/textLight.svg" />
      </div>
    </div>

    <div class="form-wrapper">
      <div v-show="!isModalVisible" class="content">
        <div class="not-found" :class="{ active: notFoundError }">
          <div class="wrapper">
            <h2>
              Din păcate nu am găsit artiști <br />disponibili pentru
              evenimentul tău
            </h2>
            <p>
              Poți încerca încă o dată căutarea pentru a te asigura că datele au
              fost introduse corect
            </p>
            <div class="section buttons">
              <div class="zrcol_10 t_zrcol_10 m_zrcol_9">
                <button class="btn-clasic search" @click="backToStart(1)">
                  Reincearca cautarea
                </button>
              </div>
              <div class="zrcol_2 t_zrcol_2 m_zrcol_3">
                <button class="btn-clasic help" @click="helpText = true">
                  <svg
                    version="1.1"
                    class="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                  >
                    <path
                      class="st0"
                      d="M512,346.5c0-74.6-50.3-139.8-121.2-159.5C386.3,83.1,300.5,0,195.5,0C87.7,0,0,87.7,0,195.5
c0,35.1,9.4,69.3,27.1,99.4L0.7,390.3l95.4-26.4c27.6,16.3,58.7,25.5,90.9,26.9C206.7,461.7,271.9,512,346.5,512
c29.8,0,58.8-7.9,84.2-23l80.6,22.3L489,430.7C504.1,405.3,512,376.3,512,346.5z M100.9,331.5l-57.1,15.8l15.8-57.1l-3.6-5.6
c-17-26.6-25.9-57.3-25.9-89C30,104.2,104.2,30,195.5,30S361,104.2,361,195.5S286.8,361,195.5,361c-31.7,0-62.4-9-89-25.9
L100.9,331.5z M468.3,468.3l-42.4-11.7l-5.7,3.7c-21.9,14.3-47.4,21.8-73.7,21.8c-58.7,0-110.2-37.9-128.5-92.3
c89.8-10.4,161.3-81.8,171.7-171.7c54.4,18.3,92.3,69.8,92.3,128.5c0,26.3-7.5,51.8-21.8,73.7l-3.7,5.7L468.3,468.3z"
                    />
                    <path class="st0" d="M180.5,271h30v30h-30V271z" />
                    <path
                      class="st0"
                      d="M225.5,150c0,8.5-3.5,16.4-9.8,22.1l-35.2,32.2V241h30v-23.4l25.5-23.3c12.4-11.3,19.5-27.5,19.5-44.3
c0-33.1-26.9-60-60-60s-60,26.9-60,60h30c0-16.5,13.5-30,30-30S225.5,133.5,225.5,150z"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <p class="helpText" :class="{ active: helpText }">
              Pentru mai multe detalii ne puteti <br />
              contacta la numarul <a href="tel:+40751023338">0751 023 338</a>
            </p>
          </div>
        </div>

        <ul class="steps has-content-centered">
          <li
            v-for="(value, id) in stepSegments"
            :key="id"
            class="steps-segment navigators"
            :class="{ 'is-active': id <= currentStep }"
            @click="certainStep(id)"
          >
            <div class="steps-content">
              <p v-if="id === '1' && eventType" class="is-size-4">
                {{ getEventTypePill() }}
              </p>
              <p v-else-if="id === '2' && eventDateTime" class="is-size-4">
                {{ eventDateTime.eventStartDate }}
                {{ eventDateTime.eventStartTime }}
              </p>
              <p v-else-if="id === '3' && eventLocation" class="is-size-4">
                {{ eventLocation.place }}
              </p>
              <p v-else-if="id === '4' && eventGuests" class="is-size-4">
                {{ eventGuests }}
              </p>
              <p v-else class="is-size-4">
                {{ value }}
              </p>
            </div>
          </li>
        </ul>
        <div class="insider">
          <div class="form">
            <!-- <div class="control back-button" :class="{inactive: currentStep < 2}">
          <button class="btn-clasic back" @click="prevStep">
            <img src="@/assets/img/arrow.png" />
          </button>
        </div> -->
            <div
              v-for="(result, index) in requestResults"
              id="profile-page"
              :key="index"
              class="profile-page generated-step"
              :class="{ 'active-step': displayedProfile === result.id }"
              :data-id="result.id"
            >
              <div
                class="results-back-button"
                :class="{ active: displayedProfile === result.id }"
              >
                <button @click="backToResults()">
                  <img src="@/assets/img/arrow.png" />
                  <span>Înapoi la rezultate</span>
                </button>
              </div>
              <ProfilePage
                v-if="displayedProfile === result.id"
                :artist-id="result.id"
                :requested-offer="requestedOffer"
              />
            </div>
            <div class="tab-details">
              <FormStep1
                v-model="eventType"
                :class="{
                  'active-step': currentStep == Object.keys(stepSegments)[0],
                }"
                :step-name="stepSegments[1]"
                :step-id="Object.keys(stepSegments)[0]"
                :eventType="eventType"
              />
              <FormStep2
                v-model="eventDateTime"
                :class="{
                  'active-step': currentStep == Object.keys(stepSegments)[1],
                }"
                :step-name="stepSegments[2]"
                :step-id="Object.keys(stepSegments)[1]"
              />
              <FormStep3
                v-model="eventLocation"
                :class="{
                  'active-step': currentStep == Object.keys(stepSegments)[2],
                }"
                :step-name="stepSegments[3]"
                :step-id="Object.keys(stepSegments)[2]"
                :inputLocation="eventLocation.address"
              />
              <FormStep4
                v-model="eventGuests"
                :class="{
                  'active-step': currentStep == Object.keys(stepSegments)[3],
                }"
                :step-name="stepSegments[4]"
                :step-id="Object.keys(stepSegments)[3]"
                :eventGuests="eventGuests"
              />
              <div
                v-if="currentStep === 0"
                id="Results"
                class="single-step"
                :class="{
                  'active-step': currentStep === 0 && displayedProfile === 0,
                }"
              >
                <div class="startAgain" @click="backToForm(4)">
                  <img src="@/assets/img/arrow.svg" />
                  <span>Întoarce-te la formular</span>
                </div>
                <h2>Artiști disponibili</h2>
                <p class="subttl">
                  Alege cel mai potrivit artist disponibil pentru evenimentul
                  tău și solicită oferta.
                </p>
                <div id="resultsList" class="results-carousel">
                  <ProfileCard
                    v-for="(result, index) in requestResults"
                    :key="index"
                    :artist-id="result.id"
                    :artist-name="result.firstName + ' ' + result.lastName"
                    :artist-img="result.profileImage"
                    :artist-stars="result.ratings"
                    :requested="result.requested"
                    :requested-offer="requestedOffer"
                  />
                </div>
              </div>
            </div>
            <div class="actions buttons-area">
              <div class="control-btn">
                <button
                  v-if="currentStep > 1 && currentStep < 4"
                  class="btn-clasic next"
                  data-direction="true"
                  @click="nextStep"
                >
                  Continuă
                </button>
                <button
                  v-if="currentStep === 4 && !artistId"
                  class="btn-clasic next sendOffer"
                  data-direction="true"
                  @click="showResults"
                >
                  Caută artiști disponibili
                </button>
                <button
                  v-else-if="currentStep === 4"
                  :disabled="requested"
                  class="btn-clasic next sendOffer"
                  data-direction="true"
                  @click="showModal"
                >
                  Solicită oferta
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal v-show="isModalVisible" @close="closeModal"
      ><info-step
        v-model="eventClient"
        :eventLastName="eventClient.lastName"
        :eventFirstName="eventClient.firstName"
        :eventPhone="eventClient.phone"
        :termsAndConditions="eventClient.termsAndConditions"
      />
      <button
        :disabled="requested"
        class="btn-clasic next sendOffer"
        @click="createOffer(selectedArtistId)"
      >
        Solicită oferta
      </button></Modal
    >
  </div>
</template>

<script>
import * as Pristine from "pristinejs";
import PriventApi from "@/api/calls.js";
import { modal, loader } from "@/store/loaders.js";
import { tns } from "tiny-slider/src/tiny-slider";
import FormStep1 from "@/components/steps/FormStep1.vue";
import FormStep2 from "@/components/steps/FormStep2.vue";
import FormStep3 from "@/components/steps/FormStep3.vue";
import FormStep4 from "@/components/steps/FormStep4.vue";
import InfoStep from "@/components/steps/InfoStep.vue";
import ProfileCard from "@/components/ProfileCard.vue";
import ProfilePage from "@/components/ProfilePage.vue";
import LoaderPulsar from "@/components/LoaderPulsar.vue";
import CookieLaw from "vue-cookie-law";
import "tiny-slider/dist/tiny-slider.css";
import $ from "jquery";
import Modal from "./Modal.vue";

export default {
  name: "FormSteps",
  components: {
    FormStep1,
    FormStep2,
    FormStep3,
    FormStep4,
    InfoStep,
    ProfileCard,
    ProfilePage,
    LoaderPulsar,
    CookieLaw,
    Modal,
  },
  data() {
    return {
      selectedArtistId: null,
      isModalVisible: false,
      offerId: null,
      requested: false,
      artistId: null,
      currentStep: 1,
      eventLocation: {
        address: null,
        place: null,
        city: null,
      },
      eventType: null,
      eventDateTime: null,
      eventGuests: null,
      eventClient: {
        phone: "",
        termsAndConditions: null,
        firstName: null,
        lastName: null,
      },
      validation: false,
      validationInfo: false,
      stepSegments: {
        1: "Tipul evenimentului",
        2: "Data și ora",
        3: "Locație",
        4: "Participanți",
      },
      requestResults: null,
      getResults: false,
      displayedProfile: 0,
      displayProfile: false,
      requestedOffer: [],
      notFoundError: false,
      helpText: false,
    };
  },
  watch: {
    eventType: function(val) {
      //do something when the data changes.
      if (val != null) {
        this.nextStep();
      }
    },
    currentStep: function() {
      if (this.currentStep === 0) {
        // Add class on body for styles
        document.getElementById("app").classList.add("add-results");
        // Prevent pull down refresh on Android
        document.body.style.overscrollBehavior = "contain";
      }
      // Update progress bar
      if (
        this.currentStep > 0 &&
        this.currentStep <= Object.keys(this.stepSegments).length
      ) {
        setTimeout(() => {
          this.progressBarMovement();
        }, 100);
      }
      if (this.currentStep > 4) {
        // Add class on body for styles
        document.getElementById("app").classList.remove("add-results");
      }
    },
    requestResults: function() {
      setTimeout(() => {
        this.getResults = true;
      }, 100);
    },
    getResults: function() {
      // Initialize Cards Carousel
      var slider = tns({
        container: ".results-carousel",
        slideBy: "page",
        loop: false,
        speed: 600,
        navPosition: 'bottom',
        responsive: {
          50: {
            items: 1,
            center: true,
            mouseDrag: true,
            edgePadding: 60,
            swipeAngle: 360,
            preventScrollOnTouch: "force",
            controls: false
          },
          600: {
            items: 3,
            mouseDrag: true,
            edgePadding: 0,
            swipeAngle: 360,
            center: false,
            touch: true,
            preventScrollOnTouch: "force",
            arrowKeys: true,
            controls: false
          },
        },
      });
      // Add class on first item from Results carousel
      /* document
        .querySelector('#Results .result-card')
        .classList.add('current-active')
      */
      slider.events.on("indexChanged", () => {
        var info = slider.getInfo();

        document.querySelectorAll(".result-card").forEach((sld) => {
          sld.classList.remove("current-active");
        });

        info.slideItems[info.index].classList.add("current-active");
      });
    },
  },
  created() {
    const urlString = window.location.href;
    const urlNew = new URL(urlString);
    this.artistId = urlNew.searchParams.get("artistId");
    this.offerId = urlNew.searchParams.get("offerId");
    if (this.offerId) {
      loader.displayLoader(true);
    }
  },
  mounted() {
    // Scrolls back to top after keyboard input
    // Saves the offset when the user clicks the input
    let initialOffset = $("body").scrollTop();
    $("input, textarea, select").focus(function(e) {
      let offset = $(e.target).offset();
      initialOffset = $("body").scrollTop();
      $("body").scrollTop(offset.top - 100);
    });
    $("input, textarea, select").focusout(function() {
      // Reset to the initial offset
      $("body").scrollTop(initialOffset);
    });

    // integrate the browser back button into the form steps
    // pressing back will go to the previous step
    history.pushState(null, null, location.href);
    window.onpopstate = () => {
      if (this.currentStep === 0) {
        this.backToForm(4);
        history.pushState(null, null, location.href);
      } else if (this.currentStep === 1) {
        // do nothing
      } else if (this.currentStep === 2) {
        this.prevStep();
      } else {
        this.prevStep();
        history.pushState(null, null, location.href);
      }
    };

    if (this.offerId) {
      PriventApi.getOfferForSearch(this.offerId).then((response) => {
        const data = response.data;
        this.eventGuests = data.nrAttendees;
        this.eventLocation.address = data.eventAddress;
        this.eventType = this.getEventTypeFromApi(data.eventType);
        this.eventDate = data.eventDate;
        this.eventLocation.place = data.location;
        this.eventLocation.city = data.city;
        this.eventClient.phone = data.phone;
        this.eventClient.termsAndConditions = true;
        this.eventClient.firstName = data.firstName;
        this.eventClient.lastName = data.lastName;

        this.validation = true;
        this.showResults();
      });
    }
    // var getHeight = document.documentElement.clientHeight
    // document.querySelector('#app').style.height = `${getHeight}px`
    // document.querySelector('.control.back-button').style.top = `calc( calc( ${getHeight}px - ${getHeight}px / 4) / 2 )`
  },
  methods: {
    showModal(artistId) {
      this.selectedArtistId = artistId;
      if (this.eventClient.phone.length === 10) {
        this.createOffer(artistId)
      }
      else {
        this.isModalVisible = true;
      }
    },
    closeModal() {
      this.isModalVisible = false;
      this.displayedProfile = 0
    },
    // "Transform" certain element into form (used for validation)
    formify() {
      var step = this.currentStep;
      var pristine = new Pristine(document.getElementById(`form-step-${step}`));
      var valid = pristine.validate();
      if (valid) {
        pristine.reset();
        pristine.destroy();
        this.validation = true;
      }
    },
    backToResults() {
      this.displayedProfile = 0;
      document.querySelector(".content").classList.remove("maximized");
    },
    // Navigate to next step and transform into form for validation
    nextStep() {
      this.formify();
      if (this.validation === true) {
        this.currentStep += 1;
      }
      this.validation = false;
    },
    // Navigate to prev step
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep -= 1;
      }
    },
    // Navigate to certain step (used by step segements)
    certainStep: function(step) {
      this.currentStep = parseInt(step, 10);
      if (step == 1) {
        this.eventType = null;
        document.getElementsByTagName("type").checked = false;
        var elements = document.getElementsByTagName("input");

        for (var i = 0; i < elements.length; i++) {
          if (elements[i].type == "radio") {
            elements[i].checked = false;
          }
        }
      }
    },
    backToForm: function(step) {
      loader.displayLoader(true);
      setTimeout(() => {
        // this.requestResults = null
        this.currentStep = parseInt(step, 10);
        document.getElementById("app").classList.remove("add-results");
        document
          .getElementById("app-form")
          .classList.remove("form-place-results");
        document
          .querySelector("#Results .result-card")
          .classList.remove("oneResult");
        // document.getElementById('app-form').classList.add('form-place')
        this.validation = false;
        this.getResults = false;
        loader.displayLoader(false);
      }, 400);
    },
    backToStart: function(step) {
      this.eventType = null;
      this.notFoundError = false;
      this.currentStep = parseInt(step, 10);
      document.getElementById("app").classList.remove("add-results");
      document
        .querySelector("#Results .result-card")
        .classList.remove("oneResult");
      document
        .getElementById("app-form")
        .classList.remove("form-place-results");
      document.getElementById("app-form").classList.add("form-place");
    },
    getEventType() {
      if (this.eventType === "Corporate") {
        return "PetrecereP";
      }
      if (this.eventType === "Petrecere Aniversara") {
        return "PetrecereA";
      }
      if (this.eventType === "Cununie Civila") {
        return "Cununie";
      }
      return this.eventType;
    },
    getEventTypePill() {
      if (this.eventType === "PetrecereP") {
        return "Corporate";
      }
      if (this.eventType === "PetrecereA") {
        return "Petrecere Aniversara";
      }
      if (this.eventType === "Cununie") {
        return "Cununie Civila";
      }
      return this.eventType;
    },
    getEventTypeFromApi(type) {
      switch (type) {
        case 1:
          return "Nunta";
        case 2:
          return "Cununie";
        case 3:
          return "Botez";
        case 4:
          return "Majorat";
        case 5:
          return "PetrecereA";
        case 6:
          return "PetrecereP";
      }
    },
    showResults: function() {
      this.formify();
      if (this.validation === true) {
        setTimeout(() => {
          loader.displayLoader(true);
          setTimeout(() => {
            this.currentStep = 0;
            let startTime = null;
            if (this.eventDateTime) {
              startTime =
                this.eventDateTime.eventStartDate +
                " " +
                this.eventDateTime.eventStartTime;
            } else {
              startTime = this.eventDate;
            }
            var dateObject = {
              Start: startTime,
              EventType: this.getEventType(),
              EventAddress: this.eventLocation.address,
            };

            PriventApi.eventAvailability(dateObject)
              .then((response) => {
                this.requestResults = response.data;
                setTimeout(() => {
                  document.getElementById("app").classList.add("add-results");
                  loader.displayLoader(false);

                  if (this.requestResults.length < 1) {
                    // modal.displayModal(true, 'resultsError')
                    // eroare 404 daca nu a fost gasit nici un artist disponibil
                    this.notFoundError = true;
                  }
                  if (this.requestResults.length === 1) {
                    document
                      .querySelector("#Results .result-card")
                      .classList.add("oneResult");
                  }
                  if (this.requestResults.length > 0) {
                    document
                      .getElementById("app-form")
                      .classList.remove("form-place");
                    document
                      .getElementById("app-form")
                      .classList.add("form-place-results");
                  }
                  this.requestResults.forEach(res => res.requested = false)

                  this.getResults = true;
                }, 800);
              })
              .catch(() => {
                this.offerRequestedSucces = false;
                document.body.classList.add("blurred-bg");
                document.getElementById("app").classList.add("add-results");
                document
                  .getElementById("app-form")
                  .classList.remove("form-place");
                document
                  .getElementById("app-form")
                  .classList.add("form-place-results");
              });
          }, 400);
        }, 200);
      }
    },
    formifyInfoStep() {
      var pristine = new Pristine(document.getElementById(`form-step-info`));
      var valid = pristine.validate();
      if (valid) {
        pristine.reset();
        pristine.destroy();
        this.validationInfo = true;
      }
    },
    createOffer: function(artistId) {
      if(this.artistId === null){
        this.artistId = artistId;
      }

      if(this.offerId){
        if(this.eventClient.firstName.length > 3 && this.eventClient.lastName.length > 3 && this.eventClient.phone.length === 10){
          this.validationInfo = true
        }
      }
      else{
        this.formifyInfoStep();
      }

      
      if (this.validationInfo === true) {
        this.validationInfo = false;
        if(this.artistId === null) {
        const resultSelected = this.requestResults.findIndex(res => res.id == this.artistId)
        this.requestResults[resultSelected].requested = true
        }

        this.requested = true;
        let eventDateFormatted = null;
        if (this.eventDateTime) {
          eventDateFormatted =
            this.eventDateTime.eventStartDate +
            " " +
            this.eventDateTime.eventStartTime;
        } else {
          eventDateFormatted = this.eventDate;
        }
        var eventInfosObj = {
          Name: this.eventClient.firstName + " " + this.eventClient.lastName,
          ArtistId: this.artistId,
          NrAttendees: this.eventGuests,
          EventAddress: this.eventLocation.address,
          EventType: this.getEventType(),
          EventDate: eventDateFormatted,
          Location: this.eventLocation.place,
          City: this.eventLocation.city,
          Phone: this.eventClient.phone,
          TermsAndConditions: this.eventClient.termsAndConditions,
        };

        this.closeModal()
        modal.displayModal(true, true, "requestLoading");
        PriventApi.addOffer(eventInfosObj)
          .then((response) => {
            this.requested = false;
            this.requestedOffer.push(response.data.ArtistId);
            document.body.classList.add("blurred-bg");
            modal.displayModal(true, true, "requestSuccess", "", this.eventClient.phone);
          })
          .catch((error) => {
            this.requested = false;
            if (error.response.status === 409) {
              document.body.classList.add("blurred-bg");
              modal.displayModal(true, true, "artistNotAvailable");
            } else {
              document.body.classList.add("blurred-bg");
              modal.displayModal(true, true, "requestError");
            }
          });
      }
      
    },
    showProfile: function(artistId) {
      this.displayedProfile = artistId;
    },
    progressBarMovement() {
      const stepsWrapper = document.querySelector("ul.steps");
      stepsWrapper.scrollLeft += stepsWrapper.clientWidth;
    },
  },
};
</script>

<style lang="less" scoped>

</style>