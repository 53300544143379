<template>
  <div>
    <!-- Loader -->
    <div class="loader show" id="loaderBackground">
      <div class="pulsar">
        <lottie-animation
          ref="lottieVue"
          class="lottie"
          :autoplay="true"
          :click-play="false"
          :loop="true"
          path="lottie/logo.json"
        />
      </div>
    </div>
    <!-- end Loader -->
    <div class="playlist-wrapper">
      <div class="wrapper">
        <div class="header">
          <div class="wrapperHeader">
            <img src="@/assets/img/logoHeadline.svg" />
            <div class="actions" />
          </div>
        </div>
        <div v-if="offerLoaded" class="wrapperCards">
          <Splide :options="splideOptions">
            <SplideSlide>
              <div class="card">
                <p class="headline">Eveniment</p>
                <p class="content">
                  {{ offer.eventType }}<br />{{ offer.nrParticipants }}
                </p>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div class="card">
                <p class="headline">Data si ora</p>
                <p class="content">{{ offer.time }}<br />{{ offer.date }}</p>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div class="card">
                <p class="headline">Locatie</p>
                <p class="content">
                  {{ offer.location }}
                </p>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div class="card">
                <p class="headline">DJ</p>
                <p class="content">
                  {{ offer.artistFirstName }} {{ offer.artistLastName }}
                </p>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div class="card">
                <p class="headline">Pret</p>
                <p class="content">
                  {{ offer.price }}
                </p>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div v-if="offer.soundPackage" class="card">
                <p class="headline">Sound Experience</p>
                <p class="content">
                  {{ offer.soundPackage.name }}
                </p>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div v-if="offer.lightPackage" class="card">
                <p class="headline">Showlight</p>
                <p class="content">
                  {{ offer.lightPackage.name }}
                </p>
              </div>
            </SplideSlide>
          </Splide>
          <vue-horizontal-list-autoscroll
            :items="items"
            :options="autoScrollOptions"
          >
            <template v-slot:default="{ item }">
              <div class="card generateContract">
                <div class="gradient" />
                <p class="headline">
                  {{ item.headline }}
                </p>
                <!-- TODO: CHANGE ME BACK -->
                <!-- <p class="content" v-html="item.content">
                  {{ item.content }}
                </p> -->
              </div>
            </template>
          </vue-horizontal-list-autoscroll>
        </div>
        <div class="wrapperTitle">
          <div class="title">
            <p>Adaga preferintele tale muzicale</p>
          </div>
        </div>
        <div class="wrapperForm">
          <div id="form" class="form">
            <div id="personalForm">
              <p class="formTitle">Adauga playlistul tău</p>
              <a href="https://youtu.be/xGfeZjF00N8" class="playlist-info">
                <img class="" src="@/assets/img/getHelp.svg" />
                <p>
                  Cum se creeaza un
                  <span style="text-decoration: underline">playlist</span>?
                </p>
              </a>
              <div class="inputWrapper first">
                <input
                  id="playlist"
                  v-model="clientPlaylist"
                  class="input"
                  type="text"
                  name="clientPlaylist"
                  placeholder=" "
                  autocomplete="off"
                  required
                  minlength="4"
                  data-pristine-minlength-message="Introduceti minim 4 caractere"
                  data-pristine-required-message="Câmp obligatoriu"
                />
                <label class="label" for="name">Playlistul tau</label>
                <img class="checked" src="@/assets/img/check.svg" alt="" />
                <img class="error" src="@/assets/img/error.svg" alt="" />
              </div>
              <div class="soundPlatforms">
                <img src="@/assets/img/platforms/youtube.svg" />
                <img src="@/assets/img/platforms/spotify.svg" />
                <img src="@/assets/img/platforms/apple.svg" />
              </div>
              <p class="formTitle">Adauga blacklist</p>
              <p class="formSubtitle">
                Ce melodii sa nu punem sau ce sa nu <br />facem la eveniment?
              </p>
              <div class="inputWrapper maxHeight">
                <textarea
                  v-model="feedback"
                  class="input"
                  type="text"
                  placeholder=" "
                  autocomplete="off"
                  rows="5"
                  required
                  minlength="4"
                  style="resize: none"
                />
                <label class="label" for="adress"
                  >Scrie recomandarea ta aici</label
                >
                <img class="checked" src="@/assets/img/check.svg" alt="" />
                <img class="error" src="@/assets/img/error.svg" alt="" />
              </div>
              <div class="termsWrapper">
                <div class="terms">
                  <div class="inputWrapper">
                    <input
                      id="checkbox"
                      type="checkbox"
                      name="checkbox"
                      v-model="termsAndConditions"
                      required
                      data-pristine-required-message="Trebuie să acceptați termenii și condițiile pentru a genera contractul"
                    />
                    <label for="checkbox">
                      <p>
                        Sunt de acord cu
                        <a
                          href="https://storagedjbooking.blob.core.windows.net/terms-and-conditions/GDPR%20PREF.%20MUZICALE.pdf"
                          target="_blank"
                          >termenii si conditiile</a
                        >
                      </p>
                    </label>
                  </div>
                </div>
              </div>

              <button class="btn" @click="formifyClient">
                Trimite preferințele muzicale
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import * as Pristine from "pristinejs";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import PriventApi from "@/api/calls.js";
import { modal } from "@/store/loaders.js";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import Footer from "@/components/Footer.vue";

const defaultConfig = {
  classTo: "inputWrapper",
  errorClass: "has-danger",
  successClass: "has-succes",
  // class of the parent element where error text element is appended
  errorTextParent: "inputWrapper",
  // type of element to create for the error text
  errorTextTag: "p",
  // class of the error text element
  errorTextClass: "text-help",
};

export default {
  name: "GenerateContract",
  components: {
    Splide,
    SplideSlide,
    LottieAnimation,
    Footer,
  },
  data() {
    return {
      loading: true,
      offerLoaded: false,
      offer: {},
      validation: false,
      termsAndConditions: false,
      clientPlaylist: "",
      feedback: "",
      splideOptions: {
        type: "loop",
        perPage: 4,
        autoplay: true,
        interval: 2500,
        perMove: 1,
        arrows: false,
        pagination: false,
        breakpoints: {
          600: {
            perPage: 2.7,
            drag: true,
          },
          1000: {
            perPage: 3,
            drag: true,
          },
        },
      },
    };
  },
  mounted() {
    const offerId = this.$route.query.offerId;
    this.offer.id = offerId;
    PriventApi.getOfferForContract(offerId).then((response) => {
      const data = response.data;
      this.offer.location = data.location;
      this.offer.eventType = this.getEventType(data.eventType);
      this.offer.nrParticipants = data.nrAttendees;
      this.offer.artistFirstName = data.firstName;
      this.offer.artistLastName = data.lastName;
      this.offer.soundPackage = data.selectedSoundPackage;
      this.offer.lightPackage = data.selectedLightPackage;
      this.offer.datetime = this.getEventDate(data.eventDate);
      this.offer.dateOriginalFormat = data.eventDate;
      this.offer.price = data.price;
      this.offer.phone = data.phone;
      this.offer.id = offerId;
      this.offer.hasPrepayment = data.hasPrepayment;
      this.offer.playlist = data.playlist;
      this.offerLoaded = true;
      setTimeout(() => {
        this.turnLoaderOff();
      }, 1000);

      if (this.offer.playlist) {
        modal.displayModal(true, false, "playlistExpired");
      }
    });
  },
  methods: {
    turnLoaderOff() {
      setTimeout(() => {
        // Close proloader
        document.querySelector(".loader").classList.remove("show");
      }, 250);
    },
    isWeb() {
      if (window.innerWidth > 600) {
        return true;
      }
    },
    savePlaylist() {
      const data = {
        Playlist: this.clientPlaylist,
        TermsAndConditions: this.termsAndConditions,
        Blacklist: this.feedback,
      };

      PriventApi.addPlaylist(this.offer.id, data).then(() => {
        modal.displayModal(true, false, "playlistConfirmed");
      });
    },
    formifyClient() {
      var elem = document.getElementById("playlist");
      var pristine = new Pristine(
        document.getElementById("personalForm"),
        defaultConfig
      );
      pristine.addValidator(
        elem,
        (value) => {
          if (
            value.includes("youtube.com/playlist") ||
            value.includes("spotify.com") ||
            value.includes("apple.com")
          ) {
            return true;
          }
          return false;
        },
        "Va rugam introduceti un link valid de Youtube, Spotify sau Apple Music",
        1,
        false
      );
      var valid = pristine.validate();
      if (valid) {
        pristine.reset();
        pristine.destroy();
        this.validation = true;
        this.savePlaylist();
      }
    },
    getEventDate(date) {
      var predate = new Date(date);
      var dd = predate.getDate();

      var mm = predate.getMonth() + 1;
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }
      var hh = predate.getHours();
      if (hh < 10) {
        hh = "0" + hh;
      }
      var ss = predate.getMinutes();
      if (ss < 10) {
        ss = "0" + ss;
      }
      const finalDate =
        dd + "." + mm + "." + predate.getFullYear() + " " + hh + ":" + ss;
      this.offer.time = hh + ":" + ss;
      this.offer.date = dd + "." + mm + "." + predate.getFullYear();
      return finalDate;
    },
    getEventType(type) {
      switch (type) {
        case 1:
          return "Nuntă";
        case 2:
          return "Cununie civilă";
        case 3:
          return "Botez";
        case 4:
          return "Majorat";
        case 5:
          return "Petrecere aniversară";
        case 6:
          return "Corporate";
      }
    },
  },
};
</script>

<style lang="less" scoped>
body {
  overflow: auto;
  overflow-y: auto !important;
}

.generateContract-wrapper .wrapper .wrapperForm .form .btn {
  margin-top: 20px;
}

.generateContract-wrapper .wrapper .wrapperForm .form .termsWrapper {
  margin-top: 10px;
}
</style>
