<template>
  <div>
    <div id="opener" class="area">
      <img src="@/assets/img/jordon-conner-portocaliu.jpg" class="hero-image" />
      <div class="hero-logo">
        <img src="@/assets/img/textLight.svg" alt="" class="top-logo" />
        <img
          src="@/assets/img/logoHeadline.svg"
          alt=""
          class="top-logo-mobil"
        />
        <div class="page-txt">Descoperă pachetul recomandat</div>
        <div class="page-txt">
          pentru evenimentul tău <span>memorabil</span>
        </div>
      </div>
    </div>
    <div class="firstInfos">
      <div id="smallInfos" class="area">
        <div class="section rav_a">
          <div class="col zrcol_6 t_zrcol_6 col_pad_a date_col mb-4">
            <div class="single-info date-text">
              <div class="date-titlu">Tipul evenimentului</div>
              <div class="date-subtitlu event-type">
                {{ type }}
              </div>
            </div>
          </div>
          <div class="col zrcol_6 t_zrcol_6 col_pad_a date_col">
            <div class="single-info date-text">
              <div class="date-titlu">Data și ora</div>
              <div class="date-subtitlu event-date">
                {{ date }}
              </div>
            </div>
          </div>
          <div class="col zrcol_6 t_zrcol_6 col_pad_a date_col">
            <div class="single-info date-text">
              <div class="date-titlu">Locația</div>
              <div class="date-subtitlu event-location">
                {{ location }}
              </div>
            </div>
          </div>
          <div class="col zrcol_6 t_zrcol_6 col_pad_a date_col">
            <div class="single-info date-text">
              <div class="date-titlu">Nr. de persoane</div>
              <div class="date-subtitlu event-guests">
                {{ guests }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <card-dj
        v-if="artistName !== null"
        :artist-name="artistName"
        :artist-photo="artistPhoto"
        @redirect="redirect"
      />
    </div>
  </div>
</template>

<script>
import CardDj from '@/components/Offer/CardDj.vue'

export default {
  name: 'HeaderOffer',
  components: {
    CardDj
  },
  props: {
    type: String,
    location: String,
    guests: Number,
    date: String,
    artistName: String,
    artistPhoto: String,
    redirect: Function
  }
}
</script>
