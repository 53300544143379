<template>
  <div
    :id="'form-step-' + stepId"
    class="single-step"
    :data-name="stepName"
    :data-step="stepId"
  >
    <h2>Numărul de participanți</h2>
    <p class="subttl">
      Câți invitați sunt așteptați la eveniment?
    </p>
    <div class="divider" />
    <div class="field">
      <div class="control form-group">
        <input
          v-model="guests"
          name="guests"
          inputmode="numeric"
          type="number"
          placeholder=" "
          class="input creator"
          min="20"
          max="500"
          data-pristine-min-message="Numărul minim de participanți este 20."
          data-pristine-required-message="Te rugăm să introduci numărul participanților."
          data-pristine-max-message="Numărul maxim de participanți este 500."
          required
        />
        <label class="label" for="adress"
          >Introduceți numărul de participanți</label
        >
      </div>
      <div class="extra participants">
        <img class="illustration" src="@/assets/img/participants.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormStep4',
  props: {
    stepId: String,
    stepName: String,
    eventGuests: Number
  },
  data(){
    return{
      guests: this.eventGuests
    }
  },
  watch: {
    guests: function() {
      this.$emit('input', +this.guests)
    }
  }
}
</script>
