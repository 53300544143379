<template>
  <div class="profile-page-frame">
    <div class="opener profile-section section">
      <div class="buttonContainer">
        <button
          class="result-offer profile"
          :class="{ sended: sended }"
          :data-id="artistId"
          @click="createOffer(artistId)"
        >
          <span v-if="sended">Solicitare trimisă</span>
          <span v-else>Solicită oferta</span>
        </button>
      </div>
      <div class="image col zrcol_4 t_zrcol_3 m_zrcol_12 profileImage">
        <div class="marginRightWeb">
          <img :src="artistImg">
        </div>
      </div>
      <div
        class="name-and-offer col zrcol_8 t_zrcol_9 m_zrcol_12 profileCard mainContent"
      >
        <div class="marginLeftWeb">
          <div
            class="rating"
            style="position: relative"
          >
            <h2 class="artist">
              {{ artistName }}
            </h2>
            <ProfileStars
              v-if="artistReviews.length > 0"
              :artist-stars="artistReviews"
            />
          </div>
          <button
            class="result-offer profile"
            :class="{ sended: sended }"
            :data-id="artistId"
            @click="createOffer(artistId)"
          >
            <span v-if="sended">Solicitare trimisă</span>
            <span v-else>Solicită oferta</span>
          </button>
        </div>
      </div>
    </div>
    <div class="details-wrapper profile-section">
      <div
        v-if="artistDesc != ''"
        class="profileCard"
      >
        <h2>Descriere</h2>
        <div class="page-txt">
          {{ artistDesc }}
        </div>
      </div>
    </div>
    <div class="badgesWeb">
      <div
        v-if="
          artistLink1.includes('soundcloud') ||
            artistLink2.includes('soundcloud')
        "
        class="badge"
        @click="openUrlWeb('soundcloud')"
      >
        <img src="@/assets/img/SoundcloudGrey.svg">
      </div>
      <div
        v-if="
          artistLink1.includes('youtube') || artistLink2.includes('youtube')
        "
        class="badge"
        @click="openUrlWeb('youtube')"
      >
        <img src="@/assets/img/YoutubeGrey.svg">
      </div>
      <div
        v-if="
          artistLink1.includes('spotify') || artistLink2.includes('spotify')
        "
        class="badge"
        @click="openUrlWeb('spotify')"
      >
        <img src="@/assets/img/SpotifyGrey.svg">
      </div>
    </div>
    <div v-if="artistLinks || artistLinks.length">
      <div
        v-for="link in filteredArtistLinks"
        :key="link"
        class="feedback-wrapper profile-section"
      >
        <div class="profileCard">
          <p class="txt">
            Asculta artistul tau pe {{ linkType(link) }}
          </p>
          <div class="badgeContainer">
            <div
              v-if="link.includes('soundcloud')"
              class="badge"
              @click="openUrl(link)"
            >
              <img src="@/assets/img/SoundcloudLogo.svg">
            </div>
            <div
              v-if="link.includes('youtube')"
              class="badge"
              @click="openUrl(link)"
            >
              <img src="@/assets/img/YoutubeLogo.svg">
            </div>
            <div
              v-if="link.includes('spotify')"
              class="badge"
              @click="openUrl(link)"
            >
              <img src="@/assets/img/SpotifyLogo.svg">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="galerie-wrapper profile-section">
      <h2>Galerie foto</h2>
      <v-gallery
        :images="artistImagesGallery"
        :index="showIndex"
        @close="showIndex = null"
      />
      <div
        :id="'Gallery-' + artistId"
        class="page-txt all_gallery"
      >
        <div
          v-for="(media, index) in artistImages"
          :key="index"
          class="single_gallery_image"
        >
          <div class="single_gallery_image-frame">
            <div
              class="image-crop"
              :style="{ backgroundImage: 'url(' + media.imageBase + ')' }"
              @click="showIndex = index"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="artistReviews.length > 0"
      class="recenzii-wrapper profile-section"
    >
      <div class="recenzii-row">
        <h2>Recenzii</h2>
        <h3 v-if="artistReviews.length < 2">
          O recenzie
        </h3>
        <h3 v-else>
          {{ artistReviews.length }} recenzii
        </h3>
      </div>
      
      <div
        :id="'Reviews-' + artistId"
        class="page-txt all-reviews"
      >
        <div
          v-for="(review, index) in artistReviews"
          :key="index"
          class="single_review"
        >
          <div class="single_review_frame">
            <div class="single_review_name">
              {{ review.name }}
            </div>
            <div class="single_review_stars">
              <img
                v-for="(star, index) in review.ratingDj"
                :key="index"
                src="@/assets/img/star.svg"
              >
            </div>
            <div class="single_review_text">
              {{ review.feedbackDj }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loader } from '@/store/loaders.js'
import PriventApi from '@/api/calls.js'
import { tns } from 'tiny-slider/src/tiny-slider'
import ProfileStars from '@/components/ProfileStars.vue'
import VGallery from 'vue-gallery'

export default {
  name: 'ProfilePage',
  components: { ProfileStars, VGallery },
  props: {
    artistId: Number,
    requestedOffer: Array,
    offerId: String
  },
  data() {
    return {
      artistImg: null,
      artistName: null,
      artistDesc: null,
      artistImages: null,
      artistImagesGallery: [],
      artistReviews: [],
      artistLink1: '',
      artistLink2: '',
      artistLinks: [],
      sended: false,
      showIndex: null,
      toggler: false
    }
  },
  computed: {
    filteredArtistLinks() {
      return this.artistLinks.filter(Boolean)
    }
  },
  mounted() {
    const urlString = window.location.href
    const urlNew = new URL(urlString)
    this.artistIdFromUrl = urlNew.searchParams.get('artistId')
    // set page scroll to 0
    document.documentElement.scrollTop = 0

    // Turn loader ON
    loader.displayLoader(true)

    // API call - get dj info
    PriventApi.loadProfile(this.artistId)
      .then((response) => {
        var rawData = response.data
        this.artistImg = rawData.profileImage
        this.artistName = rawData.firstName + ' ' + rawData.lastName
        this.artistDesc = rawData.description
        this.artistImages = rawData.media
        rawData.media.forEach((element) => {
          this.artistImagesGallery.push(element.imageBase)
        })
        this.artistLink1 = rawData.playlistUrl1
        this.artistLink2 = rawData.playlistUrl2
        if (rawData.Rating !== null) {
          this.artistReviews = rawData.ratings
        } else {
          this.artistReviews = []
        }
        this.initCarousels()
        this.artistLinks = [this.artistLink1, this.artistLink2]
        this.artistLinks.filter(Boolean)
        // Turn loader OFF
        loader.displayLoader(false)
      })
      .catch(() => {
        // Turn loader OFF
        loader.displayLoader(false)
      })
  },
  methods: {
    linkType(string) {
      if (string.includes('spotify')) {
        return 'Spotify'
      } else if (string.includes('youtube')) {
        return 'Youtube'
      } else if (string.includes('soundcloud')) {
        return 'Soundcloud'
      }
    },
    openUrlWeb(type) {
      if (this.artistLink1.includes(type)) {
        this.openUrl(this.artistLink1)
      } else if (this.artistLink2.includes(type)) {
        this.openUrl(this.artistLink2)
      }
    },
    openUrl(url) {
      window.open(url)
    },
    createOffer(id) {
      if (!this.artistIdFromUrl) {
        var dataID = id
        this.sended = true
        this.$parent.showModal(dataID)
      } else {
        this.$router.push(`/?artistId=${id}`)
      }
    },
    initCarousels() {
      this.$nextTick(() => {
        // Initialize Gallery Carousels
        tns({
          container: '#Gallery-' + this.artistId,
          slideBy: 'page',
          autoplay: false,
          controls: false,
          nav: false,
          loop: false,
          speed: 600,
          responsive: {
            50: {
              items: 1,
              center: true,
              mouseDrag: true,
              edgePadding: 30,
              swipeAngle: 360,
              preventScrollOnTouch: 'force'
            },
            1000: {
              items: 3,
              mouseDrag: true,
              center: false,
              controls: true,
              controlsText: [
                '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAvCAYAAAASP7HuAAAABHNCSVQICAgIfAhkiAAAAYxJREFUWIW12D1OAkEUB/D/WzaAje4NpLDAii0kUlLbeQM8gEQSjmFCNKFWT+ARwA4roaKhkNKO0CgG8igwyuIC8/HedDNvk18yyc77ICgtbsURMgclLD4H1OhPACBQgdrlGJn8G4i7CHMdbsWRCsbtcoxF0AGhsDqhGGH+Rhz7gyhKBvAOAKQOAU903auJYSaQCGYKeWM2kBdmCzljLpAT5gpZYz6QFeYLGWMSkBEmBe3FJKGdmDS0FdOAUjEt6B+mCSUwbQj4ydR8d3aBRfCqCQEA8e3pMbJHIxBCTQgAAmQPL1OgkTS0wr6nzwB/bZyf8H3lQRyj5nCM2bQI8Ad4LUKoSYMBAFBzOMZ8VgTxIBEVBpP/WSuOEOa6AJUSXzEeqd67EsW0wfS3UQnc/uorgLvzmTC4P1MLgmY1iBBoXl0JgHZ1oydoXxF7gG61viPo3sU4gH79mSXo33lagDI9tSEoNy0wAMXmINToTzCfVYG0BHwuP3TZCv66CitxpYwXqveqGs4GWims75eDgB166t7wEQAAAABJRU5ErkJggg==">',
                '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAvCAYAAAASP7HuAAAABHNCSVQICAgIfAhkiAAAAYxJREFUWIW12D1OAkEUB/D/WzaAje4NpLDAii0kUlLbeQM8gEQSjmFCNKFWT+ARwA4roaKhkNKO0CgG8igwyuIC8/HedDNvk18yyc77ICgtbsURMgclLD4H1OhPACBQgdrlGJn8G4i7CHMdbsWRCsbtcoxF0AGhsDqhGGH+Rhz7gyhKBvAOAKQOAU903auJYSaQCGYKeWM2kBdmCzljLpAT5gpZYz6QFeYLGWMSkBEmBe3FJKGdmDS0FdOAUjEt6B+mCSUwbQj4ydR8d3aBRfCqCQEA8e3pMbJHIxBCTQgAAmQPL1OgkTS0wr6nzwB/bZyf8H3lQRyj5nCM2bQI8Ad4LUKoSYMBAFBzOMZ8VgTxIBEVBpP/WSuOEOa6AJUSXzEeqd67EsW0wfS3UQnc/uorgLvzmTC4P1MLgmY1iBBoXl0JgHZ1oydoXxF7gG61viPo3sU4gH79mSXo33lagDI9tSEoNy0wAMXmINToTzCfVYG0BHwuP3TZCv66CitxpYwXqveqGs4GWims75eDgB166t7wEQAAAABJRU5ErkJggg==">'
              ]
            }
          }
        })
        // END - Initialize Gallery Carousels
        if (document.documentElement.clientWidth < 600) {
          document
            .querySelectorAll('#Gallery-' + this.artistId + ' a')
            .forEach((elm) => {
              elm.removeAttribute('href')
            })
        }
        if (this.artistReviews.length > 0) {
          // Initialize Reviws Carousels
          tns({
            container: '#Reviews-' + this.artistId,
            slideBy: 'page',
            autoplay: false,
            controls: false,
            nav: false,
            loop: false,
            speed: 600,
            responsive: {
              50: {
                items: 1,
                center: false,
                mouseDrag: true,
                edgePadding: 20,
                swipeAngle: 360,
                preventScrollOnTouch: 'force'
              },
              1000: {
                items: 2,
                mouseDrag: true,
                center: false,
                controls: true,
                controlsText: [
                  '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAvCAYAAAASP7HuAAAABHNCSVQICAgIfAhkiAAAAYxJREFUWIW12D1OAkEUB/D/WzaAje4NpLDAii0kUlLbeQM8gEQSjmFCNKFWT+ARwA4roaKhkNKO0CgG8igwyuIC8/HedDNvk18yyc77ICgtbsURMgclLD4H1OhPACBQgdrlGJn8G4i7CHMdbsWRCsbtcoxF0AGhsDqhGGH+Rhz7gyhKBvAOAKQOAU903auJYSaQCGYKeWM2kBdmCzljLpAT5gpZYz6QFeYLGWMSkBEmBe3FJKGdmDS0FdOAUjEt6B+mCSUwbQj4ydR8d3aBRfCqCQEA8e3pMbJHIxBCTQgAAmQPL1OgkTS0wr6nzwB/bZyf8H3lQRyj5nCM2bQI8Ad4LUKoSYMBAFBzOMZ8VgTxIBEVBpP/WSuOEOa6AJUSXzEeqd67EsW0wfS3UQnc/uorgLvzmTC4P1MLgmY1iBBoXl0JgHZ1oydoXxF7gG61viPo3sU4gH79mSXo33lagDI9tSEoNy0wAMXmINToTzCfVYG0BHwuP3TZCv66CitxpYwXqveqGs4GWims75eDgB166t7wEQAAAABJRU5ErkJggg==">',
                  '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAvCAYAAAASP7HuAAAABHNCSVQICAgIfAhkiAAAAYxJREFUWIW12D1OAkEUB/D/WzaAje4NpLDAii0kUlLbeQM8gEQSjmFCNKFWT+ARwA4roaKhkNKO0CgG8igwyuIC8/HedDNvk18yyc77ICgtbsURMgclLD4H1OhPACBQgdrlGJn8G4i7CHMdbsWRCsbtcoxF0AGhsDqhGGH+Rhz7gyhKBvAOAKQOAU903auJYSaQCGYKeWM2kBdmCzljLpAT5gpZYz6QFeYLGWMSkBEmBe3FJKGdmDS0FdOAUjEt6B+mCSUwbQj4ydR8d3aBRfCqCQEA8e3pMbJHIxBCTQgAAmQPL1OgkTS0wr6nzwB/bZyf8H3lQRyj5nCM2bQI8Ad4LUKoSYMBAFBzOMZ8VgTxIBEVBpP/WSuOEOa6AJUSXzEeqd67EsW0wfS3UQnc/uorgLvzmTC4P1MLgmY1iBBoXl0JgHZ1oydoXxF7gG61viPo3sU4gH79mSXo33lagDI9tSEoNy0wAMXmINToTzCfVYG0BHwuP3TZCv66CitxpYwXqveqGs4GWims75eDgB166t7wEQAAAABJRU5ErkJggg==">'
                ]
              }
            }
          })
        }
        // END - Initialize Reviews Carousels

        // Fix margin for carousels when there are more than 3 items
        document
          .querySelectorAll('.profile-page .tns-outer')
          .forEach((outer) => {
            var slidesInside = outer.querySelectorAll('.tns-item')
            if (slidesInside.length > 3) {
              outer.classList.add('many-items')
            }
          })
      })
    }
  }
}
</script>
