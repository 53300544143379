<template>
  <div id="personalForm">
    <div class="inputWrapper first">
      <input
        v-model="client.name"
        class="input"
        type="text"
        name="clientName"
        placeholder=" "
        autocomplete="off"
        required
        minlength="4"
        data-pristine-minlength-message="Introduceti minim 4 caractere"
        data-pristine-required-message="Câmp obligatoriu"
      />
      <label class="label" for="name">Nume beneficiar</label>
      <img class="checked" src="@/assets/img/check.svg" alt="" />
      <img class="error" src="@/assets/img/error.svg" alt="" />
    </div>
    <div class="inputWrapper">
      <input
        v-model="client.address"
        class="input"
        type="text"
        name="street_address"
        placeholder=" "
        autocomplete="off"
        required
        minlength="4"
        data-pristine-minlength-message="Introduceti minim 4 caractere"
        data-pristine-required-message="Câmp obligatoriu"
      />
      <label class="label" for="adress">Adresa</label>
      <img class="checked" src="@/assets/img/check.svg" alt="" />
      <img class="error" src="@/assets/img/error.svg" alt="" />
    </div>
    <div class="inputWrapper">
      <input
        v-model="client.city"
        class="input"
        type="text"
        name="address-level2"
        placeholder=" "
        autocomplete="off"
        required
        minlength="4"
        data-pristine-minlength-message="Introduceti minim 3 caractere"
        data-pristine-required-message="Câmp obligatoriu"
      />
      <label class="label" for="adress">Oraș</label>
      <img class="checked" src="@/assets/img/check.svg" alt="" />
      <img class="error" src="@/assets/img/error.svg" alt="" />
    </div>
    <div class="inputWrapper">
      <input
        v-model="client.county"
        class="input"
        type="text"
        name="address-level1"
        placeholder=" "
        autocomplete="off"
      />
      <label class="label" for="adress">Județ</label>
      <img class="checked" src="@/assets/img/check.svg" alt="" />
      <img class="error" src="@/assets/img/error.svg" alt="" />
    </div>
    <div class="inputWrapper">
      <input
        v-model="client.cnp"
        v-mask="'#############'"
        class="input"
        type="number"
        placeholder=" "
        autocomplete="off"
        required
        minlength="13"
        data-pristine-minlength-message="CNP invalid"
        data-pristine-required-message="Câmp obligatoriu"
      />
      <label class="label" for="cnp">CNP</label>
      <img class="checked" src="@/assets/img/check.svg" alt="" />
      <img class="error" src="@/assets/img/error.svg" alt="" />
    </div>
    <div class="inputWrapper">
      <input
        v-model="client.series"
        v-model.trim="client.series"
        v-mask="'AA/######'"
        class="input"
        type="text"
        placeholder=" "
        autocomplete="off"
        data-pristine-required-message="Câmp obligatoriu"
        required
      />
      <label class="label" for="seriesCI">Serie si numar C.I.</label>
      <img class="checked" src="@/assets/img/check.svg" alt="" />
      <img class="error" src="@/assets/img/error.svg" alt="" />
    </div>
    <div class="inputWrapper">
      <input
        v-model="client.mail"
        v-model.trim="client.mail"
        class="input"
        type="email"
        placeholder=" "
        autocomplete="off"
        data-pristine-required-message="Câmp obligatoriu"
        data-pristine-email-message="Format incompatibil"
        required
      />
      <label class="label" for="mail">Email beneficiar</label>
      <img class="checked" src="@/assets/img/check.svg" alt="" />
      <img class="error" src="@/assets/img/error.svg" alt="" />
    </div>
    <div class="termsWrapper">
      <div class="terms">
        <div class="inputWrapper">
          <input
            id="checkbox"
            type="checkbox"
            name="checkbox"
            v-model="client.termsAndConditions"
            required
            data-pristine-required-message="Trebuie să acceptați termenii și condițiile pentru a genera contractul"
          />
          <label for="checkbox"
            ><p>
              Sunt de acord cu
              <a
                href="https://storagedjbooking.blob.core.windows.net/terms-and-conditions/GDPR%20CONTRACT.pdf"
                target="_blank"
                >termenii si conditiile</a
              >
            </p></label
          >
        </div>
      </div>
    </div>
    <button class="btn" @click="formifyClient">Generează contractul</button>
  </div>
</template>
<script>
import * as Pristine from 'pristinejs'
import { mask } from 'vue-the-mask'

const defaultConfig = {
  classTo: 'inputWrapper',
  errorClass: 'has-danger',
  successClass: 'has-succes',
  // class of the parent element where error text element is appended
  errorTextParent: 'inputWrapper',
  // type of element to create for the error text
  errorTextTag: 'p',
  // class of the error text element
  errorTextClass: 'text-help'
}
export default {
  name: 'PersonalForm',
  directives: { mask },
  data() {
    return {
      client: {
        name: null,
        address: null,
        city: null,
        county: '',
        cnp: null,
        series: null,
        mail: null,
        termsAndConditions: false
      }
    }
  },
  methods: {
    formifyClient: function() {
      var pristine = new Pristine(
        document.getElementById('personalForm'),
        defaultConfig
      )
      var valid = pristine.validate()
      if (valid) {
        pristine.reset()
        pristine.destroy()
        this.validation = true
        this.client.address = `${this.client.address}, ${this.client.city}`

        if (this.client.county.length > 0) {
          this.client.address += `, ${this.client.county}`
        }

        document.querySelector('body').classList.add('printFriendly')


        this.$emit('generateContract', this.client)
      }
    }
  }
}
</script>
