<template>
  <div
    class="loader"
    :class="{show: displayLoader.display}"
  >
    <div class="pulsar">
      <lottie-animation
        ref="lottieVue"
        class="lottie"
        :autoplay="true"
        :click-play="false"
        :loop="true"
        path="lottie/showResults.json"
      />
    </div>
  </div>
</template>

<script>
import { loader } from '@/store/loaders.js'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
export default {
  name: 'LoaderPulsar',
  components: {
    LottieAnimation
  },
  data() {
    return {
      displayLoader: loader.state
    }
  }
}
</script>
