<template>
  <div class="form-place">
    <!-- Loader -->
    <div class="fullscreen-loader">
      <div class="wrapperLoader">
        <div class="container">
          <lottie-animation
            ref="lottieVue"
            class="lottieLoader"
            :autoplay="true"
            :click-play="false"
            :loop="true"
            path="lottie/logo.json"
          />
        </div>
      </div>
    </div>
    <!-- end Loader -->
    <div class="global-wrapper offer-wrapper">
      <div class="offer-expired">
        <div class="container-expired">
          <lottie-animation
            ref="lottieVue"
            class="lottieLoader"
            :autoplay="true"
            :click-play="false"
            :loop="true"
            path="lottie/errorDark.json"
          />
          <div class="text">
            <h3>Oferta a expirat <br />sau a fost stearsa!</h3>
            <h4 @click="redirectToHome()">Generează o ofertă nouă</h4>
          </div>
        </div>
      </div>

      <!-- Start Header -->
      <header id="offerheader">
        <header-offer
          :location="location"
          :type="type"
          :guests="guests"
          :date="date"
          :artist-name="artistName"
          :artist-photo="artistPhoto"
          :redirect="redirect"
        />
      </header>
      <!-- end header -->
      <section id="offerDetails" class="area">
        <div v-if="packagesLoaded">
          <div id="soundExperience" class="categories-card">
            <div class="wrapper">
              <div id="artist-name" class="mid-ttl">Sound Experience</div>
              <div class="small-ttl">Selectează pachetul de sunet</div>
            </div>
          </div>

          <cards-package
            :offer-requested="offerRequested"
            :offer-confirmed="offerConfirmed"
            :packages-sound="packagesSound"
            :options="countSoundPackages()"
            :tiny-slider-options="tinySliderOptions"
            :is-sound-package="true"
            :selected-package="selectedSoundPackage"
            @updatePrice="updatePrice"
            @selectSound="selectSound($event)"
          />

          <!-- Showlight Starts here-->
          <div
            v-if="
              !(offerRequested && selectedLightPackage === null) &&
              packagesLight.length > 0
            "
          >
            <div
              v-if="packagesLight != null"
              id="showlight"
              class="categories-card"
            >
              <div class="wrapper">
                <div id="artist-name" class="mid-ttl">Showlight</div>
                <div class="small-ttl">Selectează pachetul de lumini</div>
              </div>
            </div>
            <cards-package
              :offer-requested="offerRequested"
              :offer-confirmed="offerConfirmed"
              :packages-sound="packagesLight"
              :options="countLightPackages()"
              :tiny-slider-options="tinySliderOptions"
              :is-sound-package="false"
              :selected-package="selectedLightPackage"
              @updatePrice="updatePrice"
              @selectLight="selectLight($event)"
            />
          </div>
        </div>
      </section>
      <section id="priceEvent" class="area">
        <div class="container">
          <div class="pachet">
            <div class="small-ttl">Pretul pachetului tău:</div>
            <div
              v-if="price !== basePrice || offerRequested !== false"
              class="mid-ttl"
            >
              <span class="event-price">{{ price }}</span> Lei
            </div>
            <div v-else class="mid-ttl-select">
              Va rugam selectati <br />
              un pachet
            </div>
            <offer-resume
              :selected-sound-package="selectedSoundPackage"
              :selected-light-package="selectedLightPackage"
              :artist-name="artistName"
            />
          </div>
          <div class="pachetTermeni">
            <div class="termeni">
              <div class="small-ttl">Termenii și condițiile</div>
              <ul class="termeni-generali-main">
                <li class="list-item">
                  Oferta este valabila pentru maximum 3 zile
                </li>
                <li class="list-item">30% avans la semnarea contractului</li>
                <li class="list-item">
                  Prețul include și tariful transportului
                </li>
                <li class="list-item">
                  Anularea evenimentului se face cu cel putin 30 de zile inainte
                </li>
                <li class="list-item">
                  Prestația DJ-ului selectat este de 8 ore
                </li>
              </ul>
            </div>
            <button
              v-if="selectedSoundPackage === null"
              class="btn-cls null"
              @click="scrollToSoundExperience()"
            >
              Selectează pachetele
            </button>
            <button
              v-else-if="
                (offerRequested === true && offerConfirmed === false) ||
                offerConfirmed === true
              "
              class="btn-cls null"
            >
              Selecția confirmată
            </button>
            <button
              v-else
              :disabled="requestingOffer"
              class="btn-cls"
              :class="{ null: requestingOffer }"
              @click="requestOffer"
            >
              Confirma selecția
            </button>

            <!-- TODO: confirma oferta -> oferta confirmata gri -> apare alt buton sub cu suna pentru confirmare-->
            <!-- <button class="btn-clasic">Confirmare telefonica</button> -->
          </div>
        </div>
      </section>
      <section id="otherOffer" class="area">
        <div class="categories-card">
          <div class="wrapper">
            <div id="artist-name" class="mid-ttl">Solicită altă ofertă</div>
            <div class="small-ttl">
              Solicită oferta pentru alți artiști disponibili
            </div>
          </div>
          <div class="wrapper">
            <button class="button" @click="searchArtist">
              Caută alt artist
            </button>
          </div>
        </div>
      </section>

      <!-- start footer -->
      <Footer />
      <!-- end footer -->
    </div>
  </div>
</template>

<script>
import PriventApi from "@/api/calls.js";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import HeaderOffer from "@/components/Offer/HeaderOffer.vue";
import CardsPackage from "@/components/Offer/CardsPackage.vue";
import OfferResume from "@/components/Offer/OfferResume.vue";
import { modal } from "@/store/loaders.js";
import Footer from "@/components/Footer.vue";

export default {
  name: "Offer",
  components: {
    LottieAnimation,
    HeaderOffer,
    CardsPackage,
    OfferResume,
    Footer,
  },
  data() {
    return {
      tinySliderOptions: {
        drag: false,
        mouseDrag: false,
        loop: true,
        items: 1,
        controlsContainer: "#customize-controls",
        controlsPosition: "bottom",
        navPosition: "bottom",
      },
      animationSpeed: 1,
      currentInfo: false,
      packagesLight: [],
      packagesSound: [],
      offerId: null,
      location: null,
      type: null,
      date: null,
      guests: null,
      price: null,
      artist: null,
      artistName: null,
      artistPhoto: null,
      sound: null,
      lights: null,
      optionsSound: {
        rewind: true,
        arrows: false,
        trimSpace: false,
        drag: false,
        pagination: false,
        perPage: 3,
        // autoWidth: true,
        padding: {
          right: "2.5rem",
          left: "0rem",
        },
        breakpoints: {
          600: {
            perPage: 1,
            drag: true,
            pagination: true,
          },
        },
      },
      optionsSoundPlus3: {
        rewind: true,
        arrows: false,
        trimSpace: false,
        drag: true,
        pagination: false,
        perPage: 3,
        // autoWidth: true,
        padding: {
          right: "2.5rem",
          left: "0rem",
        },
        breakpoints: {
          600: {
            perPage: 1,
            drag: true,
            pagination: true,
          },
        },
      },
      optionsLight: {
        rewind: true,
        arrows: false,
        trimSpace: false,
        drag: false,
        pagination: false,
        perPage: 3,
        // autoWidth: true,
        padding: {
          right: "2.5rem",
          left: "0rem",
        },
        breakpoints: {
          600: {
            perPage: 1,
            drag: true,
            pagination: true,
          },
        },
      },
      optionsLightPlus3: {
        rewind: true,
        arrows: false,
        trimSpace: false,
        drag: true,
        pagination: false,
        perPage: 3,
        // autoWidth: true,
        padding: {
          right: "2.5rem",
          left: "0rem",
        },
        breakpoints: {
          600: {
            perPage: 1,
            drag: true,
            pagination: true,
          },
        },
      },
      options2: {
        drag: false,
        arrows: true,
        pagination: true,
        isNavigation: false,
      },
      options3: {
        rewind: true,
      },
      packagesLoaded: false,
      selectedSoundPackage: null,
      selectedLightPackage: null,
      offerExpired: false,
      offerRequested: false,
      offerConfirmed: false,
      offerRequestedSucces: true,
      basePrice: 0,
      requestingOffer: false,
    };
  },

  created() {
    // const urlString = 'https://priventstorage.z16.web.core.windows.net/Offer/index.html?offerId=edacc3ee-4d40-4667-a22b-371f711d9e58'
    const urlString = window.location.href;
    const urlNew = new URL(urlString);
    this.offerId = urlNew.searchParams.get("offerId");
  },
  mounted() {
    document.querySelector(".form-place").style.width = "100%";
    document.querySelector(".form-place").style.maxWidth = "100%";
    document.querySelector(".form-place").style.flex = "0 0 100%";
    document.querySelector(".form-place").style.padding = "0";

    document.querySelector(".form-place").classList.add("preloaded");
    document.body.style.overflow = "hidden";

    // API call - get dj info
    PriventApi.loadOffer(this.offerId)
      .then((response) => {
        var rawData = response.data;
        this.location = rawData.location;
        this.type = this.getEventType(rawData.eventType);
        this.date = this.getEventDate(rawData.eventDate);
        if (new Date() > new Date(rawData.eventDate)) {
          this.requestingOffer = true;
        }
        this.guests = rawData.nrAttendees;
        this.basePrice = rawData.price;
        this.price = this.basePrice;
        this.artist = rawData.artistId;
        if (rawData.firstName !== null || rawData.lastName !== null) {
          this.artistName = rawData.firstName + " " + rawData.lastName;
        }
        this.artistPhoto = rawData.profileImage;
        this.offerRequested = rawData.requested;
        this.sound = rawData.sound;
        this.lights = rawData.light;
        if (rawData.selectedSoundPackage) {
          this.selectedSoundPackage = rawData.selectedSoundPackage;
          this.selectedLightPackage = rawData.selectedLightPackage;
        }
        this.offerConfirmed = rawData.confirmed;
        rawData.packages.forEach((element) => {
          if (element.type === 0) {
            if (element.youtubeLink !== null) {
              var youtubeLink = element.youtubeLink.replace(
                "watch?v=",
                "embed/"
              );
              element.photos.unshift({ url: youtubeLink });
            }
            this.packagesSound.push(element);
          }
          if (element.type === 1) {
            if (element.youtubeLink !== null) {
              youtubeLink = element.youtubeLink.replace("watch?v=", "embed/");
              element.photos.unshift({ url: youtubeLink });
            }
            this.packagesLight.push(element);
          }
        });
        // add index to use arrows for tiny slider (detect the index of the tinyslider)
        var counter = 0;
        this.packagesSound.forEach((element) => {
          element.index = counter;
          counter = counter + 1;
        });
        counter = 0;
        this.packagesLight.forEach((element) => {
          element.index = counter;
          counter = counter + 1;
        });
        // this.initCarousels()
        this.packagesLoaded = true;
        this.turnLoaderOff();
      })
      .catch(() => {
        setTimeout(() => {
          modal.displayModal(true, false, "offerExpired");
        }, 1000);
        this.turnLoaderOff();
      });
  },
  methods: {
    countSoundPackages() {
      if (this.packagesSound.length > 3) {
        return this.optionsSoundPlus3;
      } else {
        return this.optionsSound;
      }
    },
    countLightPackages() {
      if (this.packagesLight.length > 3) {
        return this.optionsLightPlus3;
      } else {
        return this.optionsLight;
      }
    },
    scrollToSoundExperience() {
      document
        .getElementById("soundExperience")
        .scrollIntoView({ behavior: "smooth" });
    },
    redirectToHome() {
      this.$router.push("/");
    },
    selectLight(p) {
      this.selectedLightPackage = p;
    },
    selectSound(p) {
      this.selectedSoundPackage = p;
    },
    onChange(entry, unobserve) {
      // After loading Cancel monitoring, optimise performance
      if (entry.isIntersecting) {
        unobserve();
      }
      this.currentInfo = entry.isIntersecting
        ? this.imageSrc
        : "https://avatars2.githubusercontent.com/u/20992106?s=460&v=4";
    },
    updatePrice() {
      this.price = this.basePrice;
      if (
        this.selectedSoundPackage !== null &&
        this.selectedLightPackage !== null
      ) {
        this.price = this.basePrice + this.selectedSoundPackage.price;
        this.price += this.selectedLightPackage.price;
      } else if (this.selectedSoundPackage !== null) {
        this.price = this.basePrice + this.selectedSoundPackage.price;
      } else if (this.selectedLightPackage !== null) {
        this.price = this.basePrice + this.selectedLightPackage.price;
      }
    },
    requestOffer() {
      this.requestingOffer = true;
      if (this.selectedSoundPackage !== null) {
        var data = {
          ClientOfferId: this.offerId,
          SoundPackageId: this.selectedSoundPackage.id,
          LightPackageId: 0,
        };
        if (this.selectedLightPackage !== null) {
          data.LightPackageId = this.selectedLightPackage.id;
        }
        PriventApi.requestOffer(data)
          .then(() => {
            this.offerRequested = true;
            this.offerRequestedSucces = true;
            modal.displayModal(true, true, "selectionSuccess");
            this.requestingOffer = false;
          })
          .catch(() => {
            this.offerRequestedSucces = false;
            modal.displayModal(true, true, "selectionError");
            this.requestingOffer = false;
          });
      } else {
        document
          .getElementById("soundExperience")
          .scrollIntoView({ behavior: "smooth" });
      }
    },
    redirect() {
      this.$router.push(
        "Profile?artistId=" + this.artist + "&&offerId=" + this.offerId
      );
    },
    turnLoaderOff() {
      setTimeout(() => {
        // Start animation
        // Close proloader
        setTimeout(() => {
          document.querySelector(".form-place").classList.remove("preloaded");
          document.body.style.overflow = "unset";
          if (this.offerExpired) {
            document.querySelector(".offer-expired").classList.add("displayed");
            setTimeout(() => {
              document.querySelector(".fullscreen-loader").style.display =
                "none";
            }, 200);
          }
          setTimeout(() => {
            document.querySelector(".fullscreen-loader").style.display = "none";
          }, 200);
        }, 1000);
      }, 250);
    },
    // Initialize Carousels
    // End -- Init Carousels
    getEventType(type) {
      switch (type) {
        case 1:
          return "Nuntă";
        case 2:
          return "Cununie civilă";
        case 3:
          return "Botez";
        case 4:
          return "Majorat";
        case 5:
          return "Petrecere aniversară";
        case 6:
          return "Corporate";
      }
    },
    getEventDate(date) {
      var predate = new Date(date);
      var dd = predate.getDate();

      var mm = predate.getMonth() + 1;
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }
      var hh = predate.getHours();
      if (hh < 10) {
        hh = "0" + hh;
      }
      var ss = predate.getMinutes();
      if (ss < 10) {
        ss = "0" + ss;
      }
      const finalDate =
        dd + "." + mm + "." + predate.getFullYear() + " " + hh + ":" + ss;

      return finalDate;
    },
    searchArtist() {
      this.$router.push(`/?offerId=${this.offerId}`);
    },
  },
};
</script>

<style lang="less" scoped>
.btn-cls.negative.disabled {
  background: transparent !important;
  border: 2.5px solid #f27f32;
}
.card-sound.general-card {
  margin-bottom: 0px !important;
}
.splide__slide {
  padding-bottom: 30px !important;
}
.splide {
  margin-bottom: 5px !important;
}
/*.splide__slide img {
  width : 100%;
  height: auto;
}*/
div.form-place {
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%;
  padding: 0;
}
.offer-wrapper {
  .container {
    max-width: 1260px;
  }
  #getMore {
    .single-more {
      &.single-more_djmc {
        background-image: url("./../assets/img/djmc.png");
      }

      &.single-more_live {
        background-image: url("./../assets/img/live.png");
      }

      &.single-more_foto {
        background-image: url("./../assets/img/foto.png");
      }

      &.single-more_bar {
        background-image: url("./../assets/img/bar.png");
      }
    }
  }
}

@media (max-width: 600px) {
  .offer-wrapper {
    .container {
      padding: 0px 15px;
    }
  }
}
</style>
