import { BlobServiceClient } from "@azure/storage-blob";
// import { promisify } from "util";

const path = require("path");
const crypto = require("crypto");

const blobSasUrl = process.env.VUE_APP_blobSasUrl;
const blobServiceClient = new BlobServiceClient(blobSasUrl);

const readFileAsBlob = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      const result = event.target.result;
      const blob = new Blob([result], { type: file.type });
      resolve(blob);
    };

    fileReader.onerror = () => {
      reject(new Error("Error reading the file."));
    };

    fileReader.readAsArrayBuffer(file);
  });
};

const getImageSize = async(file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = function() {
      const imageSize = {
        width: img.width,
        height: img.height,
      };
      resolve(imageSize);
    };

    img.onerror = function() {
      reject(new Error("Failed to load image."));
    };

    img.src = URL.createObjectURL(file);
  });
};

const compressImage = async(file, maxWidth, maxHeight, quality) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = function() {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        "image/jpeg",
        quality
      );
    };

    img.onerror = function() {
      reject(new Error("Image loading failed."));
    };

    img.src = URL.createObjectURL(file);
  });
};

const generateUniqueBlobName = (originalFileName) => {
  const fileExtension = path.extname(originalFileName);
  const uniqueName = crypto.randomBytes(16).toString("hex");
  return `${uniqueName}${fileExtension}`;
};

const blobStorageHelper = {
  async createContainerIfNotExists(offerId) {
    let containerExists = false;
    let containerClient

    try{
    containerClient = blobServiceClient.getContainerClient(offerId);
    containerExists = await containerClient.exists();
    }
    catch(error){
      // Ignore
    }

    if (!containerExists) {
      const options = { access: "blob" };
      try {
        await containerClient.create(options);
      } catch (error) {
        //Ignore
      }
    }
  },
  async uploadFile(file, progressCallback, offerId) {
    const isVideo = file.type.startsWith("video/");
    const containerClient = blobServiceClient.getContainerClient(offerId);

    const containerExists = await containerClient.exists();

    if (!containerExists) {
      const options = { access: "blob" };
      await containerClient.create(options);
    }

    blobServiceClient.setProperties({
      defaultServiceVersion: "2021-04-10"
    })
      .then()
      .catch(err => console.error("Set Properties error", err));

    try {
      const blobName = generateUniqueBlobName(file.name);
      const blobThumbnailName = `${blobName.replace(
        /\.([^.]+)$/,
        "-thumbnail.jpg"
      )}`;

      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      const blockBlobClientThumbnail =
        containerClient.getBlockBlobClient(blobThumbnailName);

      let fileData = await readFileAsBlob(file);

      if (!isVideo) {
        const imageSize = await getImageSize(file);
        fileData = await compressImage(
          file,
          imageSize.width,
          imageSize.height,
          0.7
        );
      }

      const uploadOptions = {
        blobHTTPHeaders: { blobContentType: file.type },
        onProgress: (ev) => {
          const percentComplete = (ev.loadedBytes / fileData.size) * 100;
          progressCallback(percentComplete);
        },
      };

      await blockBlobClient.uploadData(fileData, uploadOptions);

      if (!isVideo) {
        const imageSize = await getImageSize(file);
        const fileDataThumbnail = await compressImage(
          file,
          imageSize.width,
          imageSize.height,
          0.05
        );

        const uploadOptionsThumbnail = {
          blobHTTPHeaders: { blobContentType: "image/jpeg" },
          onProgress: (ev) => {
            const percentComplete = (ev.loadedBytes / fileData.size) * 100;
            progressCallback(percentComplete);
          },
        };

        await blockBlobClientThumbnail.uploadData(
          fileDataThumbnail,
          uploadOptionsThumbnail
        );
      }

      const url = `${process.env.VUE_APP_storage}/${offerId}/${blobName}`;
      return url;
    } catch (error) {
      console.error("Error during concurrent file upload:", error);
    }
  },
};

export default blobStorageHelper ;
