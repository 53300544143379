<template>
  <div class="upload-form border border-gray-100 shadow-xl overflow-hidden sm:rounded-md md:mx-32 h-auto"
    :class="{ 'with-items': files.length > 0 }">
    <div class="upload-form-inner">
      <!--      Tool Bar      -->
      <tool-bar v-if="files.length != 0" :files="files" :temporaryFiles="temporaryFiles" @filesSaved="saveFiles"
        ref="toolbar"></tool-bar>

      <!--      items     -->
      <items v-if="files.length != 0" :files="files" :items="temporaryFiles" @upload-action="handleUploadAction"></items>

      <!--        Upload and drag & drop area            -->

      <div v-show="files.length == 0" style="width: 100%; border-radius: 23px;  pointer-events: auto!important; cursor: pointer" @drop.prevent="drop($event)" @click.self="clickOnInput()" @dragover.prevent="dragover($event)">
        <div 
          class="upload-icon cursor-pointer hover:bg-gray-50 mt-1 flex justify-center rounded-md" >
          <div class="space-y-1 text-center">
            <svg width="65" height="65" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="28" cy="28" r="28" fill="url(#paint0_linear_1052_8014)" />
              <path
                d="M26.4286 29.5714H18.5714C18.1262 29.5714 17.753 29.4208 17.4518 29.1196C17.1506 28.8185 17 28.4452 17 28C17 27.5548 17.1506 27.1815 17.4518 26.8804C17.753 26.5792 18.1262 26.4286 18.5714 26.4286H26.4286V18.5714C26.4286 18.1262 26.5792 17.753 26.8804 17.4518C27.1815 17.1506 27.5548 17 28 17C28.4452 17 28.8185 17.1506 29.1196 17.4518C29.4208 17.753 29.5714 18.1262 29.5714 18.5714V26.4286H37.4286C37.8738 26.4286 38.247 26.5792 38.5482 26.8804C38.8494 27.1815 39 27.5548 39 28C39 28.4452 38.8494 28.8185 38.5482 29.1196C38.247 29.4208 37.8738 29.5714 37.4286 29.5714H29.5714V37.4286C29.5714 37.8738 29.4208 38.247 29.1196 38.5482C28.8185 38.8494 28.4452 39 28 39C27.5548 39 27.1815 38.8494 26.8804 38.5482C26.5792 38.247 26.4286 37.8738 26.4286 37.4286V29.5714Z"
                fill="white" />
              <defs>
                <linearGradient id="paint0_linear_1052_8014" x1="63.875" y1="73.5" x2="-46.1262" y2="66.3577"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#F27F32" />
                  <stop offset="1" stop-color="#921E0C" />
                </linearGradient>
              </defs>
            </svg>

            <div class="flex text-sm text-gray-600">
              <label for="file-upload" class="relative cursor-pointer transition-color duration-300">
                <span class="file-upload-big-text">Incarcă fotografii <br />
                  sau videoclipuri</span>
                <br />
                <input multiple @change="clickedInput($event)" id="file-upload" name="file-upload" type="file"
                  class="sr-only" />
              </label>
            </div>
          </div>
        </div>
      </div>
      <!--       End  Upload drag & drop area            -->
    </div>
  </div>
</template>

<script>
import ToolBar from "./ToolBar";
import Items from "./Items";
import axios from "axios";
import "@fortawesome/fontawesome-free/css/all.css";
import path from "path";

export default {
  props: {
    url: {
      type: String,
      default: "/",
    },
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "PNG, JPG, GIF up to 10MB",
    },
  },
  data: function() {
    return {
      temporaryFiles: [],
      request: new FormData(),
      files: [],
      uploading: false,
    };
  },
  components: {
    "tool-bar": ToolBar,
    items: Items,
  },
  methods: {
    saveFiles() {
      //TODO: Stop button loader here
      this.$parent.saveFiles(this.temporaryFiles);
    },
    async upload() {
      var extensionNotSupported = false;
      var totalSizeKb = 0;

      this.files.forEach((file) => {
        const fileExtension = path.extname(file.name);
        totalSizeKb += file.size;
        if (
          !process.env.VUE_APP_media_extensions.includes(
            fileExtension.toLowerCase() || fileExtension === ''
          )
        ) {
          extensionNotSupported = true;

          this.$parent.$data.extensionForbidden = fileExtension
          const element = document.body.querySelector('.file-size-format-error');
          element.classList.remove("disabled");
        }
        else {
          const element = document.body.querySelector('.file-size-format-error');
          element.classList.add("disabled");
        }
      });

      const totalSizeMb = (totalSizeKb / (1024 * 1024)).toFixed(2);

      if(this.files.length > 10){
        const element = document.body.querySelector(".file-number-maximum-error");
        element.classList.remove("disabled");
      }
      else if (totalSizeMb > 1024) {
        this.$parent.$data.totalFileSize = totalSizeMb

        const element = document.body.querySelector(".file-size-upload-error");
        element.classList.remove("disabled");

      } else {
        const element = document.body.querySelector(".file-size-upload-error");
        element.classList.add("disabled");
        const elementMaximumNumber = document.body.querySelector(".file-number-maximum-error");
        elementMaximumNumber.classList.add("disabled");

        if (!extensionNotSupported) {
          this.$refs.toolbar.uploadAll();
        }
      }
    },

    handleUploadAction() {
      const uploadInput = document.getElementById("file-upload");
      uploadInput.click();
    },
    dragover: function(e) {
      e.stopPropagation();
      e.dataTransfer.dropEffect = "copy";
    },
    drop: function(e) {
      e.stopPropagation();
      this.createFile(e);
    },

    createCancelToken: function() {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      return source;
    },
    clickOnInput: function() {
      const uploadInput = document.getElementById("file-upload");
      uploadInput.click();
    },
    clickedInput: function(e) {
      this.createFile(e);
    },
    createUrl: function(cur) {
      if (cur.type.match(/video.*/)) {
        this.temporaryFiles.push({
          type: "video",
          url: URL.createObjectURL(cur),
          status: "pending",
          percent: 0,
        });
      } else if (cur.type.match(/image.*/)) {
        this.temporaryFiles.push({
          type: "image",
          url: URL.createObjectURL(cur),
          status: "pending",
          percent: 0,
        });
      } else {
        this.temporaryFiles.push({
          type: "file",
          url: URL.createObjectURL(cur),
          status: "pending",
          percent: 0,
        });
      }
    },
    createFile: function(e) {
      var files = [...(e.target.files || e.dataTransfer.files)]; // Array of all files
      this.files.push(...files);

      files.forEach((cur) => {
        this.createUrl(cur);
      });
    },
    isAllUploading() {
      let uploading = false;
      let uploadingList = [];

      this.temporaryFiles.forEach((cur) => uploadingList.push(cur.status));

      if (uploadingList.includes("uploading")) return true;

      return uploading;
    },
  },
  mounted() {
    if (this.id) this.request.append("id", this.id);
  },
};
</script>

<style scoped>
.upload-images {
  width: 100px !important;
  height: 100px !important;
}

.tool-bar-img {
  font-size: 12px;
}

/* Custom Styles */

.border {
  border: 1px solid #e5e7eb;
}

.shadow {
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-md {
  border-radius: 0.375rem;
}

.mx-32 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.h-auto {
  height: auto;
}

.p-8 {
  padding: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-bg-gray-50:hover {
  background-color: #f9fafb;
}

.mt-1 {
  margin-top: 0.25rem;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.border-2 {
  border-width: 2px;
}

.border-dashed {
  border-style: dashed;
}

.bg-white {
  background-color: #ffffff;
}

.font-medium {
  font-weight: 500;
}

.text-blue-500 {
  color: #3b82f6;
}

.hover-text-blue-600:hover {
  color: #2563eb;
}

.transition-colors {
  transition-property: color;
}

.duration-300 {
  transition-duration: 300ms;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.h-12 {
  height: 3rem;
}

.w-12 {
  width: 3rem;
}

.text-gray-400 {
  color: #9ca3af;
}

.text-center {
  text-align: center;
}

.text-sm {
  font-size: 0.875rem;
}

.text-gray-600 {
  color: #4b5563;
}

.relative {
  position: relative;
}

.pl-1 {
  padding-left: 0.25rem;
}

.text-xs {
  font-size: 0.75rem;
}

.text-gray-500 {
  color: #6b7280;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
</style>
