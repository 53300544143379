<template>
  <div
    :id="'form-step-' + stepId"
    class="single-step"
    :data-name="stepName"
    :data-step="stepId"
  >
    <h2>Locația evenimentului</h2>
    <p v-if="knownLocation" class="subttl knownLocation active">
      Daca ai stabilit locatia evenimentului<br />spune-ne locul in care se va
      desfasura
    </p>
    <p v-if="!knownLocation" class="subttl unknownLocation">
      Chiar daca nu ai stabilit locatia evenimentului<br />spune-ne orasul in
      care se va desfasura
    </p>
    <div class="divider" />
    <div class="field">
      <div class="control form-group">
        <input
          v-show="knownLocation"
          id="autoLocation"
          ref="autoComplete"
          type="text"
          name="autoLocation"
          class="input pac-target-input"
        />
        <input
          v-show="!knownLocation"
          id="autoLocation"
          ref="autoCompleteCities"
          type="text"
          name="autoLocation"
          class="input pac-target-input"
        />
        <input
          id="city"
          v-model="placeCity"
          type="text"
          class="input hidden-input"
          required
          data-pristine-required-message="Te rugăm să introduci locația din lista."
          autocomplete="chrome-off"
          readonly
        />
        <label v-if="knownLocation" class="label" for="adress"
          >Introduceți locația evenimentului</label
        >
        <label v-if="!knownLocation" class="label" for="adress"
          >Introduceți orașul evenimentului</label
        >
      </div>
      <div class="extra">
        <img class="illustration" src="@/assets/img/location.svg" />
        <div class="skipContainer" @click="changeLocationType()">
          <p v-if="knownLocation" class="skip">
            Nu am hotarat locatia evenimentului
          </p>
          <p v-if="!knownLocation" class="skip">
            Am hotarat locatia evenimentului
          </p>
          <div class="divider" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormStep3',
  props: {
    stepId: String,
    stepName: String,
    inputLocation: String
  },
  data() {
    return {
      placeName: null,
      placeAddress: 'null',
      placeCity: null,
      hasText: false,
      knownLocation: true
    }
  },
  watch: {
    placeName: function() {
      this.$emit('input', {
        place: this.placeName,
        address: this.placeAddress,
        city: this.placeCity
      })
    }
  },
  mounted() {
    var locationOptions = {
      fields: ['formatted_address', 'name', 'address_components'],
      componentRestrictions: { country: 'RO' }
    }

    var locationOptionsCities = {
      types: ['(cities)'],
      componentRestrictions: { country: 'RO' }
    }

    const google = window.google

    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autoComplete,
      locationOptions
    )
    this.autocompleteCities = new google.maps.places.Autocomplete(
      this.$refs.autoCompleteCities,
      locationOptionsCities
    )

    this.autocomplete.addListener('place_changed', () => {
      var place = this.autocomplete.getPlace()
      if (!place.address_components) {
        alert(
          'Aceasta locatie nu are o adresa. Va rugam sa selectati alta locatie sau sa introduceti adresa locatiei dorite.'
        )
      } else {
        this.placeName = place.name
        this.placeAddress = place.formatted_address
        this.placeCity = place.address_components[3].long_name
      }
    })

    this.autocompleteCities.addListener('place_changed', () => {
      var place = this.autocompleteCities.getPlace()
      this.placeName = place.name
      this.placeAddress = place.formatted_address
      this.placeCity = place.address_components[3].long_name
    })
  },
  methods: {
    changeLocationType() {
      if (this.knownLocation) {
        document.querySelector('.knownLocation').classList.remove('active')
        setTimeout(() => {
          this.knownLocation = false
          setTimeout(() => {
            document.querySelector('.unknownLocation').classList.add('active')
          }, 200)
        }, 300)
      } else {
        document.querySelector('.unknownLocation').classList.remove('active')
        setTimeout(() => {
          this.knownLocation = true
          setTimeout(() => {
            document.querySelector('.unknownLocation').classList.add('active')
          }, 200)
        }, 300)
      }
    }
  }
}
</script>
