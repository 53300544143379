<template>
  <div>
    <div class="offerResume">
      <div class="small-ttl">
        Configuratia pachetului selectat
      </div>
      <div class="section">
        <div
          v-if="artistName !== null"
          class="zrcol_2 t_zrcol_2 m_zrcol_2 mark-area"
        >
          <svg
            version="1.1"
            class="mark"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background:new 0 0 512 512;"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M497.4,70c-7.5-7.5-19.8-7.6-27.3,0L238.6,300.8l-83.5-90.7c-7.2-7.8-19.4-8.3-27.3-1.1c-7.8,7.2-8.3,19.4-1.1,27.3
              l97.1,105.5c3.6,3.9,8.5,6.1,13.8,6.2c0.1,0,0.3,0,0.4,0c5.1,0,10-2,13.6-5.6L497.3,97.3C504.9,89.8,504.9,77.5,497.4,70z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M492.7,236.7c-10.7,0-19.3,8.6-19.3,19.3c0,119.9-97.5,217.4-217.4,217.4c-119.9,0-217.4-97.5-217.4-217.4
              c0-119.9,97.5-217.4,217.4-217.4c10.7,0,19.3-8.6,19.3-19.3C275.3,8.6,266.7,0,256,0C114.8,0,0,114.8,0,256
              c0,141.2,114.8,256,256,256c141.2,0,256-114.8,256-256C512,245.3,503.4,236.7,492.7,236.7z"
                />
              </g>
            </g>
          </svg>
        </div>
        <div
          v-if="artistName !== null"
          class="zrcol_10 t_zrcol_10 m_zrcol_10"
        >
          <div class="ttl-title">
            DJ-ul selectat
          </div>
          <div class="ttl-content">
            {{ artistName }}
          </div>
        </div>
      </div>
      <div
        v-if="selectedSoundPackage"
        class="section"
      >
        <div class="zrcol_2 t_zrcol_2 m_zrcol_2">
          <svg
            version="1.1"
            class="mark"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background:new 0 0 512 512;"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M497.4,70c-7.5-7.5-19.8-7.6-27.3,0L238.6,300.8l-83.5-90.7c-7.2-7.8-19.4-8.3-27.3-1.1c-7.8,7.2-8.3,19.4-1.1,27.3
              l97.1,105.5c3.6,3.9,8.5,6.1,13.8,6.2c0.1,0,0.3,0,0.4,0c5.1,0,10-2,13.6-5.6L497.3,97.3C504.9,89.8,504.9,77.5,497.4,70z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M492.7,236.7c-10.7,0-19.3,8.6-19.3,19.3c0,119.9-97.5,217.4-217.4,217.4c-119.9,0-217.4-97.5-217.4-217.4
              c0-119.9,97.5-217.4,217.4-217.4c10.7,0,19.3-8.6,19.3-19.3C275.3,8.6,266.7,0,256,0C114.8,0,0,114.8,0,256
              c0,141.2,114.8,256,256,256c141.2,0,256-114.8,256-256C512,245.3,503.4,236.7,492.7,236.7z"
                />
              </g>
            </g>
          </svg>
        </div>
        <div class="zrcol_10 t_zrcol_10 m_zrcol_10">
          <div class="ttl-title">
            Pachetul de sunet selectat
          </div>
          <div class="ttl-content">
            {{ selectedSoundPackage.name }}
          </div>
        </div>
      </div>
      <div
        v-if="selectedLightPackage"
        class="section"
      >
        <div class="zrcol_2 t_zrcol_2 m_zrcol_2">
          <svg
            version="1.1"
            class="mark"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background:new 0 0 512 512;"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M497.4,70c-7.5-7.5-19.8-7.6-27.3,0L238.6,300.8l-83.5-90.7c-7.2-7.8-19.4-8.3-27.3-1.1c-7.8,7.2-8.3,19.4-1.1,27.3
              l97.1,105.5c3.6,3.9,8.5,6.1,13.8,6.2c0.1,0,0.3,0,0.4,0c5.1,0,10-2,13.6-5.6L497.3,97.3C504.9,89.8,504.9,77.5,497.4,70z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M492.7,236.7c-10.7,0-19.3,8.6-19.3,19.3c0,119.9-97.5,217.4-217.4,217.4c-119.9,0-217.4-97.5-217.4-217.4
              c0-119.9,97.5-217.4,217.4-217.4c10.7,0,19.3-8.6,19.3-19.3C275.3,8.6,266.7,0,256,0C114.8,0,0,114.8,0,256
              c0,141.2,114.8,256,256,256c141.2,0,256-114.8,256-256C512,245.3,503.4,236.7,492.7,236.7z"
                />
              </g>
            </g>
          </svg>
        </div>
        <div class="zrcol_10 t_zrcol_10 m_zrcol_10">
          <div class="ttl-title">
            Pachetul de lumini
          </div>
          <div class="ttl-content">
            {{ selectedLightPackage.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OfferResume',
  props: {
    selectedLightPackage: Object,
    selectedSoundPackage: Object,
    artistName: String
  }
}
</script>
