<template>
  <div class="page-wrapper upload-wrapper">
    <div id="loader-container" class="loader-container">
      <lottie-animation
        ref="lottieVue"
        class="lottie"
        :auto-play="true"
        :click-play="false"
        :loop="true"
        path="lottie/memoriesCollectionLightAnimation.json"
      />
    </div>

    <div class="header">
      <div class="headerGradient" />
      <div class="wrapperHeader">
        <div
          v-if="!isGalleryDisplayed"
          class="backButton"
          @click="goBackToGallery()"
        >
          <img src="@/assets/img/back-arrow.svg" alt="" width="22px" />
          <h4>Înapoi către galerie</h4>
        </div>

        <h3 v-else>
          {{ organizerName }}
        </h3>
        <div class="otherDetails">
          <h5>Nunta</h5>
        </div>
      </div>
    </div>

    <div v-if="!isGalleryDisplayed" id="upload-form" class="wrapper">
      <file-upload :id="id" ref="fileUpload" :url="url" :label="label" />

      <div class="error-container">
        <span class="file-upload-error disabled">
          Va rugam incarcati cel putin un fisier media.
        </span>

        <span class="file-size-upload-error disabled">
          Marimea fisierelor este {{ totalFileSize }}MB. Nu puteti incarca mai
          mult de 1024MB
        </span>

        <span class="file-number-maximum-error disabled">
          Nu puteti incarca mai mult de 10 fisiere.
        </span>

        <span class="file-size-format-error disabled">
          Extensia {{ extensionForbidden }} nu este acceptata.
        </span>
      </div>

      <div id="form" class="form">
        <div class="userData">
          <div class="inputWrapper first">
            <input
              v-model="name"
              class="input"
              type="text"
              name="clientName"
              placeholder=" "
              autocomplete="off"
              required
              minlength="3"
              data-pristine-minlength-message="Introduceti minim 3 caractere"
              data-pristine-required-message="Câmp obligatoriu"
            />
            <label class="label" for="name">Numele si prenumele tau*</label>
            <img class="checked" src="@/assets/img/check.svg" alt="" />
            <img class="error" src="@/assets/img/error.svg" alt="" />
          </div>
          <div class="special-message" @click="togglMessage">
            <h2>Vrei sa lasi un mesaj<br />pentru sarbatoriti?</h2>
            <img class="vector" src="@/assets/img/specialMessage.svg" alt="" />
          </div>
          <div class="textAreaWrapper">
            <textarea
              v-model="message"
              class="input"
              type="text"
              name="street_address"
              placeholder=" "
              autocomplete="off"
              rows="5"
              minlength="4"
              data-pristine-minlength-message="Introduceti minim 4 caractere"
              data-pristine-required-message="Câmp obligatoriu"
            />
            <label class="label" for="adress"
              >Mesajul tau pentru sarbatoriti</label
            >
            <img class="checked" src="@/assets/img/check.svg" alt="" />
            <img class="error" src="@/assets/img/error.svg" alt="" />
          </div>
        </div>

        <div class="termsWrapper">
          <div class="terms">
            <div class="inputWrapper">
              <input
                id="checkbox"
                v-model="termsAndConditions1"
                type="checkbox"
                name="checkbox"
                required
                data-pristine-required-message="Trebuie să acceptați aceasta conditie"
              />
              <label for="checkbox">
                <p>
                  Sunt de acord cu incarcarea fotografiilor si cu faptul ca doar
                  sarbatoritii le vor putea sterge
                </p>
              </label>
            </div>
            <div class="inputWrapper last">
              <input
                id="checkbox1"
                v-model="termsAndConditions"
                type="checkbox"
                name="checkbox1"
                required
                data-pristine-required-message="Trebuie să acceptați termenii și condițiile pentru a incarca fotografiile"
              />
              <label for="checkbox1">
                <p>
                  Sunt de acord cu
                  <a
                    href="https://storagedjbooking.blob.core.windows.net/terms-and-conditions/GDPR%20CONTRACT.pdf"
                    target="_blank"
                    >termenii si conditiile</a
                  >
                </p>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="uploaders.length > 0" class="wrapper-uploaded">
      <div class="uploaded-card">
        <div class="stackone-container-add" @click="handleUploadFormAction">
          <div class="stackone">
            <svg
              width="65"
              height="65"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="28"
                cy="28"
                r="28"
                fill="url(#paint0_linear_1052_8014)"
              />
              <path
                d="M26.4286 29.5714H18.5714C18.1262 29.5714 17.753 29.4208 17.4518 29.1196C17.1506 28.8185 17 28.4452 17 28C17 27.5548 17.1506 27.1815 17.4518 26.8804C17.753 26.5792 18.1262 26.4286 18.5714 26.4286H26.4286V18.5714C26.4286 18.1262 26.5792 17.753 26.8804 17.4518C27.1815 17.1506 27.5548 17 28 17C28.4452 17 28.8185 17.1506 29.1196 17.4518C29.4208 17.753 29.5714 18.1262 29.5714 18.5714V26.4286H37.4286C37.8738 26.4286 38.247 26.5792 38.5482 26.8804C38.8494 27.1815 39 27.5548 39 28C39 28.4452 38.8494 28.8185 38.5482 29.1196C38.247 29.4208 37.8738 29.5714 37.4286 29.5714H29.5714V37.4286C29.5714 37.8738 29.4208 38.247 29.1196 38.5482C28.8185 38.8494 28.4452 39 28 39C27.5548 39 27.1815 38.8494 26.8804 38.5482C26.5792 38.247 26.4286 37.8738 26.4286 37.4286V29.5714Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1052_8014"
                  x1="63.875"
                  y1="73.5"
                  x2="-46.1262"
                  y2="66.3577"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F27F32" />
                  <stop offset="1" stop-color="#921E0C" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <h2 class="person-title">Adauga media</h2>
        <h3 class="person-image-count">Imagini sau videoclipuri</h3>
      </div>
      <div
        v-for="(uploader, index) in uploaders"
        :key="index"
        class="uploaded-card"
      >
        <v-gallery
          :images="uploader.mediaItems"
          :index="showIndex"
          @close="showIndex = null"
        />

        <div class="stackone-container">
          <div class="stackone">
            <img
              v-if="uploader.mediaItems.length > 0"
              :src="uploader.mediaItems[0].urlThumbnail"
              :alt="'First Image'"
              @click="showImage(0, uploader)"
            />
          </div>
        </div>

        <h2 class="person-title">{{ uploader.name }}.</h2>
        <h3 v-if="uploader.mediaItems.length > 1" class="person-image-count">
          {{ uploader.mediaItems.length }} fisiere
        </h3>
        <h3 v-else class="person-image-count">
          {{ uploader.mediaItems.length }} fisier
        </h3>
      </div>
    </div>
    <div v-else />
    <LightBox
      ref="lightbox"
      :media="media"
      :show-thumbs="false"
      :show-caption="true"
      :interface-hide-time="100000"
      :autoplay="false"
    >
      <template v-slot:customCaption="slotProps">
        <div class="caption">
          <div class="captionUser">
            {{ slotProps.currentMedia.name }}
          </div>
          <div class="captionContent">
            {{ slotProps.currentMedia.caption }}
          </div>
        </div>
      </template>
    </LightBox>

    <button class="button" @click="handleUploadFormAction">
      <img
        v-if="isGalleryDisplayed"
        src="@/assets/img/uploadImageIcon.svg"
        alt=""
        width="20px"
      />
      <span v-if="isGalleryDisplayed">Încarcă fotografiile tale</span>
      <span v-else>Trimite către încarcare</span>
    </button>
  </div>
</template>

<script>
import FileUpload from "@/components/Uploader/FileUpload.vue";
import VGallery from "vue-gallery";
import axios from "axios";
import * as Pristine from "pristinejs";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import LightBox from "@/components/Lightbox/Lightbox.vue";
import { modal } from "@/store/loaders.js";
import videoThumb1 from "@/assets/img/videoThumbnails/videoPlaceholder-1.jpg";
import videoThumb2 from "@/assets/img/videoThumbnails/videoPlaceholder-2.jpg";
import videoThumb3 from "@/assets/img/videoThumbnails/videoPlaceholder-3.jpg";
import videoThumb4 from "@/assets/img/videoThumbnails/videoPlaceholder-4.jpg";
import videoThumb5 from "@/assets/img/videoThumbnails/videoPlaceholder-5.jpg";
require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

const defaultConfig = {
  classTo: "inputWrapper",
  errorClass: "has-danger",
  successClass: "has-succes",
  // class of the parent element where error text element is appended
  errorTextParent: "inputWrapper",
  // type of element to create for the error text
  errorTextTag: "p",
  // class of the error text element
  errorTextClass: "text-help",
};

export default {
  name: "PhotosCollection",
  components: { FileUpload, VGallery, LottieAnimation, LightBox },

  data() {
    return {
      media: [],
      videoThumbImageList: [],
      videoThumbSrcList: [],
      id: "",
      label: "test",
      url: "",
      isGalleryDisplayed: false,
      showIndex: null,
      name: "",
      organizerName: "",
      message: "",
      termsAndConditions: false,
      termsAndConditions1: false,
      validation: false,
      uploaders: [],
      totalFileSize: 0,
      pageVisited: false,
      extensionForbidden: "",
      extensionsVideo: [".mp4", ".avi", ".mov", ".wmv", ".flv", ".mkv"],
      items: [
        {
          name: "Alexandru Goncearu",
          note: "Împărtășesc cu voi aceste momente speciale de la nunta noastră cu inima plină de bucurie și fericire. Fie ca aceste amintiri să rămână mereu vii și să ne reamintească de ziua noastră deosebită. Mulțumesc tuturor celor care au făcut parte din această zi minunată!v",
          images: [
            "https://picsum.photos/250/300",
            "https://picsum.photos/200/300",
          ],
        },
        {
          name: "Adelin Preda",
          note: "N-am ce sa mai zic, nu mai e nimic de zis, nu prea-mi place cum arata",
          images: [
            "https://picsum.photos/200/250",
            "https://picsum.photos/200/280",
          ],
        },
      ],
    };
  },
  watch: {
    temporaryFiles: "buttonEnabler",
    name: "buttonEnabler",
    termsAndConditions: "buttonEnabler",
    termsAndConditions1: "buttonEnabler",
  },
  mounted() {
    this.id = this.$route.params.offerId;
    if(this.id === "50189028-0286-4d61-b617-7ec76cef80c4"){
      this.id = "340c07a5-8fa2-48ef-883f-5ef7bb7e54e0"
    }

    const visitedPage = localStorage.getItem("visitedMemoryCollectionPage");
    if (visitedPage) {
      this.pageVisited = true;
    }

    // Add thumbnails to the list
    this.videoThumbImageList.push(videoThumb1);
    this.videoThumbImageList.push(videoThumb2);
    this.videoThumbImageList.push(videoThumb3);
    this.videoThumbImageList.push(videoThumb4);
    this.videoThumbImageList.push(videoThumb5);

    // Create blob for each image
    const fetchPromises = this.videoThumbImageList.map(thumb =>
      fetch(thumb)
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
    );

    Promise.all(fetchPromises)
      .then(videoThumbSrcList => {
        this.videoThumbSrcList = videoThumbSrcList;

        // Perform actions after all images are fetched
        this.checkImages();
      })
      .catch(error => {
        console.error('Error fetching images:', error);
      });

    // Making a GET request
    axios
      .get(
        `${process.env.VUE_APP_baseUrl}MediaHub/GetGallery/${this.id}`
      )
      .then((response) => {
        this.organizerName = response.data.name;
      })
      .catch((error) => {
        // Handle any errors that occur
        console.error("Error:", error);
      });

    
    localStorage.setItem("visitedMemoryCollectionPage", "true");
  },
  methods: {
    getRandomNumber() {
      // Generate a random number between 0 and 1
      const random = Math.random();

      // Scale the random number to be between 1 and 4
      const randomNumber = Math.floor(random * 4);

      return randomNumber;
    },
    isVideo(item) {
      return this.extensionsVideo.includes(
        item.url.substring(item.url.lastIndexOf(".")).toLowerCase()
      );
    },
    showImage(index, uploader) {
      this.media = [];

      uploader.mediaItems.forEach((item) => {
        if (this.isVideo(item)) {
          var videoType = item.url.substring(item.url.lastIndexOf(".") + 1);
          if (videoType.toLowerCase() === "mov") {
            videoType = "mp4";
          }
          this.media.push({
            sources: [
              {
                src: item.url,
                type: `video/${videoType}`,
                caption: uploader.message,
              },
            ],
            thumb: this.videoThumbSrcList[this.getRandomNumber()],
            width: 400,
            height: 600,
            type: "video",
            caption: uploader.message,
            name: uploader.name,
          });
        } else {
          this.media.push({
            src: item.url,
            thumb: item.urlThumbnail,
            type: "image",
            name: uploader.name,
          });
        }
      });

      this.$refs.lightbox.showImage(index);
    },
    formifyClient() {
      var pristine = new Pristine(
        document.getElementById("form"),
        defaultConfig
      );
      var valid = pristine.validate();
      if (valid) {
        pristine.reset();
        pristine.destroy();
        this.validation = true;
      }
    },

    checkImages() {
      axios
        .get(
          `${process.env.VUE_APP_baseUrl}MediaHub/MediaUploaders/${this.id}`
        )
        .then((response) => {
          this.uploaders = response.data;
          
          this.uploaders.forEach((uploader) => {
            uploader.mediaItems.forEach((item) => {
              if (this.isVideo(item)) {
                item.urlThumbnail =
                  this.videoThumbSrcList[this.getRandomNumber()];
              }
            });
          });

          if (this.uploaders.length > 0) {
            this.isGalleryDisplayed = true;
          } else {
            this.isGalleryDisplayed = false;
          }

          document
            .querySelector(".loader-container")
            .classList.add("anim-exit");
          setTimeout(() => {
            document
              .querySelector(".loader-container")
              .classList.add("disabled");
          }, 300);

          if (!this.pageVisited) {
            modal.displayModal(true, true, "uploadGallery");
          }
        })
        .catch((error) => {
          // Handle any errors that occur
          console.error("Error:", error);
        });
    },

    togglMessage() {
      document.querySelector(".special-message").classList.add("disabled");
      setTimeout(() => {
        document.querySelector(".special-message").classList.add("hidden");
        document.querySelector(".textAreaWrapper").classList.add("active");
      }, 300);
    },

    goBackToGallery() {
      this.isGalleryDisplayed = true;

      document.querySelector(".file-upload-error").classList.remove("add");
      document.querySelector(".button").classList.remove("disabled");

      document.querySelector(".upload-form-inner").classList.remove("pulse");
    },

    buttonEnabler() {
      this.areAllConditionsMet =
        this.$refs.fileUpload.temporaryFiles.length !== 0 &&
        this.name !== "" &&
        this.termsAndConditions &&
        this.termsAndConditions1;

      if (this.areAllConditionsMet) {
        document.querySelector(".button").classList.remove("disabled");

        // Do something when all conditions are met
      } else {
        document.querySelector(".button").classList.add("disabled");

        // Do something when conditions are not met
      }
    },

    handleUploadFormAction() {
      if (this.isGalleryDisplayed) {
        this.isGalleryDisplayed = false;
        document.querySelector(".button").classList.add("disabled");
      } else {
        this.formifyClient();
        if (this.$refs.fileUpload.temporaryFiles.length === 0) {
          document
            .querySelector(".file-upload-error")
            .classList.remove("disabled");
          // Check the user's response
          document.querySelector(".upload-form-inner").classList.add("pulse");

          setTimeout(() => {
            document
              .querySelector(".upload-form-inner")
              .classList.remove("pulse");
          }, 1250);
        } else {
          document.querySelector(".file-upload-error").classList.remove("add");
          document.querySelector(".button").classList.remove("disabled");

          document
            .querySelector(".upload-form-inner")
            .classList.remove("pulse");

          if (
            this.validation &&
            this.$refs.fileUpload.temporaryFiles.length > 0
          ) {
            this.$refs.fileUpload.upload();
            this.isGalleryDisplayed = false;
          }
        }
      }
    },
    saveFiles(files) {
      const postData = {
        MediaItems: files,
        Message: this.message,
        Name: this.name,
        TermsAndConditions: this.termsAndConditions,
      };

      // Make a POST request
      axios
        .post(
          `${process.env.VUE_APP_baseUrl}MediaHub/Upload/${this.id}`,
          postData
        )
        .then(() => {
          // Handle successful response here
          this.isGalleryDisplayed = true;

          //TODO: start loader here

          // Making a GET request

          axios
            .get(
              `${process.env.VUE_APP_baseUrl}MediaHub/MediaUploaders/${this.id}`
            )
            .then((response) => {
              this.uploaders = response.data;
              this.uploaders.forEach((uploader) => {
                uploader.mediaItems.forEach((item) => {
                  if (this.isVideo(item)) {
                    item.urlThumbnail =
                      this.videoThumbSrcList[this.getRandomNumber()];
                  }
                });
              });

              document.querySelector(".button").classList.add("success");

              setTimeout(() => {
                document.querySelector(".button").classList.remove("success");
                document.querySelector(".button").classList.remove("loading");
              }, 3000);
            })
            .catch((error) => {
              // Handle any errors that occur
              console.error("Error:", error);
            });
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle error here
        });
    },
  },
};
</script>
<style scoped>
body {
  background-color: #ffffff !important;
  /* White 
  background color */
}

.masonry-item {
  margin-bottom: 10px;
}

.item-image {
  width: 100%;
  display: block;
}

.items {
  border: solid 1px;
}

.item {
  border: solid 1px aqua;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);

  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-flow: dense;
}

.grid-item {
  border: solid 1px aqua;
  display: grid-item;
}

.grid-item[data-aspect="sq"] {
  grid-column-end: span 1;
  grid-row-end: span 1;
}

.grid-item[data-aspect="h"] {
  grid-column-end: span 1;
  grid-row-end: span 2;
}

.grid-item[data-aspect="v"] {
  grid-column-end: span 2;
  grid-row-end: span 1;
}

.grid-item img {
  vertical-align: bottom;
}
</style>
