<template>
  <div
    :id="'form-step-' + stepId"
    class="single-step"
    :data-name="stepName"
    :data-step="stepId"
  >
    <h2>Tipul evenimentului</h2>
    <p class="subttl">Ce moment memorabil vei sărbatori?</p>
    <div class="divider" />
    <div class="field">
      <div class="control form-group">
        <div class="radio">
          <input
            id="nunta"
            v-model="type"
            type="radio"
            name="type"
            value="Nunta"
            required
            data-pristine-required-message="Te rugăm să alegi tipul evenimentului."
          />
          <label for="nunta">
            <img alt="Nunta - DJ Booking" src="@/assets/img/icons/nunta.svg" />
            <span>Nuntă</span>
          </label>
        </div>
        <div class="radio">
          <input
            id="botez"
            v-model="type"
            type="radio"
            name="type"
            value="Botez"
            required
            data-pristine-required-message="Te rugăm să alegi tipul evenimentului."
          />
          <label for="botez">
            <img alt="Botez - DJ Booking" src="@/assets/img/icons/botez.svg" />
            <span>Botez</span>
          </label>
        </div>
        <div class="radio">
          <input
            id="petrecere"
            v-model="type"
            type="radio"
            name="type"
            value="Petrecere Aniversara"
            required
            data-pristine-required-message="Te rugăm să alegi tipul evenimentului."
          />
          <label for="petrecere">
            <img
              alt="Petrecere aniversară - DJ Booking"
              src="@/assets/img/icons/petrecere.svg"
            />
            <span>Petrecere aniversară</span>
          </label>
        </div>
        <div class="radio">
          <input
            id="cununie"
            v-model="type"
            type="radio"
            name="type"
            value="Cununie Civila"
            required
            data-pristine-required-message="Te rugăm să alegi tipul evenimentului."
          />
          <label for="cununie">
            <img
              alt="Cununie civilă - DJ Booking"
              src="@/assets/img/icons/cununieCivila.svg"
            />
            <span>Cununie civilă</span>
          </label>
        </div>
        <div class="radio">
          <input
            id="majorat"
            v-model="type"
            type="radio"
            name="type"
            value="Majorat"
            required
            data-pristine-required-message="Te rugăm să alegi tipul evenimentului."
          />
          <label for="majorat">
            <img
              alt="Majorat - DJ Booking"
              src="@/assets/img/icons/majorat.svg"
            />
            <span>Majorat</span>
          </label>
        </div>
        <div class="radio">
          <input
            id="corporate"
            v-model="type"
            type="radio"
            name="type"
            value="Corporate"
            required
            data-pristine-required-message="Te rugăm să alegi tipul evenimentului."
          />
          <label for="corporate">
            <img
              alt="Corporate - DJ Booking"
              src="@/assets/img/icons/majorat.svg"
            />
            <span>Corporate</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormStep1',
  props: {
    stepId: String,
    stepName: String,
    eventType: String
  },
  data(){
    return {
      type: this.eventType
    }
  },
  watch: {
    type: function() {
      this.$emit('input', this.type)
    }
  }
}
</script>
