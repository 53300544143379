import axios from 'axios'

const apiPrivent = axios.create({
  baseURL: process.env.VUE_APP_baseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  eventAvailability(obj) {
    return apiPrivent.post('/Event/Availability', obj)
  },
  addOffer(obj) {
    return apiPrivent.post('/ClientOffer/Add', obj)
  },
  loadProfile(id) {
    const artistId = id
    return apiPrivent.get(`/ArtistProfile/${artistId}`)
  },
  loadOffer(id) {
    const offerId = id
    return apiPrivent.get(`/ClientOffer/${offerId}`)
  },
  requestOffer(obj) {
    return apiPrivent.post('/ClientOffer/Request', obj)
  },
  getOffer(offerId) {
    return apiPrivent.get(`/ClientOffer/${offerId}`)
  },
  getOfferForSearch(offerId) {
    return apiPrivent.get(`/ClientOffer/SearchData/${offerId}`)
  },
  getOfferForContract(offerId) {
    return apiPrivent.get(`/ClientOfferContract/${offerId}`)
  },
  getContractInfo(offerId) {
    return apiPrivent.get(`ClientOffer/Contract/${offerId}`)
  },
  saveContractInfo(data) {
    return apiPrivent.post(`ClientOffer/Contract/Save`, data)
  },
  addRating(data, offerId) {
    return apiPrivent.post(`Rating/Add/${offerId}`, data)
  },
  addArtistRating(data, artistId) {
    return apiPrivent.post(`Rating/Artist/Add/${artistId}`, data)
  },
  getOfferFeedback(offerId) {
    return apiPrivent.get(`Rating/Offer/${offerId}`)
  },
  addPlaylist(offerId, data) {
    return apiPrivent.post(`ClientOffer/Playlist/Add/${offerId}`, data)
  },
  generateOTPCode(offerId) {
    return apiPrivent.post(`Contract/OTP/Generate/${offerId}`, null)
  },
  verifyOTPCode(offerId, pin) {
    return apiPrivent.post(`Contract/OTP/Verify/${offerId}/${pin}`, null)
  },
  getInvoiceInfo(id) {
    return apiPrivent.get(`ClientOffer/Invoice/${id}`)
  }
}
