<template>
  <div class="modal-wrapper" @click="turnModalOff()">
    <div class="modal">
      <lottie-animation
        ref="lottieVue"
        class="lottieLoader"
        :autoplay="true"
        :click-play="false"
        :loop="true"
        :path="modalState.lottie"
      />
      <h3>{{ modalState.title }}</h3>
      <h4>{{ modalState.subtitle }}</h4>
      <a
        v-if="modalState.buttonLink != null"
        class="btn-cls"
        :href="modalState.buttonLink"
        >{{ modalState.buttonText }}</a
      >
      <p v-if="modalState.isDismissable">
        Apasă oriunde pentru a închide acest mesaj.
      </p>
    </div>
  </div>
</template>

<script>
import { modal } from '@/store/loaders.js'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'

export default {
  name: 'ModalMessage',
  components: {
    LottieAnimation
  },
  data() {
    return {
      modalState: modal.state
    }
  },
  methods: {
    turnModalOff() {
      if (this.modalState.isDismissable) {
        modal.displayModal(false)
      }
    }
  }
}
</script>
