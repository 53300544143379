<template>
  <splide :options="options" :arrows="false" class="cards">
    <splide-slide
      v-for="p in offerRequested
        ? packagesSound.filter((x) => x.id === selectedPackage.id)
        : packagesSound"
      :key="p.id"
    >
      <div class="general-card">
        <div :key="p.id" class="slider">
          <tiny-slider ref="tinySlider" v-bind="tinySliderOptions">
            <div v-for="photo in p.photos" :key="photo.url">
              <div
                v-if="!photo.url.includes('youtube')"
                class="image-square"
                :style="{
                  backgroundImage: 'url(' + photo.url + ')'
                }"
              />
              <div v-else>
                <iframe class="image-square" :src="photo.url" />
              </div>
            </div>
          </tiny-slider>
          <ul
            id="customize-controls"
            class="controls"
            tabindex="0"
            aria-controls="customize"
          >
            <div @click="goToPrev(p.index)">
              <img src="@/assets/img/left-arrow.svg" class="prev" />
            </div>
            <div @click="goToNext(p.index)">
              <img src="@/assets/img/right-arrow.svg" class="next" />
            </div>
          </ul>
        </div>
        <div id="parent-sound" class="wrapper-general-card">
          <div>
          <div id="div-controller" class="mid-ttl page-txt">
            {{ p.name }}
          </div>
         
            <div v-for="item in p.products" :key="item.id" class="small-ttl">
            {{ item.quantity }} x {{ item.name }}
          </div>
          
          </div>
          <div v-if="!offerRequested">
            <div v-if="selectedPackage !== p">
              <button
                id="profile-button bottomAlign"
                class="btn-cls negative"
                @click="selectPackage(p)"
              >
                Selectează pachetul
              </button>
            </div>
            <div v-else>
              <button
                v-if="p.type === 0"
                id="profile-button bottomAlign"
                class="btn-cls selected"
              >
                Pachetul selectat
              </button>
              <button
                v-else
                id="profile-button bottomAlign"
                class="btn-cls selected"
                @click="deselectPackage()"
              >
                Deselecteaza pachetul
              </button>
            </div>
          </div>
        </div>
      </div>
    </splide-slide>
  </splide>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import VueTinySlider from 'vue-tiny-slider'

export default {
  name: 'CardsPackage',
  components: {
    Splide,
    SplideSlide,
    'tiny-slider': VueTinySlider
  },
  props: {
    offerRequested: Boolean,
    offerConfirmed: Boolean,
    packagesSound: Array,
    options: Object,
    tinySliderOptions: Object,
    isSoundPackage: Boolean,
    selectedPackage: Object
  },
  mounted() {
    if(this.packagesSound.length === 1) {
      if(this.packagesSound[0].type === 0)
      {
        this.selectPackage(this.packagesSound[0]);
      }
    }
  },
  methods: {
    goToNext(index) {
      this.$refs.tinySlider[index].slider.goTo('next')
    },
    goToPrev(index) {
      this.$refs.tinySlider[index].slider.goTo('prev')
    },
    updatePrice() {
      this.$emit('updatePrice')
    },
    selectPackage(p) {
      if (this.isSoundPackage) {
        this.$emit('selectSound', p)
      } else {
        this.$emit('selectLight', p)
      }
      this.updatePrice()
    },
    deselectPackage() {
      this.$emit('selectLight', null)
      this.updatePrice()
    }
  }
}
</script>
