<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <button
          type="button"
          class="btn-close"
          @click="close"
        >
        <img src="@/assets/img/arrow.svg" class="arrow"/>
          Inchide
        </button>
      </header>

      <section class="modal-body">
        <slot>
        </slot>
       </section>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Modal',
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style>

</style>