<template>
  <div>
    <!-- Loader -->
    <div class="loader show" id="loaderBackground">
      <div class="pulsar">
        <lottie-animation
          ref="lottieVue"
          class="lottie"
          :autoplay="true"
          :click-play="false"
          :loop="true"
          path="lottie/logo.json"
        />
      </div>
    </div>
    <!-- end Loader -->
    <div class="generateContract-wrapper">
      <div class="wrapper">
        <div class="header">
          <div class="wrapperHeader">
            <img src="@/assets/img/logoHeadline.svg" />
            <div class="actions" />
          </div>
        </div>
        <div class="wrapperTitle">
          <div class="title">
            <p v-if="artistFeedback">
              Salut!<br />
              Ne bucuram ca am fost alaturi de tine<br />
              la un moment important din viata ta
            </p>
            <p v-else>
              Salut, {{ offer.clientName }}!<br />
              Ne bucuram ca am fost alaturi de tine<br />
              la un moment important din viata ta
            </p>
          </div>
        </div>
        <div v-if="!artistFeedback" class="wrapperCards">
          <Splide :options="splideOptions">
            <SplideSlide>
              <div class="card feedback">
                <p class="content">
                  La <span class="highlight">majoratul</span> tau<br />
                  au participat
                  <span class="highlight">{{ offer.nrAttendees }}</span
                  ><br />
                  de <span class="highlight">zambete</span>
                </p>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div class="card feedback">
                <p class="content">
                  Petrecerea a <br />inceput pe
                  <span class="highlight">{{ offer.dateString }}</span
                  ><br />
                  de la ora <span class="highlight">{{ offer.hour }}</span>
                </p>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div class="card feedback">
                <p class="content">
                  Momente <span class="highlight">memorabile</span><br />
                  au fost traite la<br /><span class="highlight">{{
                    offer.location
                  }}</span>
                </p>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div class="card feedback">
                <p class="content">
                  Show-ul <span class="highlight">muzical</span><br />
                  a fost intretinut de<br />
                  <span class="highlight">{{ offer.artistName }}</span>
                </p>
              </div>
            </SplideSlide>
          </Splide>
        </div>

        <div class="wrapperForm">
          <div class="form-feedback">
            <div class="progressBar">
              <div class="fillArea">
                <div class="fill" :style="width" />
              </div>
            </div>
            <step-0
              :emoji-width="emojiWidth"
              :emoji-height="emojiHeight"
              :class="{
                'active-step': currentStep == Object.keys(stepSegments)[0]
              }"
              @value="feedbackQuestion0getter"
            />
            <step-1
              :emoji-width="emojiWidth"
              :emoji-height="emojiHeight"
              :class="{
                'active-step': currentStep == Object.keys(stepSegments)[1]
              }"
              @value="feedbackQuestion1getter"
            />
            <step-2
              :class="{
                'active-step': currentStep == Object.keys(stepSegments)[2]
              }"
              @value="feedbackQuestion2getter"
            />
            <step-3
              :emoji-width="emojiWidth"
              :emoji-height="emojiHeight"
              :class="{
                'active-step': currentStep == Object.keys(stepSegments)[3]
              }"
              @value="feedbackQuestion3getter"
            />
            <step-4
              :class="{
                'active-step': currentStep == Object.keys(stepSegments)[4]
              }"
              @value="feedbackQuestion4getter"
            />
            <step-5
              :class="{
                'active-step': currentStep == Object.keys(stepSegments)[5]
              }"
              @value="feedbackQuestion5getter"
            />
            <step-6
              :emoji-width="emojiWidth"
              :emoji-height="emojiHeight"
              :class="{
                'active-step': currentStep == Object.keys(stepSegments)[6]
              }"
              @value="feedbackQuestion6getter"
              @submit="submit"
            />
            <step-7
              :class="{
                'active-step': currentStep == Object.keys(stepSegments)[7]
              }"
            />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Step1 from '../components/feedback/step1.vue'
import Step2 from '../components/feedback/step2.vue'
import Step3 from '../components/feedback/step3.vue'
import Step4 from '../components/feedback/step4.vue'
import Step5 from '../components/feedback/step5.vue'
import Step6 from '../components/feedback/step6.vue'
import Step7 from '../components/feedback/step7.vue'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import PriventApi from '@/api/calls.js'
import { modal } from '@/store/loaders.js'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import Footer from '@/components/Footer.vue'
import Step0 from '../components/feedback/step0.vue'

export default {
  name: 'Feedback',
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    Splide,
    SplideSlide,
    LottieAnimation,
    Footer,
    Step0
  },
  data() {
    return {
      loading: true,
      artistFeedback: false,
      currentStep: 1,
      emojiWidth: '',
      emojiHeight: '',
      stepSegments: {
        0: '',
        1: 'Evaluand organizarea generala a evenimentului, cum apreciezi serviciile echipei noastre?',
        2: 'Ce recomandari ai pentru imbunatatirea serviciilor echipei noastre?',
        3: 'Cum evaluezi prestatia profesionala a DJ-ului in cadrul evenimentuluis',
        4: 'Care sunt motivele pentru care l-ai recomanda',
        5: 'Ce recomandari ai pentru imbunatatirea prestatiei?',
        6: 'Cum evaluezi modul de comunicare al platformei noastre?',
        7: 'Final screen'
      },
      feedbackQuestion0: null,
      feedbackQuestion1: null,
      feedbackQuestion2: null,
      feedbackQuestion3: null,
      feedbackQuestion4: null,
      feedbackQuestion5: null,
      feedbackQuestion6: null,
      offer: {},
      progress: 15,
      splideOptions: {
        type: 'loop',
        perPage: 3,
        autoplay: true,
        interval: 2500,
        perMove: 1,
        arrows: false,
        pagination: false,
        breakpoints: {
          600: {
            fixedWidth: 190,
            perPage: 2,
            drag: true,
            padding: '0'
          },
          1000: {
            perPage: 3,
            drag: true
          }
        }
      }
    }
  },
  computed: {
    width() {
      return {
        width: this.progress + '%'
      }
    }
  },
  watch: {
    feedbackQuestion0() {
      this.currentStep = 1
    },
    feedbackQuestion1() {
      if (this.feedbackQuestion1 > 3) {
        this.currentStep = 3
        this.progress = 45
      } else {
        this.currentStep = 2
        this.progress = 30
      }
    },
    feedbackQuestion2() {
      if (this.feedbackQuestion2 != null) {
        this.currentStep = 3
        this.progress = 45
      }
    },
    feedbackQuestion3() {
      if (this.feedbackQuestion3 > 3) {
        this.currentStep = 4
        this.progress = 55
      } else {
        this.currentStep = 5
        this.progress = 60
      }
    },
    feedbackQuestion4() {
      if (this.feedbackQuestion4 != null) {
        this.currentStep = 6
        this.progress = 75
      }
    },
    feedbackQuestion5() {
      if (this.feedbackQuestion5 != null) {
        this.currentStep = 6
        this.progress = 75
      }
    },
    feedbackQuestion6() {
      if (this.feedbackQuestion6 != null) {
        this.currentStep = 7
        this.progress = 100
      }
    }
  },
  mounted() {
    if (this.$route.name === 'feedback-artist') {
      this.artistFeedback = true
      this.currentStep = 0
    }

    // For loader
    document.body.style.overflow = 'hidden'

    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    )
    if (vw >= 1000) {
      this.emojiWidth = '50px'
      this.emojiHeight = '50px'
    } else if (vw > 600 && vw < 1000) {
      this.emojiWidth = '45px'
      this.emojiHeight = '45px'
    } else {
      this.emojiWidth = '40px'
      this.emojiHeight = '40px'
    }

    if (!this.artistFeedback) {
      PriventApi.getOfferFeedback(this.$route.params.offerId).then((result) => {
        this.offer = result.data

        setTimeout(() => {
          this.turnLoaderOff()
        }, 1000)

        if (this.offer.hasFeedback) {
          modal.displayModal(true, false, 'feedbackIsExpired')
        }
      })
    } else {
      setTimeout(() => {
        this.turnLoaderOff()
      }, 1000)
    }
  },
  methods: {
    turnLoaderOff() {
      setTimeout(() => {
        // Close proloader
        document.querySelector('.loader').classList.remove('show')
      }, 250)
    },
    feedbackQuestion0getter(value) {
      this.feedbackQuestion0 = value
    },
    feedbackQuestion1getter(value) {
      this.feedbackQuestion1 = value
    },
    feedbackQuestion2getter(value) {
      this.feedbackQuestion2 = value
    },
    feedbackQuestion3getter(value) {
      this.feedbackQuestion3 = value
    },
    feedbackQuestion4getter(value) {
      this.feedbackQuestion4 = value
    },
    feedbackQuestion5getter(value) {
      this.feedbackQuestion5 = value
    },
    feedbackQuestion6getter(value) {
      this.feedbackQuestion6 = value
    },
    submit() {
      let data = {
        Date: new Date(),
        FeedbackGeneral: this.feedbackQuestion2,
        RatingGeneral: this.feedbackQuestion1,
        FeedbackDj:
          this.feedbackQuestion4 != null
            ? this.feedbackQuestion4
            : this.feedbackQuestion5,
        RatingDj: this.feedbackQuestion3,
        RatingPlatform: this.feedbackQuestion6,
        DjId: this.offer.artistId,
        Name: this.artistFeedback
          ? this.feedbackQuestion0
          : this.offer.clientName
      }
      if (this.artistFeedback) {
        PriventApi.addArtistRating(data, this.$route.params.artistId)
      } else {
        PriventApi.addRating(data, this.$route.params.offerId)
      }
    }
  }
}
</script>

<style lang="less" scoped>
body {
  overflow: auto;
  overflow-y: auto !important;
}
</style>
