<template>
  <div
    class="result-card"
    @click="triggerShowProfileMobile(artistId)"
  >
    <div class="result-frame">
      <!-- <img :src="artistImg">-->
      <div
        class="image-crop-profile"
        :style="{ backgroundImage: 'url(' + artistImg + ')' }"
      />
      <div class="details-wrapper">
        <h2 class="artistName">
          {{ artistName }}
        </h2>
        <ProfileStars :artist-stars="artistStars" />
        <div class="bts-wrapper section rav_b">
          <div class="zrcol_6 t_zrcol_12 m_zrcol_12 col col_pad_b">
            <button
              class="result-profile"
              :data-id="artistId"
              @click="triggerShowProfile(artistId)"
            >
              Vezi profilul
            </button>
          </div>
          <div class="zrcol_6 t_zrcol_6 m_zrcol_12 col col_pad_b">
            <button
              class="result-offer"
              :class="{ sended: requested }"
              :data-id="artistId"
              @click="createOffer(artistId)"
            >
              <span v-if="requested">Solicitare trimisă</span>
              <span v-else>Solicită oferta</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileStars from '@/components/ProfileStars.vue'

export default {
  name: 'ProfileCard',
  components: {
    ProfileStars
  },
  props: {
    artistId: {type: Number, default: 0},
    artistImg: {type: String, default: ""},
    artistName: {type: String, default: ""},
    requested: {type: Boolean, default: false},
    artistStars: {type: Array, default: () => []},
    requestedOffer: {type: Array, default: () => []}
  },
  methods: {
    // Call showProfile method from parent component
    triggerShowProfile(id) {

      var dataID = id
      this.$parent.showProfile(dataID)
      document.querySelector('.content').classList.add('maximized')


    },
    triggerShowProfileMobile(id) {
      const isMobile = window.matchMedia("only screen and (max-width: 1001px)").matches;

      if (isMobile) {

        var dataID = id
        this.$parent.showProfile(dataID)
        document.querySelector('.content').classList.add('maximized')

      }
    },
    // Call createOffer method from parent component
    createOffer(id) {
      var dataID = id
      this.$parent.showModal(dataID)
    }
  }
}
</script>
