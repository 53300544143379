<template>
  <div class="page-wrapper terms-wrapper">
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <h2>NOTĂ DE INFORMARE PRIVIND PROTECȚIA DATELOR CU CARACTER PERSONAL</h2>
    <h3> 1. INTRODUCERE </h3>
    <p>S.C. PRIVENT ENTERTAINMENT SRL ,cu sediul in Pitești, Str. L&acirc;năriei nr. 5B, Jud. Argeș, &icirc;nregistrat la R.C&nbsp; cu nr: J3/714/2016, av&acirc;nd codul unic de &icirc;nregistrare 35928219 denumita in continuare&nbsp;Privent&nbsp;este operator al datelor personale transmise prin intermediul acestui site.</p>
    <p>Privent&nbsp;se angajează să respecte confidențialitatea datelor dvs. cu caracter personal. Vom trata toate datele personale furnizate de dvs. ca fiind confidențiale si vom prelucra numai informațiile permise de legislația aplicabila.</p>
    <p>Confidențialitatea informațiilor este o responsabilitate permanenta si, prin urmare, vom actualiza din c&acirc;nd in c&acirc;nd aceasta notificare de confidențialitate pe măsura ce vom &icirc;ntreprinde noi practici privind datele cu caracter personal sau vom adopta noi politici de confidențialitate. In ceea ce privește prelucrarea datelor bazate pe consimțăm&acirc;nt (după cum este descris mai jos), va vom notifica orice modificare si vom solicita consimțăm&acirc;ntul dvs. suplimentar.</p>

    <h3>2. SCOPUL PRELUCRĂRII DATELOR CU CARACTER PERSONAL</h3>

    <p>Scopul PRELUCRĂRII datelor cu caracter personal constă &icirc;n principal &icirc;n trimiterea ofertelor personalizate pentru servicii muzicale.</p>
    <p>Putem colecta, stoca si utiliza următoarele tipuri de date personale:</p>
    <p>Datele preluate din formular despre eveniment (locația evenimentului, tipul evenimentului, data evenimentului, numărul de participanți) sunt prelucrate pentru ca algoritmul să genereze o ofertă relevantă cerințelor.</p>
    <p>Datele de contact pe care ni le furnizati cu scopul de a primi oferta serviciilor prin SMS</p>
    <p>Informații despre computerul dvs. si despre vizitele si utilizarea acestui site web (inclusiv adresa dvs. IP, locația geografica, sistemul de operare, sursa de recomandare, durata vizitei, vizualizările de pagina si căile de navigare ale site-ului) pentru a efectua cercetări de marketing</p>
    <p>Informațiile pe care le-ați furnizat prin intermediul formularului de site sau serviciile achiziționate (valoarea, data, surse de date, note si remarci) pentru a efectua cercetări de marketing si pentru a analiza caracteristicile vizitatorilor acestui site, pentru a evalua impactul comunicării noastre de marketing si pentru a o adapta la tendințele detectate, pentru a planifica viitoarele noastre activități de marketing, pentru a pregăti analiza noastră de business, pentru a personaliza serviciile si comunicările pentru dvs., pentru a orienta publicitatea sau pentru a oferi marketing direct unor produse si servicii similare.</p>
    <p>In numele Privent &nbsp;exista terțe persoane care prelucrează datele cu caracter personal, aceste persoane fiind denumite persoane &icirc;mputernicite de operator in acord cu prevederile Regulamentului (UE) 2016/679. Folosirea &icirc;mputerniciților este justificată de rațiuni de business, de cele mai multe ori vorbim despre servicii specializate, profesioniste care pot gestiona volumele necesare si asigură tehnologia prin care&nbsp;Privent&nbsp;să-și poată &icirc;ndeplini atribuțiile.</p>
    <p>Furnizarea datelor dumneavoastră personale către privent.ro este voluntară. Cu toate acestea, &icirc;n funcție de tipul de serviciu pe care doriți să &icirc;l utilizați, este posibil să vă oferim acest serviciu numai dacă se colectează anumite date personale. De exemplu, nu vă putem procesa Solicitarea de ofertă dacă nu vă colectăm numele și datele de contact.</p>

    <h3>3. PASTRAREA DATELOR CU CARACTER PERSONAL</h3>
    <p>Datele cu caracter personal pe care le prelucram bazate pe consimțăm&acirc;ntul dvs. vor fi păstrate pentru perioada nelimitata de timp (sau pana c&acirc;nd consimțăm&acirc;ntul este revocat), cu excepția cazului in care cerințele legale aplicabile prevăd altfel iar acest lucru este necesar pentru scopul prelucrării.</p>
    <p>Daca nu ne-ați furnizat acordul dvs. pentru prelucrarea datelor sau ați revocat acordul, vom stoca numai date minime despre tine (numele complet, referințe si note) pentru statistica sau raportare.</p>

    <h3>4. CUM VA PROTEJAM DATELE CU CARACTER PERSONAL?</h3>
    <p>&nbsp;Pentru protejarea securităţii datelor cu caracter personal, Privent utilizează metode și tehnologii de securitate, &icirc;mpreună cu politici de securitate aplicate personalului angajat şi colaboratorilor pentru a proteja sistemele informatice de accesul neautorizat şi folosirea, dezvăluirea, &icirc;ntreruperea, modificarea ori distrugerea accidentală sau ilegală a datelor cu caracter personal.</p>
    <p>Nu putem asigura sau garanta securitatea informațiilor pe care le transmiteți si &icirc;nțelegeți ca orice informație pe care o transferați către&nbsp;Privent&nbsp;se face pe propriul dvs. risc. Cu toate acestea,&nbsp;Privent&nbsp; folosește măsuri de securitate a site-ului in conformitate cu cele mai bune practici pentru a proteja site-ul web.</p>

    <h3>5. CARE SUNT DREPTURILE DUMNEAVOASTRĂ?</h3>

    <p>&Icirc;n virtutea REGULAMENTULUI (UE) 2016/679, aveţi următoarele drepturi:</p>
    <ul>
      <li>dreptul de acces la datele personale;</li>
      <li>dreptul de a solicita rectificarea sau actualizarea atunci cand datele sunt inexacte sau incomplete;</li>
      <li>dreptul de a solicita ştergerea datelor &icirc;n anumite circumstanţe (de ex.: c&acirc;nd datele personale nu mai sunt necesare &icirc;n raport cuscopul menţionat mai sus);</li>
      <li>dreptul de a solicita restricţionarea prelucrării &icirc;n anumite circumstanţe (de ex.: atunci c&acirc;nd Dvs. contestaţi exactitatea datelor &ndash;pentru perioada ce ne-ar permite verificarea exactităţii acelor date);</li>
      <li>dreptul de a depune o pl&acirc;ngere la Privent si/sau la autoritatea competentă privind protecția datelor;</li>
      <li>dreptul de a vă opune prelucrării;</li>
      <li>dreptul de a vă retrage consimţăm&acirc;ntul, &icirc;n orice moment, pentru prelucrarea datelor personale la care aţi consimţit anterior;</li>
    </ul>

    <ul>
      <li>dreptul de a fi uitat, &icirc;n urma adresării unei solicitări scrise către PRIVENT &icirc;n vederea ştergerii datelor personale din baza noastră dedate;</li>
      <li>dreptul de a fi notificat de către Privent &icirc;n caz de &icirc;ncălcări privind securitatea datelor.</li>
    </ul>
    <p />
    <h3>6. CUM PUTEȚI CONTROLA DATELE PERSONALE PE CARE LE-AȚI OFERIT LA PRIVENT.RO?</h3>
    <p>Dorim să puteți controla modul &icirc;n care datele dumneavoastră personale sunt utilizate de noi. Puteți face acest lucru &icirc;n următoarele moduri:</p>
    <ul>
      <li>ne puteți solicita o copie a datelor personale pe care le deținem despre dumneavoastră;</li>
      <li>ne puteți informa despre orice modificare a datelor dumneavoastră personale sau ne puteți cere să corectăm datele personale pe care le deținem despre dumneavoastră, dar așa cum este explicat mai jos, și dumneavoastră puteți face astfel de modificări;</li>
      <li>&icirc;n anumite situații, ne puteți cere să ștergem, să blocăm sau să restricționăm prelucrarea datelor personale pe care le deținem despre dumneavoastră sau să vă opuneți anumitor moduri &icirc;n care vă folosim datele personale</li>
      <li>&icirc;n anumite situații, puteți, de asemenea, să ne solicitați să trimitem unei terțe părți datele personale pe care ni le-ați dat.</li>
    </ul>
    <p>&Icirc;n cazul &icirc;n care vă utilizăm datele personale pe baza consimțăm&acirc;ntului dumneavoastră, aveți dreptul să retrageți acest consimțăm&acirc;nt &icirc;n orice moment sub rezerva legii aplicabile. Mai mult, &icirc;n cazul &icirc;n care procesăm datele dumneavoastră personale bazate pe interes legitim sau interes public, aveți dreptul să vă opuneți &icirc;n orice moment utilizării datelor dumneavoastră personale &icirc;n conformitate cu legislația aplicabilă.</p>
    <p>Ne bazăm pe dumneavoastră pentru a ne asigura că datele dumneavoastră personale sunt complete, exacte și actuale. Vă rugăm să ne informați cu promptitudine cu privire la orice modificări sau inexactități ale datelor dumneavoastră personale, contact&acirc;ndu-ne.</p>
    <p>Prin <em>bifarea/acceptarea &nbsp;</em>prezentului Acord, Clientul declară că a fost informat cu privire la procesarea datelor cu caracter personal de către Privent, că a &icirc;nţeles conţinutul său şi că şi-a exprimat liber consimţăm&acirc;ntul &icirc;n scopul prelucrării datelor personale.</p>
    <p>&Icirc;n situaţia &icirc;n care ulterior <em>semnării/ bifarii &nbsp;</em>prezentului Acord aveţi nelămuriri sau suspiciuni privind o posibilă &icirc;ncălcare a confidențialităţii ori doriţi să reveniţi asupra acestuia, vă rugăm să ne sesizaţI la adresa de email: contact@privent.ro , pentru a ne conforma solicitărilor Dvs. ori a &icirc;nlătura orice ne&icirc;nţelegeri &icirc;n cel mai scurt timp şi a vă asigura de deplinul respect pe care &icirc;l acordăm datelor Dvs.</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      <router-link
        :to="{ name : 'home' }"
        class="btn-clasic"
      >
        Înapoi pe prima pagină
      </router-link>
    </p>
    <div class="footer">
      <p>Toate drepturile rezevate</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Terms'
}
</script>

<style lang="less" scoped>

</style>
