<template>
  <div class="step">
    <div class="feedbackText">
      2.1. Ce recomandari ai pentru imbunatatirea prestatiei?
    </div>
    <div class="inputWrapper">
      <textarea
        v-model="feedback"
        class="input"
        type="text"
        placeholder=" "
        autocomplete="off"
        rows="5"
        required
        minlength="4"
      />
      <label class="label" for="adress">Scrie recomandarea ta aici</label>
      <img class="checked" src="@/assets/img/check.svg" alt="" />
      <img class="error" src="@/assets/img/error.svg" alt="" />
    </div>
    <button class="btn" @click="onInput()">
      Urmatorul pas
    </button>
  </div>
</template>

<script>
export default {
  name: 'Step5',
  props: {
    emojiWidth: String,
    emojiHeight: String
  },
  data() {
    return {
      feedback: '',
      labels: ['1', '2', '3', '4', '5']
    }
  },
  methods: {
    onInput() {
      this.$emit('value', this.feedback)
    }
  }
}
</script>
