<template>
  <FormSteps />
</template>

<script>
import FormSteps from '@/components/FormSteps.vue'

export default {
  name: 'Home',
  components: {
    FormSteps,

  }
}
</script>
