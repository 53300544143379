<template>
  <div class="page-wrapper factura-wrapper">
    <div class="invoice">
      <div class="header">
        <img
          src="@/assets/img/invoiceHeaderLogo.svg"
          style="position: absolute; width: 100%; height: 100%"
        />
      </div>
      <div class="content">
        <div v-if="invoice.hasInvoice">
          <div class="row">
            <div class="col-8">
              <h3>Prestator</h3>
              <h2>{{ invoice.company.name }}</h2>
              <div class="row">
                <div class="col-3">
                  <p class="bold">
                    Adresa<br />
                    CUI<br />
                    Reg. Com.<br />
                    Banca <br />
                    IBAN <br />
                  </p>
                </div>
                <div class="col-9">
                  <p>
                    {{ invoice.company.address }}<br />
                    {{ invoice.company.cui }}<br />
                    {{ invoice.company.rc }}<br />
                    {{ invoice.company.bankName }}<br />
                    {{ invoice.company.iban }} <br />
                    <!-- Trezorerie <br />
                    RO26TREZ0465069XXX016868 -->
                  </p>
                </div>
              </div>
            </div>

            <div class="col-4">
              <h1 v-if="invoice.isFiscal" class="g-pstyle3">Factură fiscală</h1>
              <h1 v-else class="g-pstyle3">Factură proformă</h1>

              <div class="invoice-details">
                <div class="row">
                  <div class="col-7">
                    <p class="bold">
                      Seria {{ invoice.serial }} nr.<br />
                      Data emiterii:<br />
                    </p>
                  </div>
                  <div class="col-5 lp">
                    <p class="invoice-number">
                      {{ invoice.number }}
                    </p>
                    <p class="invoice-number">
                      {{ invoice.createdOn }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="horizontal-divider" />
          <div class="row">
            <div class="col-8">
              <h3>Beneficiar</h3>
              <h2>{{ invoice.clientName }}</h2>
              <div class="row">
                <div class="col-3">
                  <p class="bold">
                    Adresa<br />
                    CIF / CNP<br />
                    C.I. / Reg. Com.
                  </p>
                </div>
                <div class="col-9">
                  <p>
                    {{ invoice.clientAddress }}<br />
                    {{ invoice.clientCnp }}<br />
                    {{ invoice.clientCi }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row table-head">
            <div class="number">
              <p>Nr.</p>
            </div>

            <div class="name">
              <p>Denumirea produselor sau a serviciilor</p>
            </div>

            <div class="um">
              <p>U.M.</p>
            </div>
            <div class="quantity">
              <p>Cantitate</p>
            </div>
            <div class="price">
              <p>Pret unitar (lei)</p>
            </div>
            <div class="total">
              <p>Total (lei)</p>
            </div>
          </div>
          <div class="row table-item">
            <div class="number">
              <p>1</p>
            </div>

            <div class="name">
              <p>{{ invoice.item1 }}</p>
            </div>

            <div class="um">
              <p>buc</p>
            </div>
            <div class="quantity">
              <p>1</p>
            </div>
            <div class="price">
              <p>{{ invoice.price1 }}</p>
            </div>
            <div class="total">
              <p>{{ invoice.price1 }}</p>
            </div>
          </div>
          <div class="row table-item">
            <div v-if="invoice.item2.length > 0" class="number">
              <p>2</p>
            </div>

            <div v-if="invoice.item2.length > 0" class="name">
              <p>{{ invoice.item2 }}</p>
            </div>

            <div v-if="invoice.item2.length > 0" class="um">
              <p>buc</p>
            </div>
            <div v-if="invoice.item2.length > 0" class="quantity">
              <p>1</p>
            </div>
            <div v-if="invoice.item2.length > 0" class="price">
              <p>{{ invoice.price2 }}</p>
            </div>
            <div v-if="invoice.item2.length > 0" class="total">
              <p>{{ invoice.price2 }}</p>
            </div>
          </div>
          <div class="row table-item">
            <div class="number">
              <p />
            </div>

            <div class="name">
              <p />
            </div>

            <div class="um">
              <p />
            </div>
            <div class="quantity">
              <p />
            </div>
            <div class="price">
              <p />
            </div>
            <div class="total">
              <p />
            </div>
          </div>
          <div class="totalArea">
            <p class="totalTitle">Total</p>
            <p class="totalPrice">{{ invoice.price1 + invoice.price2 }} LEI</p>
          </div>
        </div>

        <!-- Chitanta -->
        <div v-if="invoice.hasReceipt">
          <div v-if="invoice.hasInvoice" class="horizontal-divider-dashed" />
          <div class="second-content">
            <div class="row">
              <div class="col-8">
                <h3>Prestator</h3>
                <h2>{{ invoice.company.name }}</h2>
                <div class="row">
                  <div class="col-3">
                    <p class="bold">
                      Adresa<br />
                      CUI<br />
                      Reg. Com.<br />
                      Banca <br />
                      IBAN <br />
                      Banca<br />
                      IBAN <br />
                    </p>
                  </div>
                  <div class="col-9">
                    <p>
                      {{ invoice.company.address }}<br />
                      {{ invoice.company.cui }}<br />
                      {{ invoice.company.rc }}<br />
                      {{ invoice.company.bankName }}<br />
                      {{ invoice.company.iban }} <br />
                      <!-- Trezorerie <br />
                      RO26TREZ0465069XXX016868 -->
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <h1>Chitanță</h1>
                <div class="invoice-details">
                  <div class="row">
                    <div class="col-7">
                      <p class="bold">
                        Seria {{ invoice.receiptSerial }} nr.<br />
                        Data emiterii:<br />
                      </p>
                    </div>
                    <div class="col-5 lp">
                      <p class="invoice-number">
                        {{ invoice.receiptNumber }}
                      </p>
                      <p class="invoice-number">
                        {{ invoice.createdOn }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="third-content">
            <div class="row">
              <div class="col-6">
                <p>
                  Am primit de la:
                  <span class="bold-text">{{ invoice.clientName }}</span
                  ><br />CIF/CNP:
                  <span class="bold-text">{{ invoice.clientCnp }}</span
                  ><br />C.I./Reg. com:
                  <span class="bold-text">{{ invoice.clientCi }}</span
                  ><br />Adresa:
                  <span class="bold-text">{{ invoice.clientAddress }}</span>
                </p>
              </div>
              <div class="vertical-divider"></div>
              <div class="col-5">
                <p>
                  Suma de
                  <span class="bold-text"
                    >{{ invoice.price1 + invoice.price2 }} Lei</span
                  >
                  reprezentând                 </p>
                  <p v-if="invoice.hasInvoice"> contravaloarea facturii seria {{ invoice.serial }} nr. {{ invoice.number }} din data de {{ invoice.createdOn }} </p>
                  <p v-else>{{ invoice.item1 }}</p>
                <!-- <p>Casier:<br />Adelin Preda</p> -->
              </div>
            </div>
          </div>
        </div>

        <!-- End chitanta -->
      </div>

      <div class="disclaimer">
        <p>Valabil fără semnatură și ștampilă cf. legii 227/2015</p>

        <div>
          <p
            style="
              text-align: right;
              display: block;
              margin: 0px;
              padding: 0px;
              margin-bottom: 3px;
            "
          >
            Generată cu
          </p>
          <img
            src="@/assets/img/privent-matching-logo.svg"
            style="width: 140px; display: block"
          />
        </div>
      </div>
      <div class="footer">
        <img src="@/assets/img/invoiceLogoFooter.svg" />
        <p>contact@privent.ro | 0751 023 338 | www.privent.ro</p>
      </div>
    </div>
  </div>
</template>

<script>
import PriventApi from "@/api/calls.js";

export default {
  name: "Factura",
  components: {},
  props: {
    isCompany: Boolean,
    print: Boolean,
    offerLoaded: Boolean,
    contractLoaded: Boolean,
  },
  data() {
    return {
      invoice: {
        id: 0,
        createdBy: "",
        createdOn: "",
        clientName: "",
        clientCnp: "",
        contractDate: "",
        clientAddress: "",
        contractNumber: "",
        contractSerial: "",
        isFiscal: false,
        isPrepayment: false,
        hasReceipt: true,
        hasInvoice: true,
        item1: "",
        item2: "",
        number: 0,
        prepaymentDate: "",
        prepaymentNumber: "",
        prepaymentSerial: "",
        receiptSerial: "",
        price: 0,
        serial: "",
        clientSeries: "",
        clientCi: "",
        company: {}
      },
    };
  },
  mounted() {
    const id = this.$route.query.id;
    PriventApi.getInvoiceInfo(id).then((response) => {
      const data = response.data;
      this.invoice = data;
    });
  },

  methods: {},
};
</script>
