<template>
  <div class="error404">
    <img src="@/assets/img/404.svg">
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
