<template>
  <div id="app">
    <router-view v-if="!disableAppHeader()" />

    <div v-if="disableAppHeader()" id="contentInsider">
      <modal-message />
      <router-view />
    </div>
  </div>
</template>

<script>
import ModalMessage from './components/ModalMessage.vue'

export default {
  name: 'App',
  components: {
    ModalMessage,
  },

  mounted() {
    // Prevent two fingers zoom
    document.addEventListener(
      'touchmove',
      function(event) {
        event = event.originalEvent || event
        if (event.scale > 1) {
          event.preventDefault()
        }
      },
      false
    )

    document.documentElement.style.setProperty('overflow', 'auto')
    const metaViewport = document.querySelector('meta[name=viewport]')
    metaViewport.setAttribute(
      'content',
      'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
    )
  },
  methods: {
    turnModalOff() {
      document.body.classList.remove('blurred-form')
    },
    disableAppHeader() {
      if (
        this.$route.path === '/contract' ||
        this.$route.path === '/offer' ||
        this.$route.path === '/feedback' ||
        this.$route.path === '/generateContract' || 
        this.$route.path === '/wedding1'

      ) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>
<style>

</style>