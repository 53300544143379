<template>
  <div id="companyForm">
    <div class="inputWrapper first">
      <input
        v-model="company.name"
        class="input"
        type="text"
        name="companyName"
        placeholder=" "
        autocomplete="off"
        required
        minlength="4"
        data-pristine-minlength-message="Introduceti minim 4 caractere"
        data-pristine-required-message="Câmp obligatoriu"
      />
      <label class="label" for="name">Nume companie</label>
      <img class="checked" src="@/assets/img/check.svg" alt="" />
      <img class="error" src="@/assets/img/error.svg" alt="" />
    </div>
    <div class="inputWrapper">
      <input
        v-model="company.address"
        class="input"
        type="text"
        name="street_address"
        placeholder=" "
        autocomplete="off"
        required
        minlength="4"
        data-pristine-minlength-message="Introduceti minim 4 caractere"
        data-pristine-required-message="Câmp obligatoriu"
      />
      <label class="label" for="adress">Adresa</label>
      <img class="checked" src="@/assets/img/check.svg" alt="" />
      <img class="error" src="@/assets/img/error.svg" alt="" />
    </div>
    <div class="inputWrapper">
      <input
        v-model="company.city"
        class="input"
        type="text"
        name="address-level2"
        placeholder=" "
        autocomplete="off"
        required
        minlength="4"
        data-pristine-minlength-message="Introduceti minim 3 caractere"
        data-pristine-required-message="Câmp obligatoriu"
      />
      <label class="label" for="adress">Oraș</label>
      <img class="checked" src="@/assets/img/check.svg" alt="" />
      <img class="error" src="@/assets/img/error.svg" alt="" />
    </div>
    <div class="inputWrapper">
      <input
        v-model="company.county"
        class="input"
        type="text"
        name="address-level1"
        placeholder=" "
        autocomplete="off"
      />
      <label class="label" for="adress">Județ</label>
      <img class="checked" src="@/assets/img/check.svg" alt="" />
      <img class="error" src="@/assets/img/error.svg" alt="" />
    </div>
    <div class="inputWrapper">
      <input
        v-model="company.cnp"
        class="input"
        name="company.cnp"
        placeholder=" "
        autocomplete="off"
        required
        minlength="8"
        data-pristine-minlength-message="Introduceti minim 8 caractere"
        data-pristine-required-message="Câmp obligatoriu"
      />
      <label class="label" for="cnp">CUI</label>
      <img class="checked" src="@/assets/img/check.svg" alt="" />
      <img class="error" src="@/assets/img/error.svg" alt="" />
    </div>
    <div class="inputWrapper">
      <input
        v-model="company.series"
        class="input"
        type="text"
        placeholder=" "
        autocomplete="off"
        data-pristine-required-message="Câmp obligatoriu"
        required
      />
      <label class="label" for="seriesCI">Reg. Com.</label>
      <img class="checked" src="@/assets/img/check.svg" alt="" />
      <img class="error" src="@/assets/img/error.svg" alt="" />
    </div>
    <div class="inputWrapper">
      <input
        v-model="company.mail"
        v-model.trim="company.mail"
        class="input"
        type="email"
        placeholder=" "
        autocomplete="off"
        data-pristine-required-message="Câmp obligatoriu"
        data-pristine-email-message="Format incompatibil"
        required
      />
      <label class="label" for="mail">Email beneficiar</label>
      <img class="checked" src="@/assets/img/check.svg" alt="" />
      <img class="error" src="@/assets/img/error.svg" alt="" />
    </div>
    <div class="termsWrapper">
      <div class="terms">
        <div class="inputWrapper">
          <input
            id="checkbox"
            type="checkbox"
            name="checkbox"
            v-model="company.termsAndConditions"
            required
            data-pristine-required-message="Trebuie să acceptați termenii și condițiile pentru a genera contractul"
          />
          <label for="checkbox"
            ><p>
              Sunt de acord cu
              <a
                href="https://storagedjbooking.blob.core.windows.net/terms-and-conditions/GDPR%20CONTRACT.pdf"
                target="_blank"
                >termenii si conditiile</a
              >
            </p></label
          >
        </div>
      </div>
    </div>
    <button class="btn" @click="formifyClient">Generează contractul</button>
  </div>
</template>
<script>
import * as Pristine from 'pristinejs'
import { mask } from 'vue-the-mask'
const defaultConfig = {
  classTo: 'inputWrapper',
  errorClass: 'has-danger',
  successClass: 'has-succes',
  // class of the parent element where error text element is appended
  errorTextParent: 'inputWrapper',
  // type of element to create for the error text
  errorTextTag: 'p',
  // class of the error text element
  errorTextClass: 'text-help'
}
export default {
  name: 'CompanyForm',
  directives: { mask },
  data() {
    return {
      company: {
        name: null,
        address: null,
        city: null,
        county: '',
        cnp: null,
        series: null,
        mail: null,
        termsAndConditions: false
      }
    }
  },
  methods: {
    formifyClient: function() {
      var pristine = new Pristine(
        document.getElementById('companyForm'),
        defaultConfig
      )
      var valid = pristine.validate()
      if (valid) {
        pristine.reset()
        pristine.destroy()
        this.validation = true
        this.company.address = `${this.company.address}, ${this.company.city}`

        if (this.company.county.length > 0) {
          this.company.address += `, ${this.company.county}`
        }

        document.querySelector('body').classList.add('printFriendly')


        this.$emit('generateContract', this.company)
      }
    }
  }
}
</script>
