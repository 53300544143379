import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Offer from '@/views/Offer.vue'
import GenerateContract from '@/views/GenerateContract.vue'
import Contract from '@/views/Contract.vue'
import Factura from '@/views/Factura.vue'
import Chitanta from '@/views/Chitanta.vue'
import Feedback from '@/views/Feedback.vue'
import Profile from '@/views/Profile.vue'
import Terms from '@/views/Terms.vue'
import NotFound from '@/views/NotFound.vue'
import Playlist from '@/views/Playlist.vue'
import UploadComponent from '@/views/UploadComponent.vue'
import Wedding1 from '@/invitations/wedding1.vue'


Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/offer',
      name: 'offer',
      component: Offer
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms
    },
    {
      path: '/generateContract',
      name: 'generateContract',
      component: GenerateContract
    },
    {
      path: '/feedback/:offerId',
      name: 'feedback',
      component: Feedback
    },
    {
      path: '/feedback/artist/:artistId',
      name: 'feedback-artist',
      component: Feedback
    },
    {
      path: '/contract',
      name: 'contract',
      component: Contract
    },
    {
      path: '/factura',
      name: 'factura',
      component: Factura
    },
    {
      path: '/playlist',
      name: 'playlist',
      component: Playlist
    },
    {
      path: "/chitanta",
      name: "chitanta",
      component: Chitanta,
    },
    {
      path: "/upload/:offerId",
      name: "Upload",
      component: UploadComponent,
    },
    {
      path: "/wedding1",
      name: "Wedding1",
      component: Wedding1,
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFound
    }
  ]
})
