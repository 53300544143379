var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-place"},[_c('div',{staticClass:"fullscreen-loader"},[_c('div',{staticClass:"wrapperLoader"},[_c('div',{staticClass:"container"},[_c('lottie-animation',{ref:"lottieVue",staticClass:"lottieLoader",attrs:{"autoplay":true,"click-play":false,"loop":true,"path":"lottie/logo.json"}})],1)])]),_c('div',{staticClass:"global-wrapper offer-wrapper"},[_c('div',{staticClass:"offer-expired"},[_c('div',{staticClass:"container-expired"},[_c('lottie-animation',{ref:"lottieVue",staticClass:"lottieLoader",attrs:{"autoplay":true,"click-play":false,"loop":true,"path":"lottie/errorDark.json"}}),_c('div',{staticClass:"text"},[_vm._m(0),_c('h4',{on:{"click":function($event){return _vm.redirectToHome()}}},[_vm._v("Generează o ofertă nouă")])])],1)]),_c('header',{attrs:{"id":"offerheader"}},[_c('header-offer',{attrs:{"location":_vm.location,"type":_vm.type,"guests":_vm.guests,"date":_vm.date,"artist-name":_vm.artistName,"artist-photo":_vm.artistPhoto,"redirect":_vm.redirect}})],1),_c('section',{staticClass:"area",attrs:{"id":"offerDetails"}},[(_vm.packagesLoaded)?_c('div',[_vm._m(1),_c('cards-package',{attrs:{"offer-requested":_vm.offerRequested,"offer-confirmed":_vm.offerConfirmed,"packages-sound":_vm.packagesSound,"options":_vm.countSoundPackages(),"tiny-slider-options":_vm.tinySliderOptions,"is-sound-package":true,"selected-package":_vm.selectedSoundPackage},on:{"updatePrice":_vm.updatePrice,"selectSound":function($event){return _vm.selectSound($event)}}}),(
            !(_vm.offerRequested && _vm.selectedLightPackage === null) &&
            _vm.packagesLight.length > 0
          )?_c('div',[(_vm.packagesLight != null)?_c('div',{staticClass:"categories-card",attrs:{"id":"showlight"}},[_vm._m(2)]):_vm._e(),_c('cards-package',{attrs:{"offer-requested":_vm.offerRequested,"offer-confirmed":_vm.offerConfirmed,"packages-sound":_vm.packagesLight,"options":_vm.countLightPackages(),"tiny-slider-options":_vm.tinySliderOptions,"is-sound-package":false,"selected-package":_vm.selectedLightPackage},on:{"updatePrice":_vm.updatePrice,"selectLight":function($event){return _vm.selectLight($event)}}})],1):_vm._e()],1):_vm._e()]),_c('section',{staticClass:"area",attrs:{"id":"priceEvent"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"pachet"},[_c('div',{staticClass:"small-ttl"},[_vm._v("Pretul pachetului tău:")]),(_vm.price !== _vm.basePrice || _vm.offerRequested !== false)?_c('div',{staticClass:"mid-ttl"},[_c('span',{staticClass:"event-price"},[_vm._v(_vm._s(_vm.price))]),_vm._v(" Lei ")]):_c('div',{staticClass:"mid-ttl-select"},[_vm._v(" Va rugam selectati "),_c('br'),_vm._v(" un pachet ")]),_c('offer-resume',{attrs:{"selected-sound-package":_vm.selectedSoundPackage,"selected-light-package":_vm.selectedLightPackage,"artist-name":_vm.artistName}})],1),_c('div',{staticClass:"pachetTermeni"},[_vm._m(3),(_vm.selectedSoundPackage === null)?_c('button',{staticClass:"btn-cls null",on:{"click":function($event){return _vm.scrollToSoundExperience()}}},[_vm._v(" Selectează pachetele ")]):(
              (_vm.offerRequested === true && _vm.offerConfirmed === false) ||
              _vm.offerConfirmed === true
            )?_c('button',{staticClass:"btn-cls null"},[_vm._v(" Selecția confirmată ")]):_c('button',{staticClass:"btn-cls",class:{ null: _vm.requestingOffer },attrs:{"disabled":_vm.requestingOffer},on:{"click":_vm.requestOffer}},[_vm._v(" Confirma selecția ")])])])]),_c('section',{staticClass:"area",attrs:{"id":"otherOffer"}},[_c('div',{staticClass:"categories-card"},[_vm._m(4),_c('div',{staticClass:"wrapper"},[_c('button',{staticClass:"button",on:{"click":_vm.searchArtist}},[_vm._v(" Caută alt artist ")])])])]),_c('Footer')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_vm._v("Oferta a expirat "),_c('br'),_vm._v("sau a fost stearsa!")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"categories-card",attrs:{"id":"soundExperience"}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"mid-ttl",attrs:{"id":"artist-name"}},[_vm._v("Sound Experience")]),_c('div',{staticClass:"small-ttl"},[_vm._v("Selectează pachetul de sunet")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"mid-ttl",attrs:{"id":"artist-name"}},[_vm._v("Showlight")]),_c('div',{staticClass:"small-ttl"},[_vm._v("Selectează pachetul de lumini")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"termeni"},[_c('div',{staticClass:"small-ttl"},[_vm._v("Termenii și condițiile")]),_c('ul',{staticClass:"termeni-generali-main"},[_c('li',{staticClass:"list-item"},[_vm._v(" Oferta este valabila pentru maximum 3 zile ")]),_c('li',{staticClass:"list-item"},[_vm._v("30% avans la semnarea contractului")]),_c('li',{staticClass:"list-item"},[_vm._v(" Prețul include și tariful transportului ")]),_c('li',{staticClass:"list-item"},[_vm._v(" Anularea evenimentului se face cu cel putin 30 de zile inainte ")]),_c('li',{staticClass:"list-item"},[_vm._v(" Prestația DJ-ului selectat este de 8 ore ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"mid-ttl",attrs:{"id":"artist-name"}},[_vm._v("Solicită altă ofertă")]),_c('div',{staticClass:"small-ttl"},[_vm._v(" Solicită oferta pentru alți artiști disponibili ")])])
}]

export { render, staticRenderFns }