<template>
  <div class="wrapper form-place-results">
    <div class="form-wrapper">
      <div class="content">
        <div class="insider">
          <div class="form">
            <div
              class="profile-page generated-step active-step"
              style="padding-top: 40px"
            >
              <div
                v-if="!backDisabled"
                class="results-back-button active"
                @click="prevStep"
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAvCAYAAAASP7HuAAAABHNCSVQICAgIfAhkiAAAAYxJREFUWIW12D1OAkEUB/D/WzaAje4NpLDAii0kUlLbeQM8gEQSjmFCNKFWT+ARwA4roaKhkNKO0CgG8igwyuIC8/HedDNvk18yyc77ICgtbsURMgclLD4H1OhPACBQgdrlGJn8G4i7CHMdbsWRCsbtcoxF0AGhsDqhGGH+Rhz7gyhKBvAOAKQOAU903auJYSaQCGYKeWM2kBdmCzljLpAT5gpZYz6QFeYLGWMSkBEmBe3FJKGdmDS0FdOAUjEt6B+mCSUwbQj4ydR8d3aBRfCqCQEA8e3pMbJHIxBCTQgAAmQPL1OgkTS0wr6nzwB/bZyf8H3lQRyj5nCM2bQI8Ad4LUKoSYMBAFBzOMZ8VgTxIBEVBpP/WSuOEOa6AJUSXzEeqd67EsW0wfS3UQnc/uorgLvzmTC4P1MLgmY1iBBoXl0JgHZ1oydoXxF7gG61viPo3sU4gH79mSXo33lagDI9tSEoNy0wAMXmINToTzCfVYG0BHwuP3TZCv66CitxpYwXqveqGs4GWims75eDgB166t7wEQAAAABJRU5ErkJggg=="
                /><span>Înapoi la oferta</span>
              </div>
              <ProfilePage
                :artist-id="artistId"
                :requested-offer="[artistId]"
                :offerId="offerId"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.btn-clasic.back {
  background: transparent;
  padding-bottom: 0px !important;
  margin-top: 20px !important;
}
.btn-clasic.back img {
  height: 30px !important;
}
</style>
<script>
import ProfilePage from '@/components/ProfilePage.vue'

export default {
  name: 'Profile',
  components: {
    ProfilePage
  },
  data() {
    return {
      artistId: null,
      offerId: null,
      backDisabled: false
    }
  },
  created() {
    // const urlString = 'https://priventstorage.z16.web.core.windows.net/index.html?artistId=12'
    const urlString = window.location.href
    const urlNew = new URL(urlString)
    this.artistId = parseInt(urlNew.searchParams.get('artistId'), 10)
    this.offerId = urlNew.searchParams.get('offerId')
    this.backDisabled = Boolean(urlNew.searchParams.get('backDisabled'))
  },
  mounted() {
    if (!this.offerId) {
      this.backDisabled = true
    }
    document.getElementById('app').classList.add('add-results')
    document.querySelector('button.result-offer').remove()
    if (!this.backDisabled) {
      document.querySelector('.content').classList.add('maximized')
    }
  },
  methods: {
    prevStep() {
      this.$router.push('Offer?offerId=' + this.offerId)
      document.querySelector('.content').classList.remove('maximized')
    }
  }
}
</script>

<style lang="less" scoped>
.profile-page {
  padding-top: 0;
}
</style>
