<template>
  <div class="page-wrapper contract-wrapper">
    <div  v-if="!print" class="otp-wrapper">
      <div class="modal">
        <h3>Confirmă-ți identitatea pentru a finaliza semnarea contractul</h3>
        <h4>
          Introdu mai jos codul PIN trimis prin SMS la
          <span class="number">
            +407XXXXX{{ offer.phone ? offer.phone.slice(-3) : 'XXX' }}</span
          >
        </h4>
        <div class="otp-container">
          <otp-input
            ref="otpInput"
            input-classes="otp-input"
            separator=""
            :num-inputs="4"
            :should-auto-focus="false"
            :is-input-num="false"
            @on-change="handleOnChange"
            @on-complete="handleOnComplete"
          />
        </div>
        <div v-if="otpIsWrong" class="errorContainer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            :fill="['#A41623']"
            class="bi bi-info-circle"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
            />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
            />
          </svg>
          <h4 class="error">Codul nu este corect</h4>
        </div>
        <p v-if="counting">
          <vue-countdown
            v-if="counting"
            :time="60000"
            @end="onCountdownEnd"
            v-slot="{ totalSeconds }"
            >Trimite mesajul in {{ totalSeconds }} de secunde</vue-countdown
          >
        </p>
        <p v-else style="cursor: pointer; color: #f27f32" @click="resendOtp">
          Nu ai primit mesajul? <b>Retrimite</b> codul pe SMS.
        </p>
      </div>
    </div>
    <div  v-if="!print" class="helper-wrapper">
      <div class="modal">
        <lottie-animation
        ref="lottieVue"
        class="lottieLoader"
        :autoplay="true"
        :click-play="false"
        :loop="true"
        path="lottie/signContract.json"
      />

        <h3>Nu uita să semnezi digital în partea de jos a paginii</h3>
        <h4>
          Avem nevoie de semnatura ta pentru a genera contractul
        </h4>
        <button class="btn" @click="removeHelper()">Am inteles</button>
      </div>
    </div>
    <div
      v-if="!print"
      class="scrollToBottom"
      :class="{ disabled: scrolledToBottom }"
      @click="scrollIntoView()"
    >
      <div class="icon" />
    </div>
    <div class="header">
      <div class="wrapperHeader">
        <img class="backButton" src="@/assets/img/left-arrow.svg"  @click="goBack"
        v-if="!isSigned && !print" />
        <img src="@/assets/img/logoHeadlineWhite.svg"/>
        </div>
    </div>
    <div v-if="!print || (offerLoaded && contractLoaded)" class="wrapper">
      <p>&nbsp;</p>
      <div class="title">
        <h2 v-if="client.isContractVoid" class="noMargin" style="color: red">
          ANULAT
        </h2>
        <h2 class="noMargin">CONTRACT DE PRESTARI SERVICII</h2>
        <del v-if="client.isContractVoid">
          <h2>
            NR: {{ client.contractSerial }}{{ client.contractNumber }}/
            {{ currentDate() }}
          </h2>
        </del>
        <h2 v-else>
          NR: {{ client.contractSerial }}{{ client.contractNumber }}/
          {{ currentDate() }}
        </h2>
      </div>
      <div class="titleDivider" />

      <h3>1. PĂRȚI CONTRACTANTE</h3>

      <p>
        <b>{{ company.name }}</b>, cu sediul in {{ company.address }}, &icirc;nregistrat la R.C&nbsp; cu nr:
        {{ company.rc }}, av&acirc;nd codul unic de &icirc;nregistrare
        <b>{{ company.cui }}</b>, cont deschis la {{ company.bankName}} nr: {{ company.iban }} denumită &icirc;n continuare PRESTATOR
      </p>
      <p>și</p>
      <p v-if="!isCompany">
        <b>{{ client.name }}</b
        >, domiciliat/ă &icirc;n <b>{{ client.address }}</b
        >, posesor al C.I <b>{{ client.series }}</b
        >, CNP: <b>{{ client.cnp }}</b> denumit/ă &icirc;n continuare
        BENEFICIAR.
      </p>

      <p v-else>
        SC <b>{{ client.name }}</b
        >&nbsp;cu sediul &icirc;n <b>{{ client.address }}</b
        >, &icirc;nregistrată la R.C cu nr. <b>{{ client.series }}</b
        >, av&acirc;nd codul unic de &icirc;nregistrare <b>{{ client.cnp }}</b
        >, denumită &icirc;n continuare <b>BENEFICIAR.</b>
      </p>
      <p>&nbsp;</p>
      <p>
        Cele 2 părți au convenit să &icirc;ncheie prezentul contract, cu
        respectarea următoarelor clauze:
      </p>
      <p>&nbsp;</p>

      <h3>2. OBIECTUL SI DURATA CONTRACTULUI</h3>

      <ol>
        <li>
          2.1. Obiectul prezentului contract &icirc;l reprezintă asigurarea de
          către PRESTATOR a programului artistic
          <bdi v-if="offer.soundPackage && offer.lightPackage">
            și &icirc;nchiriere sistem sunet și lumini
          </bdi>
          <bdi v-else-if="offer.soundPackage"
            >și &icirc;nchiriere sistem sunet</bdi
          >
          <bdi v-else-if="offer.lightPackage"
            >și &icirc;nchiriere sistem lumini</bdi
          >
          al evenimentului de tip <b>{{ offer.eventType }}</b
          >, care se va desfășura &icirc;n data de
          <b>{{ offer.date }}</b> &icirc;ncep&acirc;nd cu ora
          <b>{{ offer.time }}</b> p&acirc;nă la data de
          <b>{{ offer.endDate }}</b
          >, ora <b>{{ offer.endTime }}</b
          >;
        </li>
        <li>
          2.2. Evenimentul se va desfășura &icirc;n locația
          <b>{{ offer.location }}</b
          >;
        </li>
        <li>
          2.3. Montajul sistemului de
          <bdi v-if="offer.soundPackage && offer.lightPackage"
            >sunet si&nbsp; lumini
          </bdi>
          <bdi v-else-if="offer.soundPackage">lumini </bdi>
          <bdi v-if="offer.lightPackage">sunet </bdi>se va realiza cu minim o
          oră înainte de sosirea invitaților;
        </li>
        <li v-if="offer.soundPackage || offer.lightPackage">
          2.4. Prestatorul se va prezenta la eveniment cu următoarele
          echipamente echipamente:
          <ol>
            <li v-if="offer.soundPackage">
              2.4.1 Tip pachet sunet: <b>{{ offer.soundPackage.name }};</b>
            </li>
            <li v-if="offer.lightPackage">
              2.4.2 Tip pachet lumini: <b>{{ offer.lightPackage.name }};</b>
            </li>
          </ol>
        </li>
      </ol>

      <h3>3. VALOAREA CONTRACTULUI</h3>

      <ol>
        <li>
          3.1. Contravaloarea totală a serviciilor realizate în baza prezentului
          contract este în cuantum de <b>{{ offer.price }} LEI</b> care va fi
          achitată prin virament bancar în contul {{ company.iban }}
          deschis la {{ company.bankName }} astfel încat, daca va fi cazul, suma care
          va fi achitată în numerar la finalul evenimentului să nu depășească
          valoarea de 5000 lei.
        </li>
        <li v-if="offer.hasPrepayment">
          3.2. Onorariul va fi achitat de către beneficiar prestatorului în lei,
          astfel:
          <ol>
            <li>
              3.2.1. Avans în cuantum de de&nbsp;
              <b>{{ Math.round((30 / 100) * offer.price).toFixed() }} LEI</b>
              care va fi achitat până cel târziu la data de&nbsp;
              <b>{{ getCurrentDatePlusDays(new Date(), 2) }}</b
              >&nbsp;
            </li>
            <li>
              3.2.2. Restul de plată în cuantum de
              <b>{{ Math.round((70 / 100) * offer.price).toFixed() }} LEI</b>
              &nbsp;până cel târziu la data de
              <b>{{ getCurrentDatePlusDays(offer.dateOriginalFormat, 2) }}</b>
              în contul și condițiile menționate la punctul 1.
            </li>
          </ol>
        </li>
        <li v-else>
          3.2. Onorariul în cuantum de
          <b>{{ offer.price }} LEI</b> va fi achitat de către beneficiar
          prestatorului în lei &nbsp;până cel târziu la data de
          <b>{{ getCurrentDatePlusDays(offer.dateOriginalFormat, 2) }}</b
          >.
        </li>
        <li v-if="offer.hasPrepayment">
          3.3. &Icirc;n situația &icirc;n care plata avansului &icirc;n valoare
          de
          <b>{{ Math.round((30 / 100) * offer.price).toFixed() }} LEI</b> nu va
          fi virată &icirc;n contul bancar al PRESTATORULUI p&acirc;nă la data
          de
          <b>{{ getCurrentDatePlusDays(new Date(), 3) }}</b>
          &nbsp;, PRESTATORUL poate considera evenimentul anulat de către
          BENEFICIAR, iar contractul este anulat.&nbsp;
        </li>
        <li>
          <bdi v-if="offer.hasPrepayment">3.4. </bdi
          ><bdi v-else>3.3. </bdi>Sumele plătite ca avans de către BENEFICIAR
          vor fi reținute și nereturnabile în situația în care beneficiarul
          anulează evenimentul stabilit la data menționată de acesta. O
          eventuală reprogramare va putea fi realizabilă doar în condițiile
          disponibilității confirmate a dj-ilor.
        </li>
      </ol>

      <h3>4. OBLIGAȚIILE PĂRȚILOR</h3>
      <li>
        În cazul în care Prestatorul, din motive independente de acesta și cu
        justificari pertinente, nu poate asigura prezența la data/locația
        menționată în contract a artistilor/dj-ilor solicitați de beneficiar,
        anterior opțiunii de reziliere/încetare a contractului, beneficiarul va
        solicita o altă ofertă care să-i fie convenabilă.
      </li>
      <ol>
        <li>
          4.1. PRESTATORUL de servicii se obligă :
          <ol>
            <li>
              4.1.1. Asigurarea programului muzical adecvat solicitat de către
              beneficiar.
            </li>
            <li>
              4.1.2. Asigurarea funcționarii echipamentelor &icirc;nchiriate
              &icirc;n condiții optime.
            </li>
          </ol>
        </li>

        <li>
          4.2. BENEFICIARUL serviciilor se obligă:

          <ul>
            <li>
              4.2.1. Achitarea contravalorii serviciilor de către prestator
              conform art III; daca plata nu va fi efectuată integral sau va fi
              efectuată parțial, contractul nu va produce efecte iar
              beneficiarul se obligă să achite despăgubiri egale cu jumatate din
              valoarea contractului și penalități contractuale de 0,02 % pe zi
              de întarziere.
            </li>
            <li>
              4.2.2. Notificarea prestatorului cu cel puțin 30 zile
              &icirc;nainte pentru anularea evenimentului, &icirc;n caz contrar
              va fi obligat la plata de daune de interese egale cu jumătate din
              valoarea contractului.
            </li>
            <li>
              4.2.3. Daunele provocate echipamentelor de catre beneficiar sau de
              catre invitați vor fi suportate de către acesta.
            </li>
          </ul>
        </li>
      </ol>

      <h3>5. &Icirc;NCETAREA CONTRACTULUI</h3>

      <ol>
        <li>
          5.1. &Icirc;n cazul &icirc;n care una din părţi invocă o cauză de
          &icirc;ncetare a prezentului contract, va notifica cealaltă parte cu
          cel puţin 30 de zile &icirc;nainte.
        </li>
        <li>
          5.2. Rezilierea prezentului contract nu va avea nici un efect asupra
          obligaţiilor deja scadente &icirc;ntre părţile contractante.
        </li>
      </ol>

      <h3>6. FORȚĂ MAJORĂ</h3>

      <ol>
        <li>
          6.1. &Icirc;n cazul &icirc;n care &icirc;ndeplinirea obligațiilor
          prevăzute &icirc;n prezentul contract este impiedicată de un caz de
          forța majoră, partea căreia &icirc;i incumbă obligația respectivă nu
          va fi ținută răspunzătoare pentru prejudiciile astfel cauzate, sub
          condiția de a fi notificat &icirc;n scris celeilalte părti evenimentul
          considerat de forță majoră &icirc;n termen de 48 de ore de la
          producerea acestuia.
        </li>
        <li>
          6.2. &Icirc;n cazul &icirc;n care evenimentul de forță majoră durează
          mai mult de 15 zile, oricare din părți poate solicita &icirc;ncetarea
          contractului.
        </li>
      </ol>

      <h3>7. LITIGII</h3>

      <ol>
        <li>
          7.1. Orice litigiu ca urmare a aplicării ori a interpretării
          prezentului contract se va soluționa pe cale amiabilă, &icirc;n caz
          contrar, litigiul va fi supus spre soluționare instanței competente.
        </li>

        <h3>8. CLAUZE FINALE</h3>
        <li>
          8.1. Orice modificare sau completare a prezentului contract se va
          putea face numai &icirc;n scris, sub forma unui act adițional semnat
          de reprezentanții legali ai fiecăreia dintre părțile
        </li>
        <li>
          8.2. Nici una dintre părti nu poate cesiona drepturile și obligațiile
          sale rezultate din prezentul contract, fără a avea acordul expres al
          celeilalte părti, dat prealabil, in scris.
        </li>
      </ol>

      <p>
        Prezentul contract a fost emis astăzi
        <b>{{ currentDate() }}</b
        >, &nbsp;și a fost trimis beneficiarului la numărul de telefon
        <b>{{ offer.phone }}</b>
      </p>

      <div class="signature">
        <div>
          <p>PRESTATOR:</p>
          <img
            v-if="print"
            src="@/assets/img/stampSignature.png"
            width="135px"
          />
        </div>
        <div>
          <p>BENEFICIAR:</p>
          <img :src="client.signature" width="135px" />
        </div>
      </div>

      <div id="signature" class="signatureContainer">
        <div v-show="!hasBeenGenerated && !print" class="actionPad">
          <h2>Semnează digital</h2>
          <p>
            Semnează pentru a valida creearea contractului apoi apasă pe butonul
            de mai jos
          </p>

          <div class="signaturePad">
            <div class="delete" @click="clear">
              <span class="icon" />
            </div>
            <div class="signatureZone">
              <vueSignature
                ref="signature"
                :sig-option="option"
                :w="'250px'"
                :h="'150px'"
              />
            </div>
          </div>
          <p v-if="signatureIsEmpty" class="error">
            Nu uita să completezi semnătura!
          </p>
          <button v-if="!generating" @click="verifyIdentity()">
            Generează contractul
          </button>
        </div>
        <div v-show="hasBeenGenerated || print" class="confirmationPad">
          <lottie-animation
            v-if="!print"
            ref="lottieVue"
            class="lottieLoader"
            :autoplay="true"
            :click-play="false"
            :loop="true"
            path="lottie/sentAnimation.json"
          />
          <h2>Contractul a fost generat cu succes</h2>
          <p>
            Prezentul contract a fost emis astăzi
            <span class="bold">{{
              client.date ? getEventDate(client.date) : currentDate()
            }}</span
            >, și a fost trimis beneﬁciarului la nr de telefon:
            <span class="bold">+4{{ offer.phone }}</span> și pe mail la adresa:
            <span class="bold">{{
              offer.email ? offer.email : client.mail
            }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueSignature from 'vue-signature'
import VueSignature from 'vue-signature-pad'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import PriventApi from '@/api/calls.js'
import OtpInput from '@bachdgvn/vue-otp-input'
import VueCountdown from '@chenfengyuan/vue-countdown'


export default {
  name: 'Contract',
  components: {
    vueSignature,
    VueSignature,
    LottieAnimation,
    OtpInput,
    VueCountdown,
  },
  props: {
    offer: {},
    client: {},
    company: {},
    isCompany: Boolean,
    print: Boolean,
    offerLoaded: Boolean,
    contractLoaded: Boolean,
    goBack: {
      type: Function
    }
  },
  data() {
    return {
      numarContract: 'PRV58',
      dataContract: '12.12.2021',
      generating: false,
      companie: {
        nume: 'Creatif Dizainar SRL',
        adresa: 'Str. Fratii Catina Nr. 2 Bl. C3. Sc. B Ap. 3',
        regCom: ''
      },
      scrolledToBottom: false,
      option: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(255, 255, 255)'
      },
      disabled: false,
      hasBeenGenerated: false,
      dataUrl: 'https://avatars2.githubusercontent.com/u/17644818?s=460&v=4',
      isSigned: false,
      otpIsWrong: false,
      signatureIsEmpty: false,
      counting: true
    }
  },
  mounted() {
    if (this.$route.query.print) {
      document.addEventListener('DOMContentLoaded', () => {
      document.body.classList.add('white');
    });
   
    }
    this.scroll()
   
  },
  methods: {
    onCountdownEnd() {
      this.counting = false
    },
    resendOtp() {
      this.verifyIdentity()
      this.counting = true
    },
    removeHelper() {
      document.body.classList.remove('helper-form')
    },
    turnModalOff() {
      document.body.classList.remove('blurred-form')
    },
    scrollIntoView() {
      const el = this.$el.querySelector('#signature')
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
    isScrolledIntoView(el) {
      const rect = el.getBoundingClientRect()
      const elemTop = rect.top
      const elemBottom = rect.bottom
      const isVisible = elemTop < window.innerHeight && elemBottom >= 0
      return isVisible
    },
    scroll() {
      window.onscroll = () => {
        const scrolledTo = document.querySelector('#signature')
        if (scrolledTo && this.isScrolledIntoView(scrolledTo)) {
          this.scrolledToBottom = true
        } else {
          this.scrolledToBottom = false
        }
      }
    },
    onBegin() {
      var _this = this
      _this.$refs.signature.resizeCanvas()
    },
    verifyIdentity() {
      if (this.$refs.signature.isEmpty()) {
        this.signatureIsEmpty = true
        this.activateHelperForm()
      } else {
        PriventApi.generateOTPCode(this.offer.id)
        this.handleClearInput()
        document.body.classList.add('blurred-form')
        document.body.classList.remove('errorContainer')
        this.signatureIsEmpty = false
      }
    },

    save() {
      document.querySelector('.actionPad').classList.add('hidden')
      document.body.classList.remove('helper-form')
      document.body.classList.remove('errorContainer')
      document.body.classList.remove('blurred-form')
      setTimeout(() => {
        this.hasBeenGenerated = true
      }, 300)
      this.generating = true
      var _this = this
      var png = _this.$refs.signature.save()
      // var jpeg = _this.$refs.signature.save('image/jpeg')
      // var svg = _this.$refs.signature.save('image/svg+xml')

      var data = {
        Name: this.client.name,
        Address: this.client.address,
        Date: new Date(),
        Signature: png,
        Cnp: this.client.cnp,
        Series: this.client.series,
        OfferId: this.offer.id,
        IsCompany: this.isCompany,
        ContractSerial: this.client.contractSerial,
        Number: this.client.contractNumber,
        HasPrepayment: this.offer.hasPrepayment,
        Email: this.client.mail,
        TermsAndConditions: this.client.termsAndConditions
      }
      PriventApi.saveContractInfo(data).catch(() => {
        this.generating = false
        this.isSigned = true
      })
    },
    clear() {
      var _this = this
      _this.$refs.signature.clear()
    },
    undo() {
      var _this = this
      _this.$refs.signature.undo()
    },
    fromDataURL(url) {
      var _this = this
      // _this.$refs.signature.fromDataURL('data:image/png;base64,iVBORw0K...')
      _this.$refs.signature.fromDataURL(url)
    },
    handleDisabled() {
      var _this = this
      _this.disabled = !_this.disabled
    },
    currentDate() {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, '0')
      var mm = String(today.getMonth() + 1).padStart(2, '0')
      var yyyy = today.getFullYear()

      today = dd + '.' + mm + '.' + yyyy
      return today
    },
    getCurrentDatePlusDays(date, nrOfDays) {
      var predate = new Date(date)
      predate.setDate(predate.getDate() + nrOfDays)
      var dd = predate.getDate()

      var mm = predate.getMonth() + 1
      if (dd < 10) {
        dd = '0' + dd
      }

      if (mm < 10) {
        mm = '0' + mm
      }
      var hh = predate.getHours()
      if (hh < 10) {
        hh = '0' + hh
      }
      var ss = predate.getMinutes()
      if (ss < 10) {
        ss = '0' + ss
      }
      const finalDate = dd + '.' + mm + '.' + predate.getFullYear()
      return finalDate
    },
    handleOnComplete(value) {
      PriventApi.verifyOTPCode(this.offer.id, value).then((response) => {
        if (response.status === 200) {
          this.save()
          this.turnModalOff()
        } else {
          this.otpIsWrong = true
          this.counting = false
          this.handleClearInput()
        }
      })
    },
    handleOnChange() {},
    handleClearInput() {
      this.$refs.otpInput.clearInput()
    },
    getEventDate(date) {
      var predate = new Date(date)
      var dd = predate.getDate()

      var mm = predate.getMonth() + 1
      if (dd < 10) {
        dd = '0' + dd
      }

      if (mm < 10) {
        mm = '0' + mm
      }
      var hh = predate.getHours()
      if (hh < 10) {
        hh = '0' + hh
      }
      var ss = predate.getMinutes()
      if (ss < 10) {
        ss = '0' + ss
      }
      const finalDate = dd + '.' + mm + '.' + predate.getFullYear()

      return finalDate
    }
  }
}
</script>

<style lang="less" scoped>
body {
  overflow: auto;
  overflow-y: auto !important;
  background: #ffffff!important;
}
.section {
  overflow-y: auto !important;
}
#header /deep/ {
  display: none;
}
#header::v-deep {
  display: none !important;
}
.logo {
  display: none;
}
</style>
