<template>
  <div class="card-dj">
    <!-- Cardul intra in slider -->
    <div
      class="image-square"
      :style="{ backgroundImage: 'url(' + artistPhoto + ')' }"
    >
      <div class="image-gradient" />
    </div>
    <div class="wrapper-profile">
      <div class="small-ttl page-txt">Mixul perfect este construit de:</div>
      <div id="artist-name" class="mid-ttl page-txt event-artist-name">
        {{ artistName }}
      </div>
      <a id="profile-button" class="btn-cls negative" @click="redirect"
        >Vezi profilul</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardDj',
  props: {
    artistPhoto: String,
    artistName: String
  },
  methods: {
    redirect() {
      this.$emit('redirect')
    }
  }
}
</script>
